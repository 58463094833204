import { useContext } from 'react';
import { AuthDataContext, AuthDispatchContext } from './context';

export function useAuthData() {
  const context = useContext(AuthDataContext);

  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (!context) {
    throw new Error('Auth Dispatch Context is not initialized.');
  }
  return context;
}
