import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AuthDataContext, AuthDispatchContext } from './context';
import { AuthData, User } from './type';
import { isRn } from '../utils/rn-utils';
import { useAutoLogin } from './hook/useAutoLogin';
import { refreshTokenStorage } from '../utils/refreshTokenStorage';
import { NoticeTopic } from 'constants/notice-topic';
import { rnBridge } from 'utils/rn-bridge';

interface AuthProviderProps {
  children?: any;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthData>({});
  const login = useCallback((user: User) => {
    setAuthData({ user });
    if(user.isNew){
      rnBridge.requestNotificationEnabled();
    }
   }, []);
  const logout = useCallback(() => {
    rnBridge.requestRefreshFCM();
    refreshTokenStorage.remove();
    setAuthData({});
    rnBridge.unsubscribeFCMTopic(NoticeTopic);
  }, []);
  const update = useCallback((user: Partial<User>) => {
    setAuthData((prev) => {
      if (prev.user) {
        return {
          user: {
            ...prev.user,
            ...user,
          },
        };
      }
      return {};
    });
  }, []);

  const dispatchMemo = useMemo(() => {
    return {
      login,
      logout,
      update,
    };
  }, [login, logout, update]);
  const isLogin = useMemo(() => {
    return !!authData.user;
  }, [authData.user]);

  useEffect(() => {
    if (isRn() && isLogin && authData.user?.service_term_agree && authData.user?.privacy_policy_agree && authData.user?.service_notification_enabled) {
      rnBridge.requestFCMToken();
      rnBridge.requestInitNotifee();
    }

  }, [isLogin, authData.user?.service_term_agree, authData.user?.privacy_policy_agree, authData.user?.service_notification_enabled]);

  const isReady = useAutoLogin(login);

  if (!isReady) {
    return null;
  }

  return (
    <AuthDataContext.Provider value={authData}>
      <AuthDispatchContext.Provider value={dispatchMemo}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthDataContext.Provider>
  );
};
