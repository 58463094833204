import { FC } from 'react';
import { useAuthData } from '../auth';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  to?: string;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ to = '/login' }) => {
  const authData = useAuthData();
  const isLogin = !!authData.user;
  const location = useLocation();

  if (!isLogin) {
    return <Navigate to={to} replace state={{ from: location.pathname }} />;
  }

  return <Outlet />;
};
