import { Component } from 'react';
import Lottie, { Options } from 'react-lottie';

export class LoadingIndicator extends Component {
  render() {
    const options: Options = {
      loop: true,
      autoplay: true,
      animationData: loadingPaintJson,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return <Lottie options={options} width={64} height={64} />;
  }
}

const loadingPaintJson = {
  v: '4.6.3',
  fr: 29.9700012207031,
  ip: 1.00000004073083,
  op: 67.000002728966,
  w: 600,
  h: 600,
  nm: 'Splash 3',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Splash 7',
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: -451.084 },
        p: { a: 0, k: [295.083, 97.911, 0] },
        a: { a: 0, k: [107.25, -230.25, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 52,
                s: [
                  {
                    i: [
                      [0.066, -0.121],
                      [0.121, 0.066],
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                    ],
                    o: [
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                      [0.066, -0.121],
                      [0.121, 0.066],
                    ],
                    v: [
                      [107.47, -230.13],
                      [107.13, -230.03],
                      [107.03, -230.37],
                      [107.37, -230.47],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 54,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 57,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0.098, -0.179],
                      [0.053, 0.043],
                      [-0.098, 0.179],
                      [-0.179, -0.098],
                    ],
                    o: [
                      [-0.098, 0.179],
                      [-0.053, -0.043],
                      [0.098, -0.179],
                      [0.179, 0.098],
                    ],
                    v: [
                      [136.689, -271.566],
                      [136.185, -271.473],
                      [136.04, -271.919],
                      [136.521, -272.027],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 59.0000024031193 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 52.0000021180034,
      op: 61.0000024845809,
      st: 31.0000012626559,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Splash 6',
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: -262 },
        p: { a: 0, k: [305.083, 107.911, 0] },
        a: { a: 0, k: [107.25, -230.25, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 54,
                s: [
                  {
                    i: [
                      [0.066, -0.121],
                      [0.121, 0.066],
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                    ],
                    o: [
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                      [0.066, -0.121],
                      [0.121, 0.066],
                    ],
                    v: [
                      [107.47, -230.13],
                      [107.13, -230.03],
                      [107.03, -230.37],
                      [107.37, -230.47],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 56,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 59,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0.098, -0.179],
                      [0.053, 0.043],
                      [-0.098, 0.179],
                      [-0.179, -0.098],
                    ],
                    o: [
                      [-0.098, 0.179],
                      [-0.053, -0.043],
                      [0.098, -0.179],
                      [0.179, 0.098],
                    ],
                    v: [
                      [136.689, -271.566],
                      [136.185, -271.473],
                      [136.04, -271.919],
                      [136.521, -272.027],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 61.0000024845809 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 54.0000021994651,
      op: 63.0000025660426,
      st: 33.0000013441176,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Splash 5',
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: -191 },
        p: { a: 0, k: [287.149, 139.465, 0] },
        a: { a: 0, k: [127.684, -254.11, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 50,
                s: [
                  {
                    i: [
                      [0.066, -0.121],
                      [0.121, 0.066],
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                    ],
                    o: [
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                      [0.066, -0.121],
                      [0.121, 0.066],
                    ],
                    v: [
                      [107.47, -230.13],
                      [107.13, -230.03],
                      [107.03, -230.37],
                      [107.37, -230.47],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 52,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 55,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0.098, -0.179],
                      [0.053, 0.043],
                      [-0.098, 0.179],
                      [-0.179, -0.098],
                    ],
                    o: [
                      [-0.098, 0.179],
                      [-0.053, -0.043],
                      [0.098, -0.179],
                      [0.179, 0.098],
                    ],
                    v: [
                      [136.689, -271.566],
                      [136.185, -271.473],
                      [136.04, -271.919],
                      [136.521, -272.027],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 57.0000023216576 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 50.0000020365418,
      op: 59.0000024031193,
      st: 29.0000011811942,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Splash 4',
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: -11 },
        p: { a: 0, k: [323.383, 57.538, 0] },
        a: { a: 0, k: [130.011, -258.056, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 52,
                s: [
                  {
                    i: [
                      [0.066, -0.121],
                      [0.121, 0.066],
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                    ],
                    o: [
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                      [0.066, -0.121],
                      [0.121, 0.066],
                    ],
                    v: [
                      [107.47, -230.13],
                      [107.13, -230.03],
                      [107.03, -230.37],
                      [107.37, -230.47],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 54,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 57,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0.098, -0.179],
                      [0.053, 0.043],
                      [-0.098, 0.179],
                      [-0.179, -0.098],
                    ],
                    o: [
                      [-0.098, 0.179],
                      [-0.053, -0.043],
                      [0.098, -0.179],
                      [0.179, 0.098],
                    ],
                    v: [
                      [136.689, -271.566],
                      [136.185, -271.473],
                      [136.04, -271.919],
                      [136.521, -272.027],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 59.0000024031193 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 52.0000021180034,
      op: 61.0000024845809,
      st: 31.0000012626559,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Shape Layer 3',
      parent: 13,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [4.729, -8.375, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 2',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: { i: [[0, 0]], o: [[0, 0]], v: [[-622, 101]], c: false },
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          ix: 2,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 2,
          ty: 'sh',
          ix: 3,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                n: '0p833_0p833_0p333_0',
                t: 0,
                s: [
                  {
                    i: [
                      [0, -0.414],
                      [0.414, 0],
                      [0.114, 0.27],
                      [0.01, 0.042],
                      [0.003, 0.037],
                      [0, 0.013],
                      [0, 0.008],
                      [-0.001, 0.017],
                      [-0.006, 0.03],
                      [-0.365, 0],
                    ],
                    o: [
                      [0, 0.414],
                      [-0.311, 0],
                      [-0.016, -0.039],
                      [-0.008, -0.035],
                      [-0.001, -0.013],
                      [0, -0.008],
                      [0, -0.018],
                      [0.002, -0.031],
                      [0.067, -0.345],
                      [0.414, 0],
                    ],
                    v: [
                      [1.75, -176.75],
                      [1, -176],
                      [0.309, -176.459],
                      [0.269, -176.581],
                      [0.253, -176.688],
                      [0.25, -176.726],
                      [0.25, -176.75],
                      [0.252, -176.802],
                      [0.264, -176.894],
                      [1, -177.5],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -38.5],
                      [38.5, 0],
                      [10.561, 25.068],
                      [0.901, 3.908],
                      [0.278, 3.394],
                      [0.037, 1.201],
                      [0, 0.733],
                      [-0.11, 1.6],
                      [-0.542, 2.79],
                      [-33.924, 0],
                    ],
                    o: [
                      [0, 38.5],
                      [-28.909, 0],
                      [-1.528, -3.626],
                      [-0.747, -3.238],
                      [-0.097, -1.185],
                      [-0.022, -0.727],
                      [0, -1.628],
                      [0.199, -2.898],
                      [6.24, -32.099],
                      [38.5, 0],
                    ],
                    v: [
                      [70.71, -176.75],
                      [1, -107.04],
                      [-63.269, -149.706],
                      [-66.928, -161.023],
                      [-68.474, -170.98],
                      [-68.676, -174.56],
                      [-68.71, -176.75],
                      [-68.544, -181.592],
                      [-67.427, -190.129],
                      [1, -246.46],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 5,
                s: [
                  {
                    i: [
                      [0, -38.5],
                      [38.5, 0],
                      [10.561, 25.068],
                      [0.901, 3.908],
                      [0.278, 3.394],
                      [0.037, 1.201],
                      [0, 0.733],
                      [-0.11, 1.6],
                      [-0.542, 2.79],
                      [-33.924, 0],
                    ],
                    o: [
                      [0, 38.5],
                      [-28.909, 0],
                      [-1.528, -3.626],
                      [-0.747, -3.238],
                      [-0.097, -1.185],
                      [-0.022, -0.727],
                      [0, -1.628],
                      [0.199, -2.898],
                      [6.24, -32.099],
                      [38.5, 0],
                    ],
                    v: [
                      [70.71, -176.75],
                      [1, -107.04],
                      [-63.269, -149.706],
                      [-66.928, -161.023],
                      [-68.474, -170.98],
                      [-68.676, -174.56],
                      [-68.71, -176.75],
                      [-68.544, -181.592],
                      [-67.427, -190.129],
                      [1, -246.46],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -24.024],
                      [24.025, 0],
                      [6.59, 15.643],
                      [0.562, 2.439],
                      [0.174, 2.118],
                      [0.023, 0.75],
                      [0, 0.457],
                      [-0.068, 0.998],
                      [-0.338, 1.741],
                      [-21.169, 0],
                    ],
                    o: [
                      [0, 24.024],
                      [-18.04, 0],
                      [-0.953, -2.263],
                      [-0.466, -2.021],
                      [-0.061, -0.74],
                      [-0.014, -0.454],
                      [0, -1.016],
                      [0.124, -1.808],
                      [3.894, -20.03],
                      [24.024, 0],
                    ],
                    v: [
                      [44.5, -176.75],
                      [1, -133.25],
                      [-39.105, -159.874],
                      [-41.389, -166.936],
                      [-42.353, -173.149],
                      [-42.479, -175.383],
                      [-42.5, -176.75],
                      [-42.397, -179.771],
                      [-41.7, -185.099],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 7,
                s: [
                  {
                    i: [
                      [0, -24.024],
                      [24.025, 0],
                      [6.59, 15.643],
                      [0.562, 2.439],
                      [0.174, 2.118],
                      [0.023, 0.75],
                      [0, 0.457],
                      [-0.068, 0.998],
                      [-0.338, 1.741],
                      [-21.169, 0],
                    ],
                    o: [
                      [0, 24.024],
                      [-18.04, 0],
                      [-0.953, -2.263],
                      [-0.466, -2.021],
                      [-0.061, -0.74],
                      [-0.014, -0.454],
                      [0, -1.016],
                      [0.124, -1.808],
                      [3.894, -20.03],
                      [24.024, 0],
                    ],
                    v: [
                      [44.5, -176.75],
                      [1, -133.25],
                      [-39.105, -159.874],
                      [-41.389, -166.936],
                      [-42.353, -173.149],
                      [-42.479, -175.383],
                      [-42.5, -176.75],
                      [-42.397, -179.771],
                      [-41.7, -185.099],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -24.024],
                      [24.024, 0],
                      [14.315, 15.643],
                      [1.284, 2.439],
                      [0.411, 2.118],
                      [0.055, 0.75],
                      [0, 0.457],
                      [-0.163, 0.998],
                      [-0.78, 1.741],
                      [-21.169, 0],
                    ],
                    o: [
                      [0, 24.024],
                      [-18.04, 0],
                      [-2.071, -2.263],
                      [-1.064, -2.021],
                      [-0.143, -0.74],
                      [-0.034, -0.454],
                      [0, -1.016],
                      [0.295, -1.808],
                      [8.969, -20.03],
                      [24.024, 0],
                    ],
                    v: [
                      [38.063, -176.75],
                      [1, -133.25],
                      [-62.336, -159.874],
                      [-67.413, -166.936],
                      [-69.65, -173.149],
                      [-69.949, -175.383],
                      [-70, -176.75],
                      [-69.753, -179.771],
                      [-68.126, -185.099],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 10,
                s: [
                  {
                    i: [
                      [0, -24.024],
                      [24.024, 0],
                      [14.315, 15.643],
                      [1.284, 2.439],
                      [0.411, 2.118],
                      [0.055, 0.75],
                      [0, 0.457],
                      [-0.163, 0.998],
                      [-0.78, 1.741],
                      [-21.169, 0],
                    ],
                    o: [
                      [0, 24.024],
                      [-18.04, 0],
                      [-2.071, -2.263],
                      [-1.064, -2.021],
                      [-0.143, -0.74],
                      [-0.034, -0.454],
                      [0, -1.016],
                      [0.295, -1.808],
                      [8.969, -20.03],
                      [24.024, 0],
                    ],
                    v: [
                      [38.063, -176.75],
                      [1, -133.25],
                      [-62.336, -159.874],
                      [-67.413, -166.936],
                      [-69.65, -173.149],
                      [-69.949, -175.383],
                      [-70, -176.75],
                      [-69.753, -179.771],
                      [-68.126, -185.099],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [3.937, -24.75],
                      [24.791, 10.086],
                      [26.888, -7.416],
                      [4.71, -1.015],
                      [0.894, 2.746],
                      [3.258, 0.928],
                      [0.486, 1.089],
                      [-0.523, 2.769],
                      [-3.31, 4.458],
                      [-54.694, 0],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-47.086, -19.156],
                      [-6.108, 1.685],
                      [-5.708, 1.23],
                      [-0.492, -1.51],
                      [-2.261, -0.644],
                      [-1.025, -2.295],
                      [0.727, -3.849],
                      [21.022, -28.316],
                      [24.024, 0],
                    ],
                    v: [
                      [46.063, -169.25],
                      [1, -133.25],
                      [-112.773, -134.745],
                      [-115.701, -140.777],
                      [-142.813, -129.799],
                      [-119.515, -152.225],
                      [-156.245, -134.275],
                      [-142.384, -147.783],
                      [-147.76, -151.014],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 15,
                s: [
                  {
                    i: [
                      [3.937, -24.75],
                      [24.791, 10.086],
                      [26.888, -7.416],
                      [4.71, -1.015],
                      [0.894, 2.746],
                      [3.258, 0.928],
                      [0.486, 1.089],
                      [-0.523, 2.769],
                      [-3.31, 4.458],
                      [-54.694, 0],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-47.086, -19.156],
                      [-6.108, 1.685],
                      [-5.708, 1.23],
                      [-0.492, -1.51],
                      [-2.261, -0.644],
                      [-1.025, -2.295],
                      [0.727, -3.849],
                      [21.022, -28.316],
                      [24.024, 0],
                    ],
                    v: [
                      [46.063, -169.25],
                      [1, -133.25],
                      [-112.773, -134.745],
                      [-115.701, -140.777],
                      [-142.813, -129.799],
                      [-119.515, -152.225],
                      [-156.245, -134.275],
                      [-142.384, -147.783],
                      [-147.76, -151.014],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [3.937, -24.75],
                      [24.791, 10.086],
                      [75.901, -44.488],
                      [4.785, 0.557],
                      [2.786, 1.657],
                      [3.258, 0.928],
                      [0.797, 0.747],
                      [1.267, 1.798],
                      [-2.7, 4.852],
                      [-54.694, 0],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-47.086, -19.156],
                      [-5.467, 3.204],
                      [-6.56, -0.763],
                      [-1.365, -0.812],
                      [-2.261, -0.644],
                      [-3.843, -3.606],
                      [-1.706, -2.421],
                      [46.978, -84.44],
                      [24.024, 0],
                    ],
                    v: [
                      [46.063, -169.25],
                      [1, -133.25],
                      [-178.164, -93.595],
                      [-165.774, -108.983],
                      [-207.061, -72.551],
                      [-185.397, -102.972],
                      [-205.964, -83.28],
                      [-166.995, -136.02],
                      [-198.497, -103.554],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 18,
                s: [
                  {
                    i: [
                      [3.937, -24.75],
                      [24.791, 10.086],
                      [75.901, -44.488],
                      [4.785, 0.557],
                      [2.786, 1.657],
                      [3.258, 0.928],
                      [0.797, 0.747],
                      [1.267, 1.798],
                      [-2.7, 4.852],
                      [-54.694, 0],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-47.086, -19.156],
                      [-5.467, 3.204],
                      [-6.56, -0.763],
                      [-1.365, -0.812],
                      [-2.261, -0.644],
                      [-3.843, -3.606],
                      [-1.706, -2.421],
                      [46.978, -84.44],
                      [24.024, 0],
                    ],
                    v: [
                      [46.063, -169.25],
                      [1, -133.25],
                      [-178.164, -93.595],
                      [-165.774, -108.983],
                      [-207.061, -72.551],
                      [-185.397, -102.972],
                      [-205.964, -83.28],
                      [-166.995, -136.02],
                      [-198.497, -103.554],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [3.937, -24.75],
                      [25.212, 8.981],
                      [6.062, -0.333],
                      [4.691, 1.099],
                      [2.786, 1.657],
                      [3.258, 0.928],
                      [0.797, 0.747],
                      [2.151, 0.459],
                      [1.537, 1.127],
                      [-191.635, 1.35],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-174.984, -62.332],
                      [-6.327, 0.347],
                      [-4.13, -0.968],
                      [-1.365, -0.812],
                      [-2.261, -0.644],
                      [-3.843, -3.606],
                      [-4.565, -0.975],
                      [-3.629, -2.662],
                      [24.024, -0.169],
                    ],
                    v: [
                      [46.062, -169.25],
                      [1, -133.25],
                      [-219.478, 24.635],
                      [-205.5, -31.17],
                      [-221.353, -1.31],
                      [-208.694, -54.13],
                      [-222.458, -18.335],
                      [-219.05, -42.263],
                      [-225.237, -35.722],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.333, y: 0 },
                n: '0p833_0p833_0p333_0',
                t: 22,
                s: [
                  {
                    i: [
                      [3.937, -24.75],
                      [25.212, 8.981],
                      [6.062, -0.333],
                      [4.691, 1.099],
                      [2.786, 1.657],
                      [3.258, 0.928],
                      [0.797, 0.747],
                      [2.151, 0.459],
                      [1.537, 1.127],
                      [-191.635, 1.35],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-174.984, -62.332],
                      [-6.327, 0.347],
                      [-4.13, -0.968],
                      [-1.365, -0.812],
                      [-2.261, -0.644],
                      [-3.843, -3.606],
                      [-4.565, -0.975],
                      [-3.629, -2.662],
                      [24.024, -0.169],
                    ],
                    v: [
                      [46.062, -169.25],
                      [1, -133.25],
                      [-219.478, 24.635],
                      [-205.5, -31.17],
                      [-221.353, -1.31],
                      [-208.694, -54.13],
                      [-222.458, -18.335],
                      [-219.05, -42.263],
                      [-225.237, -35.722],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [3.937, -24.75],
                      [25.212, 8.981],
                      [6.062, -0.333],
                      [4.691, 1.099],
                      [2.786, 1.657],
                      [3.258, 0.928],
                      [0.797, 0.747],
                      [2.151, 0.459],
                      [1.537, 1.127],
                      [-191.635, 1.35],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-174.984, -62.332],
                      [-6.327, 0.347],
                      [-4.13, -0.968],
                      [-1.365, -0.812],
                      [-2.261, -0.644],
                      [-3.843, -3.606],
                      [-4.565, -0.975],
                      [-3.629, -2.662],
                      [24.024, -0.169],
                    ],
                    v: [
                      [46.062, -169.25],
                      [1, -133.25],
                      [-219.478, 24.635],
                      [-205.5, -31.17],
                      [-221.353, -1.31],
                      [-208.694, -54.13],
                      [-222.458, -18.335],
                      [-219.05, -42.263],
                      [-225.237, -35.722],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                n: '0p667_1_0p167_0p167',
                t: 26,
                s: [
                  {
                    i: [
                      [3.937, -24.75],
                      [25.212, 8.981],
                      [6.062, -0.333],
                      [4.691, 1.099],
                      [2.786, 1.657],
                      [3.258, 0.928],
                      [0.797, 0.747],
                      [2.151, 0.459],
                      [1.537, 1.127],
                      [-191.635, 1.35],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-174.984, -62.332],
                      [-6.327, 0.347],
                      [-4.13, -0.968],
                      [-1.365, -0.812],
                      [-2.261, -0.644],
                      [-3.843, -3.606],
                      [-4.565, -0.975],
                      [-3.629, -2.662],
                      [24.024, -0.169],
                    ],
                    v: [
                      [46.062, -169.25],
                      [1, -133.25],
                      [-219.478, 24.635],
                      [-205.5, -31.17],
                      [-221.353, -1.31],
                      [-208.694, -54.13],
                      [-222.458, -18.335],
                      [-219.05, -42.263],
                      [-225.237, -35.722],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [3.937, -24.75],
                      [24.791, 10.086],
                      [75.901, -44.488],
                      [4.785, 0.557],
                      [2.786, 1.657],
                      [3.258, 0.928],
                      [0.797, 0.747],
                      [1.267, 1.798],
                      [-2.7, 4.852],
                      [-54.694, 0],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-47.086, -19.156],
                      [-5.467, 3.204],
                      [-6.56, -0.763],
                      [-1.365, -0.812],
                      [-2.261, -0.644],
                      [-3.843, -3.606],
                      [-1.706, -2.421],
                      [46.978, -84.44],
                      [24.024, 0],
                    ],
                    v: [
                      [46.063, -169.25],
                      [1, -133.25],
                      [-178.164, -93.595],
                      [-165.774, -108.983],
                      [-207.061, -72.551],
                      [-185.397, -102.972],
                      [-205.964, -83.28],
                      [-166.995, -136.02],
                      [-198.497, -103.554],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 32,
                s: [
                  {
                    i: [
                      [3.937, -24.75],
                      [24.791, 10.086],
                      [75.901, -44.488],
                      [4.785, 0.557],
                      [2.786, 1.657],
                      [3.258, 0.928],
                      [0.797, 0.747],
                      [1.267, 1.798],
                      [-2.7, 4.852],
                      [-54.694, 0],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-47.086, -19.156],
                      [-5.467, 3.204],
                      [-6.56, -0.763],
                      [-1.365, -0.812],
                      [-2.261, -0.644],
                      [-3.843, -3.606],
                      [-1.706, -2.421],
                      [46.978, -84.44],
                      [24.024, 0],
                    ],
                    v: [
                      [46.063, -169.25],
                      [1, -133.25],
                      [-178.164, -93.595],
                      [-165.774, -108.983],
                      [-207.061, -72.551],
                      [-185.397, -102.972],
                      [-205.964, -83.28],
                      [-166.995, -136.02],
                      [-198.497, -103.554],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [3.937, -24.75],
                      [24.791, 10.086],
                      [26.888, -7.416],
                      [4.71, -1.015],
                      [0.894, 2.746],
                      [3.258, 0.928],
                      [0.486, 1.089],
                      [-0.523, 2.769],
                      [-3.31, 4.458],
                      [-54.694, 0],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-47.086, -19.156],
                      [-6.108, 1.685],
                      [-5.708, 1.23],
                      [-0.492, -1.51],
                      [-2.261, -0.644],
                      [-1.025, -2.295],
                      [0.727, -3.849],
                      [21.022, -28.316],
                      [24.024, 0],
                    ],
                    v: [
                      [46.063, -169.25],
                      [1, -133.25],
                      [-112.773, -134.745],
                      [-115.701, -140.777],
                      [-142.813, -129.799],
                      [-119.515, -152.225],
                      [-156.245, -134.275],
                      [-142.384, -147.783],
                      [-147.76, -151.014],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 38,
                s: [
                  {
                    i: [
                      [3.937, -24.75],
                      [24.791, 10.086],
                      [26.888, -7.416],
                      [4.71, -1.015],
                      [0.894, 2.746],
                      [3.258, 0.928],
                      [0.486, 1.089],
                      [-0.523, 2.769],
                      [-3.31, 4.458],
                      [-54.694, 0],
                    ],
                    o: [
                      [-3.775, 23.726],
                      [-47.086, -19.156],
                      [-6.108, 1.685],
                      [-5.708, 1.23],
                      [-0.492, -1.51],
                      [-2.261, -0.644],
                      [-1.025, -2.295],
                      [0.727, -3.849],
                      [21.022, -28.316],
                      [24.024, 0],
                    ],
                    v: [
                      [46.063, -169.25],
                      [1, -133.25],
                      [-112.773, -134.745],
                      [-115.701, -140.777],
                      [-142.813, -129.799],
                      [-119.515, -152.225],
                      [-156.245, -134.275],
                      [-142.384, -147.783],
                      [-147.76, -151.014],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -24.024],
                      [24.024, 0],
                      [14.315, 15.643],
                      [1.284, 2.439],
                      [0.411, 2.118],
                      [0.055, 0.75],
                      [0, 0.457],
                      [-0.163, 0.998],
                      [-0.78, 1.741],
                      [-21.169, 0],
                    ],
                    o: [
                      [0, 24.024],
                      [-18.04, 0],
                      [-2.071, -2.263],
                      [-1.064, -2.021],
                      [-0.143, -0.74],
                      [-0.034, -0.454],
                      [0, -1.016],
                      [0.295, -1.808],
                      [8.969, -20.03],
                      [24.024, 0],
                    ],
                    v: [
                      [38.063, -176.75],
                      [1, -133.25],
                      [-62.336, -159.874],
                      [-67.413, -166.936],
                      [-69.65, -173.149],
                      [-69.949, -175.383],
                      [-70, -176.75],
                      [-69.753, -179.771],
                      [-68.126, -185.099],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 41,
                s: [
                  {
                    i: [
                      [0, -24.024],
                      [24.024, 0],
                      [14.315, 15.643],
                      [1.284, 2.439],
                      [0.411, 2.118],
                      [0.055, 0.75],
                      [0, 0.457],
                      [-0.163, 0.998],
                      [-0.78, 1.741],
                      [-21.169, 0],
                    ],
                    o: [
                      [0, 24.024],
                      [-18.04, 0],
                      [-2.071, -2.263],
                      [-1.064, -2.021],
                      [-0.143, -0.74],
                      [-0.034, -0.454],
                      [0, -1.016],
                      [0.295, -1.808],
                      [8.969, -20.03],
                      [24.024, 0],
                    ],
                    v: [
                      [38.063, -176.75],
                      [1, -133.25],
                      [-62.336, -159.874],
                      [-67.413, -166.936],
                      [-69.65, -173.149],
                      [-69.949, -175.383],
                      [-70, -176.75],
                      [-69.753, -179.771],
                      [-68.126, -185.099],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -24.024],
                      [24.025, 0],
                      [6.59, 15.643],
                      [0.562, 2.439],
                      [0.174, 2.118],
                      [0.023, 0.75],
                      [0, 0.457],
                      [-0.068, 0.998],
                      [-0.338, 1.741],
                      [-21.169, 0],
                    ],
                    o: [
                      [0, 24.024],
                      [-18.04, 0],
                      [-0.953, -2.263],
                      [-0.466, -2.021],
                      [-0.061, -0.74],
                      [-0.014, -0.454],
                      [0, -1.016],
                      [0.124, -1.808],
                      [3.894, -20.03],
                      [24.024, 0],
                    ],
                    v: [
                      [44.5, -176.75],
                      [1, -133.25],
                      [-39.105, -159.874],
                      [-41.389, -166.936],
                      [-42.353, -173.149],
                      [-42.479, -175.383],
                      [-42.5, -176.75],
                      [-42.397, -179.771],
                      [-41.7, -185.099],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 45,
                s: [
                  {
                    i: [
                      [0, -24.024],
                      [24.025, 0],
                      [6.59, 15.643],
                      [0.562, 2.439],
                      [0.174, 2.118],
                      [0.023, 0.75],
                      [0, 0.457],
                      [-0.068, 0.998],
                      [-0.338, 1.741],
                      [-21.169, 0],
                    ],
                    o: [
                      [0, 24.024],
                      [-18.04, 0],
                      [-0.953, -2.263],
                      [-0.466, -2.021],
                      [-0.061, -0.74],
                      [-0.014, -0.454],
                      [0, -1.016],
                      [0.124, -1.808],
                      [3.894, -20.03],
                      [24.024, 0],
                    ],
                    v: [
                      [44.5, -176.75],
                      [1, -133.25],
                      [-39.105, -159.874],
                      [-41.389, -166.936],
                      [-42.353, -173.149],
                      [-42.479, -175.383],
                      [-42.5, -176.75],
                      [-42.397, -179.771],
                      [-41.7, -185.099],
                      [1, -220.25],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -38.5],
                      [38.5, 0],
                      [10.561, 25.068],
                      [0.901, 3.908],
                      [0.278, 3.394],
                      [0.037, 1.201],
                      [0, 0.733],
                      [-0.11, 1.6],
                      [-0.542, 2.79],
                      [-33.924, 0],
                    ],
                    o: [
                      [0, 38.5],
                      [-28.909, 0],
                      [-1.528, -3.626],
                      [-0.747, -3.238],
                      [-0.097, -1.185],
                      [-0.022, -0.727],
                      [0, -1.628],
                      [0.199, -2.898],
                      [6.24, -32.099],
                      [38.5, 0],
                    ],
                    v: [
                      [70.71, -176.75],
                      [1, -107.04],
                      [-63.269, -149.706],
                      [-66.928, -161.023],
                      [-68.474, -170.98],
                      [-68.676, -174.56],
                      [-68.71, -176.75],
                      [-68.544, -181.592],
                      [-67.427, -190.129],
                      [1, -246.46],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 47,
                s: [
                  {
                    i: [
                      [0, -38.5],
                      [38.5, 0],
                      [10.561, 25.068],
                      [0.901, 3.908],
                      [0.278, 3.394],
                      [0.037, 1.201],
                      [0, 0.733],
                      [-0.11, 1.6],
                      [-0.542, 2.79],
                      [-33.924, 0],
                    ],
                    o: [
                      [0, 38.5],
                      [-28.909, 0],
                      [-1.528, -3.626],
                      [-0.747, -3.238],
                      [-0.097, -1.185],
                      [-0.022, -0.727],
                      [0, -1.628],
                      [0.199, -2.898],
                      [6.24, -32.099],
                      [38.5, 0],
                    ],
                    v: [
                      [70.71, -176.75],
                      [1, -107.04],
                      [-63.269, -149.706],
                      [-66.928, -161.023],
                      [-68.474, -170.98],
                      [-68.676, -174.56],
                      [-68.71, -176.75],
                      [-68.544, -181.592],
                      [-67.427, -190.129],
                      [1, -246.46],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -0.414],
                      [0.414, 0],
                      [0.114, 0.27],
                      [0.01, 0.042],
                      [0.003, 0.037],
                      [0, 0.013],
                      [0, 0.008],
                      [-0.001, 0.017],
                      [-0.006, 0.03],
                      [-0.365, 0],
                    ],
                    o: [
                      [0, 0.414],
                      [-0.311, 0],
                      [-0.016, -0.039],
                      [-0.008, -0.035],
                      [-0.001, -0.013],
                      [0, -0.008],
                      [0, -0.018],
                      [0.002, -0.031],
                      [0.067, -0.345],
                      [0.414, 0],
                    ],
                    v: [
                      [1.75, -176.75],
                      [1, -176],
                      [0.309, -176.459],
                      [0.269, -176.581],
                      [0.253, -176.688],
                      [0.25, -176.726],
                      [0.25, -176.75],
                      [0.252, -176.802],
                      [0.264, -176.894],
                      [1, -177.5],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 55.0000022401959 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 0,
      op: 60.0000024438501,
      st: 0,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Splash 8',
      parent: 13,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 181 },
        p: { a: 0, k: [-119.127, -211.088, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 31,
                s: [
                  {
                    i: [
                      [0.066, -0.121],
                      [0.121, 0.066],
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                    ],
                    o: [
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                      [0.066, -0.121],
                      [0.121, 0.066],
                    ],
                    v: [
                      [94.47, -140.13],
                      [94.13, -140.03],
                      [94.03, -140.37],
                      [94.37, -140.47],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [2.591, -4.756],
                      [4.756, 2.591],
                      [-2.591, 4.756],
                      [-4.757, -2.591],
                    ],
                    o: [
                      [-2.591, 4.756],
                      [-4.756, -2.591],
                      [2.591, -4.756],
                      [4.757, 2.591],
                    ],
                    v: [
                      [106.279, -141.477],
                      [92.977, -137.555],
                      [89.055, -150.856],
                      [102.356, -154.778],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 33,
                s: [
                  {
                    i: [
                      [2.591, -4.756],
                      [4.756, 2.591],
                      [-2.591, 4.756],
                      [-4.757, -2.591],
                    ],
                    o: [
                      [-2.591, 4.756],
                      [-4.756, -2.591],
                      [2.591, -4.756],
                      [4.757, 2.591],
                    ],
                    v: [
                      [106.279, -141.477],
                      [92.977, -137.555],
                      [89.055, -150.856],
                      [102.356, -154.778],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [2.7, -4.695],
                      [1.391, 0.912],
                      [-8.305, 37.856],
                      [-4.757, -2.591],
                    ],
                    o: [
                      [-17.529, 30.477],
                      [-1.727, -1.133],
                      [1.161, -5.29],
                      [4.757, 2.591],
                    ],
                    v: [
                      [105.529, -139.977],
                      [49.727, -77.055],
                      [88.305, -149.356],
                      [102.606, -155.028],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 36,
                s: [
                  {
                    i: [
                      [2.7, -4.695],
                      [1.391, 0.912],
                      [-8.305, 37.856],
                      [-4.757, -2.591],
                    ],
                    o: [
                      [-17.529, 30.477],
                      [-1.727, -1.133],
                      [1.161, -5.29],
                      [4.757, 2.591],
                    ],
                    v: [
                      [105.529, -139.977],
                      [49.727, -77.055],
                      [88.305, -149.356],
                      [102.606, -155.028],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [2.7, -4.695],
                      [1.391, 0.912],
                      [-8.305, 37.856],
                      [-4.757, -2.591],
                    ],
                    o: [
                      [-17.529, 30.477],
                      [-1.727, -1.133],
                      [1.161, -5.29],
                      [4.757, 2.591],
                    ],
                    v: [
                      [105.529, -139.977],
                      [49.727, -77.055],
                      [88.305, -149.356],
                      [102.606, -155.028],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 37,
                s: [
                  {
                    i: [
                      [2.7, -4.695],
                      [1.391, 0.912],
                      [-8.305, 37.856],
                      [-4.757, -2.591],
                    ],
                    o: [
                      [-17.529, 30.477],
                      [-1.727, -1.133],
                      [1.161, -5.29],
                      [4.757, 2.591],
                    ],
                    v: [
                      [105.529, -139.977],
                      [49.727, -77.055],
                      [88.305, -149.356],
                      [102.606, -155.028],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [2.591, -4.756],
                      [4.756, 2.591],
                      [-2.591, 4.756],
                      [-4.757, -2.591],
                    ],
                    o: [
                      [-2.591, 4.756],
                      [-4.756, -2.591],
                      [2.591, -4.756],
                      [4.757, 2.591],
                    ],
                    v: [
                      [106.279, -141.477],
                      [92.977, -137.555],
                      [89.055, -150.856],
                      [102.356, -154.778],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 41,
                s: [
                  {
                    i: [
                      [2.591, -4.756],
                      [4.756, 2.591],
                      [-2.591, 4.756],
                      [-4.757, -2.591],
                    ],
                    o: [
                      [-2.591, 4.756],
                      [-4.756, -2.591],
                      [2.591, -4.756],
                      [4.757, 2.591],
                    ],
                    v: [
                      [106.279, -141.477],
                      [92.977, -137.555],
                      [89.055, -150.856],
                      [102.356, -154.778],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0.066, -0.121],
                      [0.121, 0.066],
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                    ],
                    o: [
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                      [0.066, -0.121],
                      [0.121, 0.066],
                    ],
                    v: [
                      [99.47, -145.13],
                      [99.13, -145.03],
                      [99.03, -145.37],
                      [99.369, -145.47],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 44.0000017921567 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 31.0000012626559,
      op: 46.0000018736184,
      st: 10.0000004073083,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Splash 3',
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: -262 },
        p: { a: 0, k: [112, 11.66, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 8,
                s: [
                  {
                    i: [
                      [0.066, -0.121],
                      [0.121, 0.066],
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                    ],
                    o: [
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                      [0.066, -0.121],
                      [0.121, 0.066],
                    ],
                    v: [
                      [107.47, -230.13],
                      [107.13, -230.03],
                      [107.03, -230.37],
                      [107.37, -230.47],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 10,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 13,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0.098, -0.179],
                      [0.053, 0.043],
                      [-0.098, 0.179],
                      [-0.179, -0.098],
                    ],
                    o: [
                      [-0.098, 0.179],
                      [-0.053, -0.043],
                      [0.098, -0.179],
                      [0.179, 0.098],
                    ],
                    v: [
                      [136.689, -271.566],
                      [136.185, -271.473],
                      [136.04, -271.919],
                      [136.521, -272.027],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 15.0000006109625 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 8.00000032584668,
      op: 17.0000006924242,
      st: -13.0000005295009,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Splash 2',
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: -191 },
        p: { a: 0, k: [342, -100.34, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 5,
                s: [
                  {
                    i: [
                      [0.066, -0.121],
                      [0.121, 0.066],
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                    ],
                    o: [
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                      [0.066, -0.121],
                      [0.121, 0.066],
                    ],
                    v: [
                      [107.47, -230.13],
                      [107.13, -230.03],
                      [107.03, -230.37],
                      [107.37, -230.47],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 7,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 10,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0.098, -0.179],
                      [0.053, 0.043],
                      [-0.098, 0.179],
                      [-0.179, -0.098],
                    ],
                    o: [
                      [-0.098, 0.179],
                      [-0.053, -0.043],
                      [0.098, -0.179],
                      [0.179, 0.098],
                    ],
                    v: [
                      [136.689, -271.566],
                      [136.185, -271.473],
                      [136.04, -271.919],
                      [136.521, -272.027],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 12.00000048877 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 5.00000020365417,
      op: 14.0000005702317,
      st: -16.0000006516934,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Splash 1',
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: -11 },
        p: { a: 0, k: [262, 295.66, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 4,
                s: [
                  {
                    i: [
                      [0.066, -0.121],
                      [0.121, 0.066],
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                    ],
                    o: [
                      [-0.066, 0.121],
                      [-0.121, -0.066],
                      [0.066, -0.121],
                      [0.121, 0.066],
                    ],
                    v: [
                      [107.47, -230.13],
                      [107.13, -230.03],
                      [107.03, -230.37],
                      [107.37, -230.47],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 6,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [133.035, -254.315],
                      [117.053, -239.365],
                      [124.694, -258.857],
                      [130.88, -260.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 9,
                s: [
                  {
                    i: [
                      [1.254, -2.303],
                      [0.675, 0.552],
                      [-1.254, 2.303],
                      [-2.303, -1.254],
                    ],
                    o: [
                      [-1.254, 2.303],
                      [-0.675, -0.552],
                      [1.254, -2.303],
                      [2.303, 1.254],
                    ],
                    v: [
                      [135.535, -259.315],
                      [129.053, -258.115],
                      [127.194, -263.857],
                      [133.38, -265.246],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0.098, -0.179],
                      [0.053, 0.043],
                      [-0.098, 0.179],
                      [-0.179, -0.098],
                    ],
                    o: [
                      [-0.098, 0.179],
                      [-0.053, -0.043],
                      [0.098, -0.179],
                      [0.179, 0.098],
                    ],
                    v: [
                      [136.689, -271.566],
                      [136.185, -271.473],
                      [136.04, -271.919],
                      [136.521, -272.027],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 11.0000004480392 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 4.00000016292334,
      op: 13.0000005295009,
      st: -17.0000006924242,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'fleck 5',
      parent: 13,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 7 },
        p: { a: 0, k: [-1.5, -24.84, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 31,
                s: [
                  {
                    i: [
                      [0, -0.207],
                      [0.207, 0],
                      [0, 0.207],
                      [-0.207, 0],
                    ],
                    o: [
                      [0, 0.207],
                      [-0.207, 0],
                      [0, -0.207],
                      [0.207, 0],
                    ],
                    v: [
                      [-202.125, 0],
                      [-202.5, 0.375],
                      [-202.875, 0],
                      [-202.5, -0.375],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 5.156],
                      [-199.844, 7.375],
                      [-202.062, 5.156],
                      [-199.844, 2.937],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 33,
                s: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 5.156],
                      [-199.844, 7.375],
                      [-202.062, 5.156],
                      [-199.844, 2.937],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 25.156],
                      [-199.844, 27.414],
                      [-202.062, 25.156],
                      [-199.937, 9.187],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 38,
                s: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 25.156],
                      [-199.844, 27.414],
                      [-202.062, 25.156],
                      [-199.937, 9.187],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-1.25, -4.156],
                      [1.225, 0],
                      [0.031, 1.225],
                      [-0.75, -0.406],
                    ],
                    o: [
                      [0.353, 1.173],
                      [-1.225, 0],
                      [-0.125, -4.969],
                      [0.855, 0.519],
                    ],
                    v: [
                      [-197.625, 42.656],
                      [-199.844, 44.914],
                      [-202.062, 42.656],
                      [-199.937, 26.687],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 39,
                s: [
                  {
                    i: [
                      [-1.25, -4.156],
                      [1.225, 0],
                      [0.031, 1.225],
                      [-0.75, -0.406],
                    ],
                    o: [
                      [0.353, 1.173],
                      [-1.225, 0],
                      [-0.125, -4.969],
                      [0.855, 0.519],
                    ],
                    v: [
                      [-197.625, 42.656],
                      [-199.844, 44.914],
                      [-202.062, 42.656],
                      [-199.937, 26.687],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-2.175, -3.756],
                      [1.192, -0.283],
                      [0.313, 1.185],
                      [-0.824, -0.222],
                    ],
                    o: [
                      [0.614, 1.06],
                      [-1.192, 0.283],
                      [-1.268, -4.806],
                      [0.952, 0.308],
                    ],
                    v: [
                      [-191.053, 64.48],
                      [-192.691, 67.189],
                      [-195.371, 65.504],
                      [-196.988, 49.476],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 41,
                s: [
                  {
                    i: [
                      [-2.175, -3.756],
                      [1.192, -0.283],
                      [0.313, 1.185],
                      [-0.824, -0.222],
                    ],
                    o: [
                      [0.614, 1.06],
                      [-1.192, 0.283],
                      [-1.268, -4.806],
                      [0.952, 0.308],
                    ],
                    v: [
                      [-191.053, 64.48],
                      [-192.691, 67.189],
                      [-195.371, 65.504],
                      [-196.988, 49.476],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-0.416, -1.152],
                      [1.153, -0.416],
                      [0.416, 1.153],
                      [-1.153, 0.416],
                    ],
                    o: [
                      [0.416, 1.152],
                      [-1.153, 0.416],
                      [-0.416, -1.153],
                      [1.153, -0.416],
                    ],
                    v: [
                      [-179.68, 88.246],
                      [-181.001, 91.123],
                      [-183.854, 89.752],
                      [-187.275, 74.01],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 43,
                s: [
                  {
                    i: [
                      [-0.416, -1.152],
                      [1.153, -0.416],
                      [0.416, 1.153],
                      [-1.153, 0.416],
                    ],
                    o: [
                      [0.416, 1.152],
                      [-1.153, 0.416],
                      [-0.416, -1.153],
                      [1.153, -0.416],
                    ],
                    v: [
                      [-179.68, 88.246],
                      [-181.001, 91.123],
                      [-183.854, 89.752],
                      [-187.275, 74.01],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-0.51, -1.114],
                      [1.114, -0.51],
                      [0.51, 1.114],
                      [-1.114, 0.51],
                    ],
                    o: [
                      [0.51, 1.114],
                      [-1.114, 0.51],
                      [-0.51, -1.114],
                      [1.114, -0.51],
                    ],
                    v: [
                      [-177.826, 91.483],
                      [-178.921, 94.424],
                      [-181.861, 93.329],
                      [-180.767, 90.389],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 45,
                s: [
                  {
                    i: [
                      [-0.51, -1.114],
                      [1.114, -0.51],
                      [0.51, 1.114],
                      [-1.114, 0.51],
                    ],
                    o: [
                      [0.51, 1.114],
                      [-1.114, 0.51],
                      [-0.51, -1.114],
                      [1.114, -0.51],
                    ],
                    v: [
                      [-177.826, 91.483],
                      [-178.921, 94.424],
                      [-181.861, 93.329],
                      [-180.767, 90.389],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-0.082, -0.179],
                      [0.179, -0.082],
                      [0.082, 0.179],
                      [-0.179, 0.082],
                    ],
                    o: [
                      [0.082, 0.179],
                      [-0.179, 0.082],
                      [-0.082, -0.179],
                      [0.179, -0.082],
                    ],
                    v: [
                      [-179.519, 92.258],
                      [-179.695, 92.73],
                      [-180.168, 92.555],
                      [-179.992, 92.082],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 47.0000019143492 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 31.0000012626559,
      op: 48.0000019550801,
      st: 10.0000004073083,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'fleck 4',
      parent: 13,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 14 },
        p: { a: 0, k: [-25.487, -18.198, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 24,
                s: [
                  {
                    i: [
                      [0, -0.207],
                      [0.207, 0],
                      [0, 0.207],
                      [-0.207, 0],
                    ],
                    o: [
                      [0, 0.207],
                      [-0.207, 0],
                      [0, -0.207],
                      [0.207, 0],
                    ],
                    v: [
                      [-202.125, 0],
                      [-202.5, 0.375],
                      [-202.875, 0],
                      [-202.5, -0.375],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 5.156],
                      [-199.844, 7.375],
                      [-202.062, 5.156],
                      [-199.844, 2.937],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 26,
                s: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 5.156],
                      [-199.844, 7.375],
                      [-202.062, 5.156],
                      [-199.844, 2.937],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 25.156],
                      [-199.844, 27.414],
                      [-202.062, 25.156],
                      [-199.937, 9.187],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 31,
                s: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 25.156],
                      [-199.844, 27.414],
                      [-202.062, 25.156],
                      [-199.937, 9.187],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-1.25, -4.156],
                      [1.225, 0],
                      [0.031, 1.225],
                      [-0.75, -0.406],
                    ],
                    o: [
                      [0.353, 1.173],
                      [-1.225, 0],
                      [-0.125, -4.969],
                      [0.855, 0.519],
                    ],
                    v: [
                      [-197.625, 42.656],
                      [-199.844, 44.914],
                      [-202.062, 42.656],
                      [-199.937, 26.687],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 32,
                s: [
                  {
                    i: [
                      [-1.25, -4.156],
                      [1.225, 0],
                      [0.031, 1.225],
                      [-0.75, -0.406],
                    ],
                    o: [
                      [0.353, 1.173],
                      [-1.225, 0],
                      [-0.125, -4.969],
                      [0.855, 0.519],
                    ],
                    v: [
                      [-197.625, 42.656],
                      [-199.844, 44.914],
                      [-202.062, 42.656],
                      [-199.937, 26.687],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-2.175, -3.756],
                      [1.192, -0.283],
                      [0.313, 1.185],
                      [-0.824, -0.222],
                    ],
                    o: [
                      [0.614, 1.06],
                      [-1.192, 0.283],
                      [-1.268, -4.806],
                      [0.952, 0.308],
                    ],
                    v: [
                      [-191.053, 64.48],
                      [-192.691, 67.189],
                      [-195.371, 65.504],
                      [-196.988, 49.476],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 34,
                s: [
                  {
                    i: [
                      [-2.175, -3.756],
                      [1.192, -0.283],
                      [0.313, 1.185],
                      [-0.824, -0.222],
                    ],
                    o: [
                      [0.614, 1.06],
                      [-1.192, 0.283],
                      [-1.268, -4.806],
                      [0.952, 0.308],
                    ],
                    v: [
                      [-191.053, 64.48],
                      [-192.691, 67.189],
                      [-195.371, 65.504],
                      [-196.988, 49.476],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-0.416, -1.152],
                      [1.153, -0.416],
                      [0.416, 1.153],
                      [-1.153, 0.416],
                    ],
                    o: [
                      [0.416, 1.152],
                      [-1.153, 0.416],
                      [-0.416, -1.153],
                      [1.153, -0.416],
                    ],
                    v: [
                      [-179.68, 88.246],
                      [-181.001, 91.123],
                      [-183.854, 89.752],
                      [-187.275, 74.01],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 36,
                s: [
                  {
                    i: [
                      [-0.416, -1.152],
                      [1.153, -0.416],
                      [0.416, 1.153],
                      [-1.153, 0.416],
                    ],
                    o: [
                      [0.416, 1.152],
                      [-1.153, 0.416],
                      [-0.416, -1.153],
                      [1.153, -0.416],
                    ],
                    v: [
                      [-179.68, 88.246],
                      [-181.001, 91.123],
                      [-183.854, 89.752],
                      [-187.275, 74.01],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-0.51, -1.114],
                      [1.114, -0.51],
                      [0.51, 1.114],
                      [-1.114, 0.51],
                    ],
                    o: [
                      [0.51, 1.114],
                      [-1.114, 0.51],
                      [-0.51, -1.114],
                      [1.114, -0.51],
                    ],
                    v: [
                      [-177.826, 91.483],
                      [-178.921, 94.424],
                      [-181.861, 93.329],
                      [-180.767, 90.389],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 38,
                s: [
                  {
                    i: [
                      [-0.51, -1.114],
                      [1.114, -0.51],
                      [0.51, 1.114],
                      [-1.114, 0.51],
                    ],
                    o: [
                      [0.51, 1.114],
                      [-1.114, 0.51],
                      [-0.51, -1.114],
                      [1.114, -0.51],
                    ],
                    v: [
                      [-177.826, 91.483],
                      [-178.921, 94.424],
                      [-181.861, 93.329],
                      [-180.767, 90.389],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-0.082, -0.179],
                      [0.179, -0.082],
                      [0.082, 0.179],
                      [-0.179, 0.082],
                    ],
                    o: [
                      [0.082, 0.179],
                      [-0.179, 0.082],
                      [-0.082, -0.179],
                      [0.179, -0.082],
                    ],
                    v: [
                      [-179.519, 92.258],
                      [-179.695, 92.73],
                      [-180.168, 92.555],
                      [-179.992, 92.082],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 40.0000016292334 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 24.00000097754,
      op: 41.0000016699642,
      st: 3.00000012219251,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'fleck 1',
      parent: 13,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [3.784, -8.842, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'st',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.157, 0.247, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
        },
        {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 21,
                s: [
                  {
                    i: [
                      [0, -0.207],
                      [0.207, 0],
                      [0, 0.207],
                      [-0.207, 0],
                    ],
                    o: [
                      [0, 0.207],
                      [-0.207, 0],
                      [0, -0.207],
                      [0.207, 0],
                    ],
                    v: [
                      [-197.125, 0],
                      [-197.5, 0.375],
                      [-197.875, 0],
                      [-197.5, -0.375],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 5.156],
                      [-199.844, 7.375],
                      [-202.062, 5.156],
                      [-199.844, 2.937],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 23,
                s: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 5.156],
                      [-199.844, 7.375],
                      [-202.062, 5.156],
                      [-199.844, 2.937],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 25.156],
                      [-199.844, 27.414],
                      [-202.062, 25.156],
                      [-199.937, 9.187],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 28,
                s: [
                  {
                    i: [
                      [0, -1.225],
                      [1.225, 0],
                      [0, 1.225],
                      [-1.225, 0],
                    ],
                    o: [
                      [0, 1.225],
                      [-1.225, 0],
                      [0, -1.225],
                      [1.225, 0],
                    ],
                    v: [
                      [-197.625, 25.156],
                      [-199.844, 27.414],
                      [-202.062, 25.156],
                      [-199.937, 9.187],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-1.25, -4.156],
                      [1.225, 0],
                      [0.031, 1.225],
                      [-0.75, -0.406],
                    ],
                    o: [
                      [0.353, 1.173],
                      [-1.225, 0],
                      [-0.125, -4.969],
                      [0.855, 0.519],
                    ],
                    v: [
                      [-197.625, 42.656],
                      [-199.844, 44.914],
                      [-202.062, 42.656],
                      [-199.937, 26.687],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 29,
                s: [
                  {
                    i: [
                      [-1.25, -4.156],
                      [1.225, 0],
                      [0.031, 1.225],
                      [-0.75, -0.406],
                    ],
                    o: [
                      [0.353, 1.173],
                      [-1.225, 0],
                      [-0.125, -4.969],
                      [0.855, 0.519],
                    ],
                    v: [
                      [-197.625, 42.656],
                      [-199.844, 44.914],
                      [-202.062, 42.656],
                      [-199.937, 26.687],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-2.175, -3.756],
                      [1.192, -0.283],
                      [0.313, 1.185],
                      [-0.824, -0.222],
                    ],
                    o: [
                      [0.614, 1.06],
                      [-1.192, 0.283],
                      [-1.268, -4.806],
                      [0.952, 0.308],
                    ],
                    v: [
                      [-191.053, 64.48],
                      [-192.691, 67.189],
                      [-195.371, 65.504],
                      [-196.988, 49.476],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 31,
                s: [
                  {
                    i: [
                      [-2.175, -3.756],
                      [1.192, -0.283],
                      [0.313, 1.185],
                      [-0.824, -0.222],
                    ],
                    o: [
                      [0.614, 1.06],
                      [-1.192, 0.283],
                      [-1.268, -4.806],
                      [0.952, 0.308],
                    ],
                    v: [
                      [-191.053, 64.48],
                      [-192.691, 67.189],
                      [-195.371, 65.504],
                      [-196.988, 49.476],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-0.416, -1.152],
                      [1.153, -0.416],
                      [0.416, 1.153],
                      [-1.153, 0.416],
                    ],
                    o: [
                      [0.416, 1.152],
                      [-1.153, 0.416],
                      [-0.416, -1.153],
                      [1.153, -0.416],
                    ],
                    v: [
                      [-179.68, 88.246],
                      [-181.001, 91.123],
                      [-183.854, 89.752],
                      [-187.275, 74.01],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 33,
                s: [
                  {
                    i: [
                      [-0.416, -1.152],
                      [1.153, -0.416],
                      [0.416, 1.153],
                      [-1.153, 0.416],
                    ],
                    o: [
                      [0.416, 1.152],
                      [-1.153, 0.416],
                      [-0.416, -1.153],
                      [1.153, -0.416],
                    ],
                    v: [
                      [-179.68, 88.246],
                      [-181.001, 91.123],
                      [-183.854, 89.752],
                      [-187.275, 74.01],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-0.51, -1.114],
                      [1.114, -0.51],
                      [0.51, 1.114],
                      [-1.114, 0.51],
                    ],
                    o: [
                      [0.51, 1.114],
                      [-1.114, 0.51],
                      [-0.51, -1.114],
                      [1.114, -0.51],
                    ],
                    v: [
                      [-177.826, 91.483],
                      [-178.921, 94.424],
                      [-181.861, 93.329],
                      [-180.767, 90.389],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 35,
                s: [
                  {
                    i: [
                      [-0.51, -1.114],
                      [1.114, -0.51],
                      [0.51, 1.114],
                      [-1.114, 0.51],
                    ],
                    o: [
                      [0.51, 1.114],
                      [-1.114, 0.51],
                      [-0.51, -1.114],
                      [1.114, -0.51],
                    ],
                    v: [
                      [-177.826, 91.483],
                      [-178.921, 94.424],
                      [-181.861, 93.329],
                      [-180.767, 90.389],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [-0.082, -0.179],
                      [0.179, -0.082],
                      [0.082, 0.179],
                      [-0.179, 0.082],
                    ],
                    o: [
                      [0.082, 0.179],
                      [-0.179, 0.082],
                      [-0.082, -0.179],
                      [0.179, -0.082],
                    ],
                    v: [
                      [-179.519, 92.258],
                      [-179.695, 92.73],
                      [-180.168, 92.555],
                      [-179.992, 92.082],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 37.0000015070409 },
            ],
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.8470588, 0.0862745, 0.2117647, 1] },
          o: { a: 0, k: 100 },
          r: 1,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
        },
      ],
      ip: 21.0000008553475,
      op: 38.0000015477717,
      st: 0,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 3,
      nm: 'Shape Layer 1',
      ks: {
        o: { a: 0, k: 0 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.497], y: [1] },
              o: { x: [0.397], y: [0] },
              n: ['0p497_1_0p397_0'],
              t: 9,
              s: [0],
              e: [360],
            },
            { t: 43.0000017514259 },
          ],
        },
        p: { a: 0, k: [295.5, 291.5, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      ip: 0,
      op: 67.0000027289659,
      st: 0,
      bm: 0,
      sr: 1,
    },
  ],
};
