import { FC } from 'react';
import { TFeedListItem } from '../../../types/feed-list-item.type';
import { useModalContext } from '../../../modal/context';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { FeedListItem } from './FeedListItem';

interface FeedListProps {
  type: string;
  queryFn: (
    type: string,
    page: number
  ) => Promise<{
    rows: TFeedListItem[];
    total_count: number;
    page: number;
    page_size: number;
  }>;
}

export const FeedList: FC<FeedListProps> = ({ queryFn, type }) => {
  const modalDispatch = useModalContext();
  const query = useInfiniteQuery({
    queryKey: ['feed-list', type],
    queryFn({ pageParam = 1 }) {
      return queryFn(type, pageParam);
    },
    throwOnError(error) {
      modalDispatch.openModal({
        type: 'alert',
        title: '오류',
        text: error.message,
      });
      return false;
    },
    retry: false,
    initialPageParam: 1,
    getNextPageParam(lastPage) {
      if (
        lastPage.page < Math.ceil(lastPage.total_count / lastPage.page_size)
      ) {
        return lastPage.page + 1;
      }
      return undefined;
    },
  });
  const rows = query.data?.pages?.flatMap?.((v) => v.rows) ?? [];
  const [ref] = useInView({
    threshold: 0,
    onChange(inView) {
      if (inView && query.hasNextPage && !query.isFetchingNextPage) {
        query.fetchNextPage();
      }
    },
  });
  if (query.isLoading) {
    return (
      <div className="h-full flex justify-center items-center pb-12">
        <LoadingIndicator />
      </div>
    );
  }
  if (rows.length === 0) {
    return (
      <div className="h-full flex justify-center items-center pb-12">
        <p className="text-lg font-semibold text-gray300">항목이 없습니다.</p>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="py-2.5 px-5 space-y-6">
        {rows.map((v) => {
          return <FeedListItem {...v} key={v._id} type={type} />;
        })}
        <div ref={ref} style={{ height: 20 }}></div>
      </div>
    </div>
  );
};
