import React, { FC, useState } from 'react';
import { Layout } from '../../components/Layout';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { useModalContext } from '../../modal/context';
import { isRn } from '../../utils/rn-utils';
import {useAuthData, useAuthDispatch} from '../../auth';
import { userApis } from '../../apis/user.apis';
import { useNavigate } from 'react-router-dom';
import { HttpError } from '../../apis/http-error';
import { refreshTokenStorage } from '../../utils/refreshTokenStorage';
import { SamhwaBrandLogo } from '@assets/components/samhwa-brand-logo';
import { NoticeTopic } from 'constants/notice-topic';
import { rnBridge } from 'utils/rn-bridge';
import {IoCheckboxOutline, IoCheckboxSharp} from "react-icons/io5";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {TermsOfService} from "../../components/terms/TermsOfService";
import {PrivacyPolicy} from "../../components/terms/PrivacyPolicy";
import * as Yup from "yup";
import TermsModal from "../../components/terms/TermsModal";

interface TermScreenProps {}

export const TermScreen: FC<TermScreenProps> = () => {
  const authData = useAuthData();
  const authDispatch = useAuthDispatch();
  const modalDispatch = useModalContext();
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [termsType, setTermsType] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const formik = useFormik({
    initialValues: {
      allAgree: false,
      termsOfService: false,
      privacyPolicy: false,
    },
    validationSchema: Yup.object({
      allAgree: Yup.boolean().oneOf([true], '필수 항목은 반드시 동의 되어야 합니다.'),
    }),
    onSubmit: async (values, helper) => {
      try {
        await userApis.agreeServiceTerm();
        authDispatch.update({ service_term_agree: true , privacy_policy_agree: true});
        if(authData.user?.service_notification_enabled){
          rnBridge.subscribeFCMTopic(NoticeTopic);
        }
        navigate('/home', { replace: true });
      } catch (e: any) {
        const error = e as HttpError;
        if (error.code === 'USER_NOT_FOUND') {
          authDispatch.logout();
          navigate('/home', { replace: true });
        } else {
          modalDispatch.openModal({
            type: 'alert',
            title: '오류',
            text: error.message,
          });
        }
      } finally {
        helper.setSubmitting(false);
      }
    }
  });

  const handleAllAgreeChange = () => {
    const { allAgree } = formik.values;
    formik.setValues({
      allAgree: !allAgree,
      termsOfService: !allAgree,
      privacyPolicy: !allAgree,
    });
  };

  const handleIndividualChange = (field: string, value: boolean) => {
    formik.setFieldValue(field, value);

    const { termsOfService, privacyPolicy } = formik.values;

    // Check if both individual checkboxes are checked
    const allAgree = (field === 'termsOfService' ? value : termsOfService) &&
        (field === 'privacyPolicy' ? value : privacyPolicy);

    formik.setFieldValue('allAgree', allAgree);
  };

  const handleShowModal = (content: React.ReactNode, type:string, title:string) => {
    setTermsType(type);
    setModalContent(content);
    setShowModal(true);
    setModalTitle(title);
  };

  // 모달 닫기 함수
  const handleCloseModal = (type:string, isAgree:boolean) => {
    if(isAgree){
      if(type === 'privacyPolicy'){
        formik.values.privacyPolicy = true;
      }else if(type=== 'termsOfService'){
        formik.values.termsOfService = true;
      }

      if(formik.values.privacyPolicy &&  formik.values.termsOfService ){
        formik.values.allAgree = true;
      }
    }
    setShowModal(false);  // 모달을 닫기만 하고 폼 값을 변경하지 않음
  };

  const handleQuit = () => {
    setInProgress(true);
    authDispatch.logout();
    if (isRn()) {
      rnBridge.appQuit();
    }
    setInProgress(false);
  };
  const handleDismiss = () => {
    refreshTokenStorage.remove();
    modalDispatch.openModal(
      {
        type: 'alert',
        title: '서비스 이용약관에 동의 하셔야\n앱을 이용하실수 있습니다.',
        text: '',
      },
      { onClose: handleQuit }
    );
  };

  return (
    <Layout renderHeader={<></>} hideFooter>
      <Container>
        <h1 className="text-head font-bold mb-10">
          앱 이용을 위해
          <br />
          아래 <span className="text-red500">이용약관 동의</span>가 필요합니다.
        </h1>
        <FormikProvider value={formik}>
          <Form className="mt-1.5">
        <div className="flex flex-col ">
          <div className="flex items-center mb-5 px-2 py-2 cursor-pointer"
               style={{borderBottom: '1px solid var(--grayscale-100)'}}
               onClick={handleAllAgreeChange}>
            {formik.values.allAgree ? <IoCheckboxSharp className="w-6 h-6 mr-2"/> :
                <IoCheckboxOutline className="w-6 h-6 mr-2"/>}
            <Field
                type="checkbox"
                name="allAgree"
                checked={formik.values.allAgree}
                onChange={handleAllAgreeChange}
                className="hidden"
            />
            전체 동의하기
          </div>
          <div className="flex items-center mb-3 px-2 py-2 cursor-pointer">
            <div className="flex items-center cursor-pointer w-full justify-between"
                 onClick={() => handleIndividualChange('termsOfService', !formik.values.termsOfService)}>
              {formik.values.termsOfService ? <IoCheckboxSharp className="w-6 h-6 mr-2"/> :
                  <IoCheckboxOutline className="w-6 h-6 mr-2"/>}
              <Field
                  type="checkbox"
                  name="termsOfService"
                  checked={formik.values.termsOfService}
                  onChange={() => formik.setFieldValue('termsOfService', !formik.values.termsOfService)}
                  className="hidden"
              />
              <span className="flex-grow">[필수] 서비스 이용약관</span>
            </div>
            <button type="button" className="underline text-gray400 text-sm w-14"
                    onClick={() => handleShowModal(<TermsOfService/>, 'termsOfService', '서비스 이용약관')}>
              내용보기
            </button>
          </div>
          <div className="flex items-center mb-3 px-2 py-2 cursor-pointer">
            <div className="flex items-center cursor-pointer w-full justify-between"
                 onClick={() => handleIndividualChange('privacyPolicy', !formik.values.privacyPolicy)}>
              {formik.values.privacyPolicy ? <IoCheckboxSharp className="w-6 h-6 mr-2"/> :
                  <IoCheckboxOutline className="w-6 h-6 mr-2"/>}
              <Field
                  type="checkbox"
                  name="privacyPolicy"
                  checked={formik.values.privacyPolicy}
                  onChange={() => formik.setFieldValue('privacyPolicy', !formik.values.privacyPolicy)}
                  className="hidden"
              />
              <span className="flex-grow">[필수] 개인정보 수집 및 이용 동의</span>
            </div>
            <button type="button" className="underline text-gray400 text-sm w-14"
                    onClick={() => handleShowModal(<PrivacyPolicy/>, 'privacyPolicy', '개인정보 수집 이용 동의')}>
              내용보기
            </button>
          </div>
          {formik.touched.allAgree && formik.errors.allAgree ? (
              <Message>{formik.errors.allAgree}</Message>
          ) : <Message></Message>}
        </div>
        <div className="mt-5 flex space-x-2">
          <Button
              type="button"
              onClick={handleDismiss}
              btnType="secondary"
              disabled={inProgress}
          >
            비동의
          </Button>
          <Button  type="submit" disabled={formik.isSubmitting}>
            동의
          </Button>
        </div>
            {showModal && modalContent && (
                <TermsModal show={showModal} onClose={handleCloseModal} content={modalContent} type={termsType} title={modalTitle}/>
            )}
      </Form>
    </FormikProvider>
        <SamhwaWrapper>
          <SamhwaBrandLogo/>
        </SamhwaWrapper>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 110px 20px 55px;
  height: 100%;
  max-height: 100%;
`;

const SamhwaWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-top: 50px;

  img {
    width: 64px;
    height: 21px;
    display: block;
  }
`;

const Message = styled.p`
  color: #e20d3a;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  min-height: 18px;
`;