import { logger } from './logger';

const REFRESH_TOKEN_NAME = 'rtk';

function get() {
  let token: string | null = localStorage.getItem(REFRESH_TOKEN_NAME);
  if (token === '') {
    token = null;
  }
  //logger('get refresh token,', token);
  return token;
}
function set(token: string) {
  localStorage.setItem(REFRESH_TOKEN_NAME, token);
  //logger('set refresh token');
}
function remove() {
  localStorage.setItem(REFRESH_TOKEN_NAME, '');
  //logger('remove refresh token');
}

export const refreshTokenStorage = { get, set, remove };
