import * as yup from 'yup';

export const newPasswordValidate = yup
  .string()
    .matches(
        /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=`|\\{}[\]:;"'<>,.?/-]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=`|\\{}[\]:;"'<>,.?/-]{8,20}$/,
        '8~20자의 영문 대/소문자, 숫자, 특수문자 중 2개 이상 조합으로 입력해 주세요.'
    )
    .test(
        'no-username-in-password',
        '신규 비밀번호에 아이디를 포함할 수 없습니다.',
        function (value, context) {
            const username = context.parent.username;
            if (!username) return true;
            if (!value) return true;
            return !value.toLowerCase().includes(username.toLowerCase());
        }
    )
      .required('신규 비밀번호를 입력해 주세요.');
