import { FC } from 'react';
import { TBrochureListItem } from '../types/brochure-list-item.type';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { formatTimeAgo } from '../utils/formatTimeAgo';

interface BrochureListItemProps extends TBrochureListItem {
  page?: number;
  fromScreen?: string;
  vType?:string;
  vCategory?:string;
}

export const BrochureListItem: FC<BrochureListItemProps> = ({
  _id,
  category,
  image,
  name,
  type,
  date,
  created_at,
  page = 1,
  fromScreen,
  vCategory,
  vType,
}) => {
  return (
    <Container to={`/brochure/${_id}`} state={{page, fromScreen, vCategory, vType}}>
      <Cover data-src={image.url}></Cover>
      <ContentContainer>
        <div className="text-sm font-medium flex space-x-2.5 mb-2">
          <span className="block px-2 py-0.5 bg-red500 text-white rounded-px5">
            {type}
          </span>
          <span className="block px-2 py-0.5 bg-red100 text-red500 rounded-px5">
            {category}
          </span>
        </div>
        <h2 className="font-semibold text-lg text-gray800 line-clamp-2">
          {name}
        </h2>
        <p className="text-sm font-medium text-gray300">
          {date ? date : formatTimeAgo(created_at)}
        </p>
      </ContentContainer>
    </Container>
  );
};

const Container = styled(Link)`
  display: flex;
  text-decoration: none;
  color: inherit;
`;

const Cover = styled.div<{ 'data-src': string }>`
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid var(--grayscale-100);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${(item) =>
    item['data-src'] ? `background-image: url('${item['data-src']}');` : ''}
`;
const ContentContainer = styled.div`
  margin-left: 8px;
  flex: 1;
  overflow: hidden;
`;
