import React, {FC, useState} from 'react';
import Agreement from "./components/Agreement";
import PhoneVerification from "./components/PhoneVerification";
import SignupForm from "./components/SignupForm";
import {Layout} from "../../components/Layout";
import { useNavigate } from 'react-router-dom';
import {BackBtnIcon} from "@assets/components/back-btn-icon";
import styled from "styled-components";
import {useModalContext} from "../../modal/context";

interface RegisterScreenProps {}

const RegisterScreen: FC<RegisterScreenProps> = () => {
    const [step, setStep] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [termsAgreeAt, setTermsAgreeAt] = useState('');
    const [termsOfService, setTermsOfService] = useState<boolean>();
    const [privacyPolicy, setPrivacyPolicy] = useState<boolean>();
    const modalDispatch = useModalContext();
    const nav = useNavigate();

    const handleBack = () => {
        modalDispatch.openModal(
            { type: 'alert', title: '지금 나가면 회원가입이 취소됩니다.\n다음에 가입하시겠습니까?', text: '' },
            {
                buttonText2: '계속하기',
                buttonText: '나가기',
                onClose: () => {
                    nav(-1);
                },
                buttonType: '2',
            }
        );
        // if (step === 0) {
        //     // 이용약관 동의 페이지에서 뒤로가기: 로그인 페이지로 이동
        //     nav(-1);
        // }else{
        //     setStep((prevStep) => Math.max(prevStep - 1, 0));
        // }
    };
  const handleNext =  (phone_number?: string, terms_agree_at?: string, terms_of_service?:boolean, privacy_policy?:boolean, )  => {
      if (phone_number) {
          setPhoneNumber(phone_number);
      }
      if(terms_agree_at){
          setTermsAgreeAt(terms_agree_at);
      }

      if(terms_of_service){
          setTermsOfService(terms_of_service);
      }

      if(privacy_policy){
          setPrivacyPolicy(privacy_policy);
      }
      setStep((prevStep) => prevStep + 1);
  }

  return (
      <Layout hideFooter renderHeader={<HeaderContainer>
          <div className="left flex items-center">
              <BackArea type="button" onClick={handleBack}>
                  <BackBtnIcon/>
              </BackArea>
          </div>
          <div className="right"></div>
      </HeaderContainer>}>
          <Container className="relative">
              <div>
                  {step === 0 && <Agreement onNext={handleNext} terms_of_service={termsOfService} privacy_policy={privacyPolicy} />}
                  {step === 1 && <PhoneVerification onNext={handleNext} phone_number={phoneNumber}/>}
                  {step === 2 && <SignupForm phone_number={phoneNumber} terms_agree_at={termsAgreeAt ?? undefined} />}
              </div>
          </Container>
      </Layout>
  );
};

export default RegisterScreen;

const Container = styled.div`
    padding: 58px 20px 55px;
`;
const BackArea = styled.button`
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  background-color: var(--grayscale-white);
  height: 52px;
  border-bottom: 1px solid var(--grayscale-100);
  padding: 12px 20px;
  justify-content: space-between;
  .left,
  .right {
    display: flex;
    align-items: center;
  }
`;