import {useAuthData, useAuthDispatch} from 'auth';
import { Header } from 'components/Header';
import { Layout } from 'components/Layout';
import {FC, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Button} from "../../../components/Button";
import {useModalContext} from "../../../modal/context";
import {userApis} from "../../../apis/user.apis";
import {HttpError} from "../../../apis/http-error";

interface UserInfoScreenProps {}

export const UserInfoScreen: FC<UserInfoScreenProps> = () => {
  const { logout } = useAuthDispatch();
  const navigate = useNavigate();
  const authData = useAuthData();
  const modalDispatch = useModalContext();
  const [inProgress, setInProgress] = useState(false);

  const removeUser = () => {
    setInProgress(true);
    userApis
        .removeUser()
        .then(() => {
          modalDispatch.openModal(
              {
                type: 'alert',
                title: '탈퇴 완료되었습니다.\n이용해 주셔서 감사합니다.',
                text: '',
              },
              {
                onClose: () => {logout();navigate('/', { replace: true });},
                closeTypes: 'sideeffect'
              }
          );
        })
        .catch((error: HttpError) => {
          if (error.code === 'USER_NOT_FOUND') {
            logout();
            navigate('/', { replace: true });
          } else {
            setInProgress(false);
            modalDispatch.openModal({
              type: 'alert',
              title: '오류',
              text: error.message,
            });
          }
        })
        .finally(() => {
        });
  };

  const handleWithdrawal = () =>{
    modalDispatch.openModal(
        { type: 'alert', title: '탈퇴 후에는 계정 정보를\n복구할 수 없습니다.\n정말로 계정을 삭제하시겠습니까?', text: '' },
        {
          buttonText: '삭제',
          onClose: () => {
            removeUser();
          },
          buttonType: '2',
          inProgress: inProgress,
        }
    );
  }
  return (
    <Layout renderHeader={<Header hasBack title="계정 정보" />} hideFooter>
        <div className="py-3 px-5">
            <div className="space-y-6">
                <div>
                    <label className="block text-lg font-semibold text-gray800 mb-px7">
                        아이디
                    </label>
                    <input
                        className="input"
                        placeholder=""
                        value={authData?.user?.username ?? ''}
                        disabled
                    />
                </div>
                <div>
                    <label className="block text-lg font-semibold text-gray800 mb-px7">
                        이름
                    </label>
                    <input
                        className="input"
                        placeholder=""
                        value={authData?.user?.name ?? ''}
                        disabled
                    />
                </div>
                <div>
                    <label className="block text-lg font-semibold text-gray800 mb-px7">
                        휴대전화 번호
                    </label>
                    <input
                        className="input"
                        placeholder=""
                        value={authData?.user?.phone_number ?? ''}
                        disabled
                    />
                </div>
                <div>
                    <label className="block text-lg font-semibold text-gray800 mb-px7">
                        이메일
                    </label>
                    <input
                        className="input"
                        placeholder=""
                        value={authData?.user?.email ?? ''}
                        disabled
                    />
                </div>
            </div>
            <Link
                to="/user/setting/info/password"
                className="block bg-black rounded-px5 text-lg font-semibold text-white text-center mt-4"
                style={{padding: 15}}
            >
                비밀번호 변경하기
            </Link>
            <div className="flex space-x-3 text-center justify-center underline mt-8">
                <Link to="/user/setting/delete-account"
                      className="block text-md text-gray400 font-medium"
                >
                    회원탈퇴
                </Link>
            </div>
            </div>
    </Layout>
);
};
