import { FC } from 'react';

interface LoginIconProps {}

export const LoginIcon: FC<LoginIconProps> = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 25V22H18V23H20.5H23V9H21.25H18V10H16V7H23C23.55 7 24.021 7.19567 24.413 7.587C24.8043 7.979 25 8.45 25 9V23C25 23.55 24.8043 24.021 24.413 24.413C24.021 24.8043 23.55 25 23 25H16ZM14 21L12.625 19.55L15.175 17H7V15H15.175L12.625 12.45L14 11L19 16L14 21Z"
        fill="#202020"
      />
    </svg>
  );
};
