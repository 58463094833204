import { FC } from 'react';


interface TermsOfServiceProps {
  fromScreen?: string
}

export const TermsOfService: FC<TermsOfServiceProps> = ({fromScreen}) => {
  return (
      <>
              <strong>‘페인트프로’ 서비스 이용약관</strong>
              <br/>
              <br/>
              <br/>
              ‘페인트프로’ 관련 제반 서비스의 이용과 관련하여 필요한 사항을
              규정합니다. ‘페인트프로’ 회원약관은 다음과 같은 내용을 담고 있습니다.
              <br/>
              <br/>
              <br/>
              <strong>제 1 조 (목적)</strong>
              <br/>
              <br/>
              <br/>
              이 약관은 삼화페인트공업㈜ (이하 “회사”라 합니다)가 제공하는
              페인트프로(이하 “서비스”라 합니다)의 이용과 관련하여 “회사”와 “회원”의
              권리, 의무, 책임, 이용조건 및 절차 등 기본적인 사항과 기타 필요한
              사항을 규정함을 목적으로 합니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 2 조 (정의)</strong> <br/>
              <br/>
              <br/>
              ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
              <br/>
              1. “서비스”라 함은 구현되는 단말기(휴대형단말기 등의 유무선 장치를
              포함)와 상관없이 “회원”이 이용할 수 있는 페인트프로와 관련 제반
              서비스를 말합니다.
              <br/>
              2. “회원”이라 함은 “회사”의 “서비스”에 접속하여 이 약관에 따라
              “회사”와 “이용계약”을 체결하고 “회사”가 제공하는 “서비스”를 이용하는
              고객을 말하며, “일반회원” 으로 구분됩니다.
              <br/>
              3. “이용계약”이라 함은 이 약관을 포함한 “서비스” 이용과 관련하여
              “회사”와 “회원”간에 체결하는 모든 계약을 말합니다.
              <br/>
              4. “아이디(ID)”라 함은 “회원”의 식별과 “서비스” 이용을 위하여 “회원”이
              설정하고 “회사”가 승인하는 문자와 숫자의 조합을 말합니다.
              <br/>
              5. “비밀번호”라 함은 “회원”이 부여 받은 “아이디”와 일치하는 “회원”임을
              확인하고 비밀보호를 위해 “회원” 자신이 정한 문자 기호 또는 숫자의
              조합을 말합니다.
              <br/>
              6. “해지”라 함은 “회사” 또는 “회원”이 “이용계약”을 해약하는 것을
              말합니다.
              <br/>
              7. “이용자”라 함은 “회사”에서 제공하는 “서비스” 또는 관련 제반
              서비스를 이용하는 “회원”과 비회원을 말합니다.
              <br/>
              8. “게시물”이라 함은 “회원”이 “서비스”를 이용함에 있어 “서비스”상에
              게시한 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 말합니다.
              <br/>
              <br/>
              <br/>
              ② 이 약관에서 사용하는 용어 중 제2조에서 정하지 아니한 것은 관계 법령
              및 별도의 이용약관 및 정책(이하 “서비스 별 안내 등”이라 합니다)에서
              정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 3 조 (약관의 효력과 변경)</strong>
              <br/>
              <br/>
              <br/>
              ① 본 약관은 사이트에 게시하며 이용자가 회원으로 가입함으로써 효력이
              발생합니다.
              <br/>
              <br/>
              <br/>
              ② 회사는 합리적인 사유가 발생한 경우에는 본 약관을 변경할 수 있으며,
              약관을 변경한 경우에는 변경내용을 최소 7일 이전에 이메일 또는 사이트의
              게시 등을 통하여 공지합니다. 단, 개정 내용이 회원의 권리와 의무에
              중대한 영향을 주는 경우에는 적용일자 30일 전부터 적용일 이후 상당한
              기간 동안 공지하도록 합니다.
              <br/>
              <br/>
              <br/>
              ③ 회원은 변경된 약관사항에 동의하지 않으면 <br/>
              서비스 이용을 중단하고, 이용계약을 해지할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ④ 약관의 효력발생일 이후의 계속적인 서비스 이용은 약관의 변경사항에
              회원이 동의한 것으로 간주합니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 4 조 (약관의 해석)</strong>
              <br/>
              <br/>
              <br/>
              ① “회사”는 개별 서비스에 대해서는 “서비스 별 안내 등”을 둘 수 있으며,
              해당 내용이 이 약관과 상충할 경우에는 “서비스 별 안내 등”이 우선하여
              적용됩니다.
              <br/>
              <br/>
              <br/>
              ② 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 관계 법령 또는
              상관례에 따릅니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 5 조 (이용계약 체결)</strong>
              <br/>
              <br/>
              <br/>
              ① “이용계약”은 “회원”이 되고자 하는 자(이하 “가입신청자”)가 약관의
              내용에 대하여 동의를 한 다음 회원가입신청을 하고 “회사”가 이러한
              신청에 대하여 승낙함으로써 체결됩니다.
              <br/>
              <br/>
              <br/>
              ② “회사”는 “가입신청자”의 신청에 대하여 “서비스” 이용을 승낙함을
              원칙으로 합니다. 다만, “회사”는 다음 각 호에 해당하는 신청에 대하여는
              승낙을 하지 않거나 사후에 “이용계약”을 해지할 수 있습니다.
              <br/>
              1. “가입신청자”가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는
              경우, 단 “회사”의 회원 재가입 승낙을 얻은 경우에는 예외로 함.
              <br/>
              2. 실명이 아니거나 타인의 명의를 이용한 경우
              <br/>
              3. 허위의 정보를 기재하거나, “회사”가 제시하는 내용을 기재하지 않은
              경우
              <br/>
              4. “이용자”의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
              사항을 위반하며 신청하는 경우
              <br/>
              <br/>
              <br/>
              ③ 제1항에 따른 신청에 있어 “회사”는 “회원”의 종류에 따라 전문기관을
              통한 실명확인 및 본인인증을 요청할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ④ “회사”는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가
              있는 경우에는 승낙을 유보할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ⑤ 제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한
              경우, “회사”는 원칙적으로 이를 “가입신청자”에게 알리도록 합니다.
              <br/>
              <br/>
              <br/>
              ⑥ “이용계약”의 성립 시기는 “회사”가 가입완료를 신청절차 상에서 표시한
              시점으로 합니다.
              <br/>
              <br/>
              <br/>
              ⑦ “회사”는 “회원”에 대해 회사정책에 따라 등급별로 구분하여 이용시간,
              이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
              <br/>
              <br/>
              <br/>
              ⑧ “회사”는 “회원”에 대하여 “영화 및 비디오물의 진흥에 관한 법률” 및
              “청소년보호법” 등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별
              제한을 할 수 있습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 6 조 (회원정보의 변경)</strong>
              <br/>
              <br/>
              <br/>
              ① “회원”은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를
              열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명,
              아이디 등은 수정이 불가능합니다.
              <br/>
              <br/>
              <br/>
              ② “회원”은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로
              수정을 하거나 전자우편 기타 방법으로 “회사”에 대하여 그 변경사항을
              알려야 합니다.
              <br/>
              <br/>
              <br/>
              ③ 제2항의 변경사항을 “회사”에 알리지 않아 발생한 불이익에 대하여
              “회사”는 책임지지 않습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 7 조 (개인정보보호 의무)</strong>
              <br/>
              <br/>
              <br/>
              “회사”는 “정보통신망법” 등 관계 법령이 정하는 바에 따라 “회원”의
              개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에
              대해서는 관련법 및 “회사”의 개인정보처리방침이 적용됩니다. 다만,
              “회사”의 공식 사이트 이외의 링크된 사이트에서는 “회사”의
              개인정보처리방침이 적용되지 않습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>
                      제 8 조 (“회원”의 “아이디” 및 “비밀번호”의 관리에 대한 의무)
              </strong>
              <br/>
              <br/>
              <br/>
              ① “회원”의 “아이디”와 “비밀번호”에 관한 관리책임은 “회원”에게 있으며,
              이를 제3자가 이용하도록 하여서는 안 됩니다.
              <br/>
              <br/>
              <br/>
              ② “아이디”는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여
              변경하고자 하는 경우에는 해당 “아이디”를 해지하고 재가입해야 합니다.
              <br/>
              <br/>
              <br/>
              ③ “회사”는 “회원”의 “아이디”가 개인정보 유출 우려가 있거나, 반사회적
              또는 미풍양속에 어긋나거나 “회사” 및 “회사”의 운영자로 오인할 우려가
              있는 경우, 해당 “아이디”의 이용을 제한할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ④ “회원”은 “아이디” 및 “비밀번호”가 도용되거나 제3자가 사용하고 있음을
              인지한 경우에는 이를 즉시 “회사”에 통지하고 “회사”의 안내에 따라야
              합니다.
              <br/>
              <br/>
              <br/>
              ⑤ 제4항의 경우에 해당 “회원”이 “회사”에 그 사실을 통지하지 않거나,
              통지한 경우에도 “회사”의 안내에 따르지 않아 발생한 불이익에 대하여
              “회사”는 책임지지 않습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 9 조 (“회원”에 대한 통지)</strong>
              <br/>
              <br/>
              <br/>
              ① “회사”가 “회원”에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는
              한 “서비스” 내 전자우편, 전자쪽지 등으로 할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ② “회사”는 “회원” 전체에 대한 통지의 경우 7일 이상 “회사”의 게시판에
              게시함으로써 제1항의 통지에 갈음할 수 있습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 10 조 (“회사”의 의무)</strong>
              <br/>
              <br/>
              <br/>
              ① “회사”는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지
              않으며, 계속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을 다하여
              노력합니다.
              <br/>
              <br/>
              <br/>
              ② “회사”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록
              개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며
              개인정보처리방침을 공시하고 준수합니다.
              <br/>
              <br/>
              <br/>
              ③ “회사”는 “서비스” 이용과 관련하여 발생하는 “이용자”의 불만 또는
              피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을
              구비합니다.
              <br/>
              <br/>
              <br/>
              ④ “회사”는 “서비스” 이용과 관련하여 “회원”으로부터 제기된 의견이나
              불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. “회원”이
              제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을
              통하여 “회원”에게 처리과정 및 결과를 전달합니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 11 조 (“회원”의 의무)</strong>
              <br/>
              <br/>
              <br/>
              ① “회원”은 다음 행위를 하여서는 안 됩니다.
              <br/>
              1. 신청 또는 변경 시 허위내용의 등록
              <br/>
              2. 타인의 정보도용
              <br/>
              3. “회사”가 게시한 정보의 변경
              <br/>
              4. “회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는
              게시
              <br/>
              5. “회사”와 기타 제3자의 저작권 등 지적재산권에 대한 침해
              <br/>
              6. “회사” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
              <br/>
              7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
              정보를 “서비스”에 공개 또는 게시하는 행위
              <br/>
              8. 회사의 동의 없이 영리를 목적으로 “서비스”를 사용하는 행위
              <br/>
              9. 기타 불법적이거나 부당한 행위
              <br/>
              <br/>
              <br/>
              ② “회원”은 관계법, 이 약관의 규정, 이용안내 및 “서비스”와 관련하여
              공지한 주의사항, “회사”가 통지하는 사항 등을 준수하여야 하며, 기타
              “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 12 조 (“서비스”의 제공 등)</strong>
              <br/>
              <br/>
              <br/>
              ① “회사”는 “회원”에게 아래와 같은 서비스를 제공합니다.
              <br/>
              제품 자료 검색 및 다운로드 서비스 제공
              <br/>
              시공사례 정보 서비스 제공
              <br/>
              제품 홍보영상 서비스 제공
              <br/>
              제품 소식지 뉴스레터 서비스 제공
              <br/>
              FAQ 서비스 제공
              <br/>
              기타 “회사”가 추가 개발하고 “회원”에게 제공하는 일체의 서비스
              <br/>
              <br/>
              <br/>
              ② “회사”는 “서비스”를 일정범위로 분할하여 각 범위 별로 이용가능시간을
              별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에
              공지합니다.
              <br/>
              <br/>
              <br/>
              ③ “서비스”는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
              <br/>
              <br/>
              <br/>
              ④ “회사”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절
              또는 운영상 상당한 이유가 있는 경우 “서비스”의 제공을 일시적으로
              중단할 수 있습니다. 이 경우 “회사”는 제9조(“회원”에 대한 통지)에 정한
              방법으로 “회원”에게 통지합니다. 다만, “회사”가 사전에 통지할 수 없는
              부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ⑤ “회사”는 “서비스”의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
              정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 13 조 (“서비스”의 변경)</strong>
              <br/>
              <br/>
              <br/>
              ① “회사”는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
              제공하고 있는 전부 또는 일부 “서비스”를 변경할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ② “서비스”의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는
              변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당
              서비스 초기화면에 게시하여야 합니다.
              <br/>
              <br/>
              <br/>
              ③ “회사”는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및
              운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에
              특별한 규정이 없는 한 “회원”에게 별도의 보상을 하지 않습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 14 조 (정보의 제공 및 광고의 게재)</strong>
              <br/>
              <br/>
              <br/>
              ① “회사”는 “회원”이 “서비스” 이용 중 필요하다고 인정되는 다양한 정보를
              공지사항이나 전자우편 등의 방법으로 “회원”에게 제공할 수 있습니다.
              다만, “회원”은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변
              등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ② “회사”는 “서비스”의 운영과 관련하여 서비스 화면, 홈페이지,
              어플리케이션, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된
              전자우편을 수신한 “회원”은 수신거절을 “회사”에게 할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ③ “이용자”는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를
              변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 15 조 (권리의 귀속)</strong>
              <br/>
              <br/>
              <br/>
              ① “서비스”에 대한 저작권 및 지적재산권은 “회사”에 귀속됩니다. 단,
              “회원”의 “게시물” 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.
              <br/>
              <br/>
              <br/>
              ② “회사”는 “서비스”와 관련하여 “회원”에게 “회사”가 정한 이용조건에
              따라 계정, “아이디”, 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며,
              “회원”은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 16 조 (계약해제, 해지 등)</strong>
              <br/>
              <br/>
              <br/>
              ① “회원”은 언제든지 홈페이지의 고객센터 또는 이메일 등을 통하여
              “이용계약” 해지 신청을 할 수 있으며, “회사”는 관련법 등이 정하는 바에
              따라 이를 처리하여야 합니다.
              <br/>
              <br/>
              <br/>
              ② “회원”이 “이용계약”을 해지할 경우, 관련법 및 개인정보처리방침에 따라
              “회사”가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 “회원”의
              개인정보는 소멸됩니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 17 조 (책임과 손해배상)</strong>
              <br/>
              <br/>
              <br/>
              ① “회원”은 회원 자신의 책임 하에 본 서비스를 이용해야 하며, 본
              서비스에서 이루어진 모든 행위 및 그 결과에 대해서 일체의 책임을 져야
              합니다.
              <br/>
              <br/>
              <br/>
              ② “회사”는 “회원”이 본 약관에 위반되는 형태로 본 서비스를 이용하고
              있다고 인정되는 경우, 필요하고 적절하다고 판단한 조치를 취할 수
              있습니다. 단, “회사”는 이러한 위반 행위를 방지 또는 시정할 의무를
              부담하지 않습니다.
              <br/>
              <br/>
              <br/>
              ③ 본 서비스 이용에 기인하여(회사가 이러한 이용으로 인해 클레임을
              제3자로부터 받은 경우를 포함) “회사”가 직접 혹은 간접적으로 어떤
              손해(변호사 비용 부담을 포함)를 입었을 경우, “회원”은 “회사”의 요구에
              따라 즉시 이를 보상해야 합니다.
              <br/>
              <br/>
              <br/>
              ④ “회사”는 무료로 제공하는 서비스의 이용과 관련하여
              개인정보보호정책에서 정하는 내용에 위반하지 않는 한 책임을 지지
              않습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 18 조 (이용제한 등)</strong>
              <br/>
              <br/>
              <br/>
              ① “회사”는 “회원”이 이 약관의 의무를 위반하거나 “서비스”의 정상적인
              운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 “서비스”
              이용을 단계적으로 제한할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ② “회사”는 전항에도 불구하고, “주민등록법”을 위반한 명의도용 및
              결제도용, “저작권법” 및 “컴퓨터프로그램보호법”을 위반한 불법프로그램의
              제공 및 운영방해, “정보통신망법”을 위반한 불법통신 및 해킹,
              악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한
              경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지
              시 “서비스” 이용을 통해 획득한 혜택 등도 모두 소멸되며, “회사”는 이에
              대해 별도로 보상하지 않습니다.
              <br/>
              <br/>
              <br/>
              ③ “회사”는 “회원”이 계속해서 3개월 이상 로그인하지 않는 경우,
              회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.
              <br/>
              <br/>
              <br/>
              ④ 본 조의 이용제한 범위 내에서 이용제한의 조건 및 세부내용은
              이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 의합니다.
              <br/>
              <br/>
              <br/>
              ⑤ 본 조에 따라 “서비스” 이용을 제한하거나 “이용계약”을 해지하는
              경우에는 “회사”는 제9조(“회원”에 대한 통지)에 따라 통지합니다.
              <br/>
              <br/>
              <br/>
              ⑥ “회원”은 본 조에 따른 이용제한 등에 대해 “회사”가 정한 절차에 따라
              이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 “회사”가 인정하는
              경우 “회사”는 즉시 “서비스”의 이용을 재개합니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 19 조 (책임제한)</strong>
              <br/>
              <br/>
              <br/>
              ① “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를
              제공할 수 없는 경우에는 “서비스” 제공에 관한 책임이 면제됩니다.
              <br/>
              <br/>
              <br/>
              ② “회사”는 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에 대하여는
              책임을 지지 않습니다.
              <br/>
              <br/>
              <br/>
              ③ “회사”는 “회원”이 “서비스”와 관련하여 게재한 정보, 자료, 사실의
              신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
              <br/>
              <br/>
              <br/>
              ④ “회사”는 “회원” 간 또는 “회원”과 제3자 상호간에 “서비스”를 매개로
              하여 거래 등을 한 경우에는 책임이 면제됩니다.
              <br/>
              <br/>
              <br/>
              ⑤ “회사”는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한
              규정이 없는 한 책임을 지지 않습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 20 조 (회사의 면책)</strong>
              <br/>
              <br/>
              <br/>
              ① “회사”는 본 서비스(본 콘텐츠를 포함)에 사실상 또는 법률상의
              하자(안전성, 신뢰성, 정확성, 완전성, 유효성, 특정 목적과 부합 여부,
              보안 등에 관련된 결함, 오류와 버그, 권리 침해 등을 포함)가 없음을
              명시적으로도 묵시적으로도 보증하지 않습니다. “회사”는 고객에 대해
              이러한 하자를 제거하고 본 서비스를 제공할 의무를 갖지 않습니다.
              <br/>
              <br/>
              <br/>
              ② “회사”는 본 서비스로 인하여 회원에게 발생한 모든 손해에 대해 일체의
              책임을 지지 않습니다. 단, 본 서비스에 관한 “회사”와 “회원” 간의
              계약(본 약관을 포함)이 소비자계약법에서 규정하는 소비자 계약에 해당될
              경우, 이 면책 규정은 적용되지 않습니다.
              <br/>
              <br/>
              <br/>
              ③ 위 제2항 단서에서 규정하는 경우라도 “회사”의 과실(중과실을 제외)에
              의한 채무 불이행 또는 불법 행위로 인해 고객에게 발생한 손해 중 특별한
              사정으로 발생한 손해(“회사” 또는 “회원”이 손해 발생을 예견했거나 또는,
              예견할 수 있었던 경우를 포함)에 대해 “회사”는 일체의 책임을 지지
              않습니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <strong>제 21 조 (준거법 및 재판관할)</strong>
              <br/>
              <br/>
              <br/>
              ① “회사”와 “회원” 간 제기된 소송은 대한민국법을 준거법으로 합니다.
              <br/>
              <br/>
              <br/>
              ② “회사”와 “회원”간 발생한 분쟁에 관한 소송은 제소 당시의 “회원”의
              주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로
              합니다. 단, 제소 당시 “회원”의 주소 또는 거소가 명확하지 아니한 경우의
              관할법원은 민사소송법에 따라 정합니다.
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              [부칙]
              <br/>
              <br/>
              <br/>이 약관은 <strong>2024년 5월 1일</strong>부터 적용됩니다.
              <br/>
              “회사”는 약관을 변경하는 경우에 “회원”이 그 변경 여부, 변경된 사항의
              시행시기와 변경된 내용을 언제든지 쉽게 알 수 있도록 지속적으로
              “서비스”를 통하여 공개합니다. 이 경우 변경된 내용은 변경 전과 후를
              비교하여 공개합니다. <br/>
              <br/>
              <br/>
              <br/>
              <br/>
      </>
  );
};

