import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Header } from './Header';
import { Body } from './Body';
import { WhiteFooter } from './WhiteFooter';

interface LayoutProps {
  children?: ReactNode;
  renderHeader?: ReactNode;
  hideFooter?: boolean;
  backgroundColor?: string;
  renderFooter?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({
  children,
  renderHeader = <Header />,
  hideFooter = false,
  backgroundColor = 'transparent',
  renderFooter = <WhiteFooter />,
}) => {
  return (
    <Container style={{ backgroundColor }}>
      {renderHeader}
      <Body>{children}</Body>
      {hideFooter === false ? renderFooter : null}
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
