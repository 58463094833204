import classNames from 'classnames';
import { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';

interface TabNavigatorProps {
  items: { label: string; value: string }[];
  value?: { label: string; value: string };
  onChange?: (next: { label: string; value: string }) => void;
}

export const TabNavigator: FC<TabNavigatorProps> = ({
  items,
  value,
  onChange,
}) => {
  const changeTabHandler =
    (next: {
      label: string;
      value: string;
    }): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      const target = e.target as HTMLButtonElement;
      const parent = target.parentElement;
      if (!onChange) {
        return;
      }
      onChange(next);
      if (parent) {
        parent.scroll({ left: target.offsetLeft - 20, behavior: 'smooth' });
      }
    };

  return (
    <TabWrapper>
      {items.map((v) => (
        <Tab
          className={classNames({ active: v.value === value?.value })}
          type="button"
          onClick={changeTabHandler(v)}
          key={v.value}
        >
          {v.label}
        </Tab>
      ))}
    </TabWrapper>
  );
};

const TabWrapper = styled.nav`
  background-color: var(--grayscale-white);
  height: 48px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 1;

  position: sticky;
  margin-top: -1px;
  top: 0;
  left: 0;
  right: 0;
`;
const Tab = styled.button`
  display: block;
  flex: 1;
  white-space: nowrap;
  position: relative;
  padding: 4px 12px 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  background-color: var(--grayscale-white);
  color: var(--grayscale-300);
  height: 100%;
  border-bottom: 0 solid var(--brand-red-800);

  &.active {
    padding: 4px 12px 1px;
    color: var(--grayscale-black);
    border-bottom: 3px solid var(--brand-red-800);
    font-weight: 600;
  }
  transition: all 0.2s;
`;
