import { useLayoutEffect } from 'react';
import { useAuthData, useAuthDispatch } from '../auth/hooks';
import { httpClient } from '../apis/http-client';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { authApis } from '../apis/auth.apis';

export function useHttpClientSetting() {
  const authData = useAuthData();
  const authDispatch = useAuthDispatch();
  useLayoutEffect(() => {
    if (!authData.user?.access_token) {
      return;
    }
    const accessToken = authData.user.access_token;

    const id = httpClient.interceptors.request.use((config) => {
      if (!config.headers) {
        config.headers = {} as AxiosRequestHeaders;
      }
      if (!config.headers.Authorization && accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });
    return () => {
      httpClient.interceptors.request.eject(id);
    };
  }, [authData.user?.access_token]);

  useLayoutEffect(() => {
    if (!authData.user?.access_token) {
      return;
    }
    const id = httpClient.interceptors.response.use(
      (config) => Promise.resolve(config),
      async (error) => {
        const config = (error?.config ?? {}) as AxiosRequestConfig & {
          _retry?: boolean;
        };

        const status = error.response?.status;
        const isAuthUrl = (config.url ?? '').startsWith('/auth');

        if (!isAuthUrl && status === 401 && !config._retry) {
          config._retry = true;
          const getAccessTokenResponse = await authApis.getAccessToken();
          if (getAccessTokenResponse === false) {
            authDispatch.logout();
            throw error;
          }
          authDispatch.update({
            access_token: getAccessTokenResponse.access_token,
          });
          if (!config.headers) {
            config.headers = {} as AxiosRequestHeaders;
          }
          config.headers.Authorization = `Bearer ${getAccessTokenResponse.access_token}`;
          try {
            const res = await httpClient(config);
            return res;
          } catch (e: any) {
            if (e.status === 401) {
              authDispatch.logout();
            }
            throw e;
          }
        }

        throw error;
      }
    );
    return () => {
      httpClient.interceptors.response.eject(id);
    };
  }, [authData.user?.access_token, authDispatch]);
}
