import axios from 'axios';

export class HttpError extends Error {
  code: string;
  status: number;
  constructor(status: number, code: string, message: string) {
    super(message);
    this.status = status;
    this.code = code;
  }
}

export function ErrorToHttpError(e: Error) {
  if (axios.isAxiosError(e)) {
    const data = e.response?.data;
    const status = e.response?.status ?? -1;
    if (typeof data?.type === 'string' && typeof data?.message === 'string') {
      return new HttpError(status, data.type, data.message);
    } else if (e.response?.status === 404) {
      return new HttpError(status, 'API_NOT_FOUND', 'API를 찾을 수 없습니다.');
    } else if (e.response?.status === 400) {
      return new HttpError(
        status,
        'API_INVALID_PARAMS',
        '올바른 파라미터를 입력해 주세요.'
      );
    } else if (e.response?.status === 500) {
      return new HttpError(
        status,
        'UNKNOWN_ERROR',
        '알려지지 않은 오류가 발생하였습니다.'
      );
    } else if (e.code === 'ECONNABORTED') {
      return new HttpError(
        status,
        'ERR_NETWORK_CONNECT',
        '네트워크 연결에 실패하였습니다.'
      );
    } else {
      return new HttpError(
        status,
        'UNFORMATTED_ERROR',
        '알 수 없는 오류가 발생하였습니다.'
      );
    }
  } else {
    return new HttpError(-1, 'NET_ERROR', '네트워크 오류가 발생하였습니다.');
  }
}
