export function formatTimeAgo(inputDate: Date): string {
  // const now = new Date();
  // const diffInMilliseconds = now.getTime() - inputDate.getTime();
  // const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  // if (diffInDays < 1) {
  //   return '방금 전';
  // } else if (diffInDays <= 7) {
  //   return `${diffInDays}일 전`;
  // } else {
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  return `${year}.${month}.${day}`;
  // }
}
