import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NotificationBadgeIconSvg, NotificationIconSvg } from '../../../assets';
import { useQuery } from '@tanstack/react-query';
import { notificationApis } from '../../../apis/notification.apis';

interface NotificationRightButtonProps {}

export const NotificationRightButton: FC<NotificationRightButtonProps> = () => {
  const query = useQuery({
    queryKey: ['notification', 'recently'],
    queryFn: notificationApis.getRecentlyId,
  });

  return (
    <Container to="/notification">
      <NotificationIconWrapper>
        <NotificationIconSvg />
      </NotificationIconWrapper>
      {query.data?.badge ? (
        <NotificationIconBadgeWrapper>
          <NotificationBadgeIconSvg />
        </NotificationIconBadgeWrapper>
      ) : null}
    </Container>
  );
};
const NotificationIconWrapper = styled.div`
  width: 32px;
  height: 32px;
`;

const Container = styled(Link)`
  width: 32px;
  height: 32px;
  display: block;
  position: relative;
`;
const NotificationIconBadgeWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
