import { CSSProperties, FC } from 'react';

interface CatalogIconProps {
  fill?: string;
  style?: CSSProperties;
  className?: string;
}

export const CatalogIcon: FC<CatalogIconProps> = ({
  fill = '#202020',
  style,
  className,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M2.81147 6.37598H2.05396C1.4719 6.37598 1 6.83288 1 7.39645V20.1606C1 20.6865 1.41281 21.1263 1.95346 21.1764L3.5262 21.3224C3.08584 20.9279 2.81146 20.3815 2.81146 19.785L2.81147 6.37598Z"
        fill={fill}
      />
      <path
        d="M21.946 6.37598H21.1888V19.785C21.1888 20.3815 20.9145 20.9278 20.4741 21.3223L22.0465 21.1764C22.5872 21.1263 22.9999 20.6865 22.9999 20.1606V7.39645C22.9999 6.83288 22.5281 6.37598 21.946 6.37598Z"
        fill={fill}
      />
      <path
        d="M19.6022 5.24929C19.3984 5.09917 19.145 5 18.8653 5C18.785 5 18.7034 5.00736 18.6211 5.0227L17.4064 5.24929L14.3863 5.81272L12.582 6.14935V22L19.1046 20.7832C19.6416 20.683 20.0256 20.2669 20.0256 19.785V6.0209C20.0256 5.94649 19.9942 5.88212 19.9772 5.81274C19.9217 5.58573 19.7949 5.39123 19.6022 5.24929Z"
        fill={fill}
      />
      <path
        d="M11.4178 6.2347V6.14915L9.6144 5.81272L6.59421 5.24929L5.37958 5.0227C5.29733 5.00736 5.21558 5 5.13539 5C4.85561 5 4.60226 5.09917 4.39849 5.24929C4.20572 5.39123 4.07894 5.58572 4.02346 5.81272C4.00649 5.88211 3.9751 5.94648 3.9751 6.02089V19.785C3.9751 20.2669 4.35914 20.683 4.8961 20.7832L11.4178 21.9999V6.2347Z"
        fill={fill}
      />
    </svg>
  );
};
