import React, {FC, useCallback, useMemo, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Layout} from "../../components/Layout";
import {Header} from "../../components/Header";
import {FindUsername} from "./components/FindUsername";
import {FindPassword} from "./components/FindPassword";
import {TabNavigator} from "../../components/TabNavigator";
import FindUserComplete from "./components/FindUsernameComplete";
import FindPasswordEdit from "./components/FindPasswordEdit";

type ListItem = {
  label: string;
  value: string;
};
const CATEGORY: ListItem[] = [
  { label: '아이디 찾기', value: 'username' },
  { label: '비밀번호 찾기', value: 'password' },
];

interface FindUserScreenProps {}

const FindUserScreen: FC<FindUserScreenProps> = () => {
    const [step, setStep] = useState(0);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [verifyId, setVerifyId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const param = useLocation();
    const navigate = useNavigate();
    const category: ListItem = useMemo(() => {
        return param?.state?.category ?? CATEGORY[0];
        }, [param?.state?.category]);
    const setCategory = useCallback(
      (next: ListItem) => {
          handleReset();
          navigate(param.pathname, {
              state: { category: next, page: 1 },
              replace: true,
          });

      },
      [navigate, param.pathname]
    );

    const handleUsernameNext=(name: string, username: string ) =>{
        if(name){
            setName(name);
        }
        if(username){
            setUsername(username);
        }
        setStep(prevState => prevState+1);
    }

    const handlePasswordNext=(verifyId:string, phoneNumber:string, username: string ) =>{
        if(verifyId){
            setVerifyId(verifyId);
        }
        if(phoneNumber){
            setPhoneNumber(phoneNumber)
        }
        if(username){
            setUsername(username);
        }
        setStep(prevState => prevState+1);
    }

    const handleReset=() =>{
        setName('');
        setUsername('');
        setStep(0);
    }

  return (
      <Layout hideFooter renderHeader={<Header hasBack />}>
        <div>
          <div className="sticky top-0 z-10">
              <TabNavigator  value={category}
                             items={CATEGORY}
                             onChange={setCategory} />
          </div>
          <div className="min-h-full">
              {category.value === 'username' && (
                  <>
                      {step === 0 && <FindUsername onNext={handleUsernameNext} />}
                      {step === 1 && <FindUserComplete onReset={handleReset} name={name} username={username}  />}
                  </>
              )}
            {category.value === 'password' && (
                <>
                    {step === 0 && <FindPassword onNext={handlePasswordNext}/> }
                    {step === 1 && <FindPasswordEdit id={verifyId} phone_number={phoneNumber} username={username}/> }
                </>
            )}
          </div>
        </div>
      </Layout>
  );
};

export default FindUserScreen;

