import { FC } from 'react';
import styled from "styled-components";
import {PiCaretRightThin} from "react-icons/pi";

interface RightPaginationIconProps {}

export const RightPaginationIcon: FC<RightPaginationIconProps> = () => {
  return (
      <IconContainer>
        <PiCaretRightThin/>
      </IconContainer>
/*    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.3999 4.79999L15.5999 12L8.3999 19.2" stroke="#B5B5B7" />
    </svg>*/
  );
};

const IconContainer = styled.div`
    font-size: 25px;
`;