import { FC } from 'react';

interface NoticeMegaphoneIconProps {
  width?: string;
  height?: string;
  color?: string;
}

export const NoticeMegaphoneIcon: FC<NoticeMegaphoneIconProps> = ({
  height = '24',
  width = '24',
  color = '#E20D3a',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_337_7501)">
        <path
          d="M2 9C2 7.89543 2.89543 7 4 7H8V15H4C2.89543 15 2 14.1046 2 13V9Z"
          fill={color}
        />
        <path
          d="M9 7L16.1056 3.44722C17.4354 2.78231 19 3.7493 19 5.23607V16.7639C19 18.2507 17.4354 19.2177 16.1056 18.5528L9 15V7Z"
          fill={color}
        />
        <path
          d="M4 15H8L9.29961 19.5486C9.65061 20.7771 8.72819 22 7.45056 22C6.59195 22 5.83738 21.4308 5.6015 20.6053L4 15Z"
          fill={color}
        />
        <path
          d="M20 14V8C22.4721 9.23607 22.4721 12.7639 20 14Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_337_7501">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
