import { FC, useMemo } from 'react';
import { TNotificationListItem } from '../types/notification-list-item.type';
import { formatTimeAgo } from '../utils/formatTimeAgo';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { notificationApis } from 'apis/notification.apis';
import { ColorSwatchIcon } from '@assets/components/colorswatch-icon';
import styled from 'styled-components';
import { NoticeMegaphoneIcon } from '@assets/components/notice-megaphone-icon';
import { SamhwaBrandLogo } from '@assets/components/samhwa-brand-logo';
import { DataBookIcon } from '@assets/components/databook-icon';
import { CatalogIcon } from '@assets/components/catalog-icon';
import { feedApis } from 'apis/feed.apis';
import { linkingSupport } from 'utils/linkingSupport';
import { useModalContext } from 'modal/context';
import { newsApis } from 'apis/news.apis';
import { faqApis } from 'apis/faq.apis';

interface NotificationListItemProps extends TNotificationListItem {}

const NotificationIcon: FC<{ type: string; read?: boolean }> = ({
  type,
  read,
}) => {
  if (type === '뉴스' || type === 'FAQ') {
    return (
      <NoticeMegaphoneIcon
        width="24"
        height="24"
        color={read ? 'var(--grayscale-400)' : 'var(--brand-red-500)'}
      />
    );
  }

  if (type === '색견본') {
    return (
      <ColorSwatchIcon
        style={{ width: 'inherit', height: 'inherit' }}
        fill={read ? 'var(--grayscale-300)' : 'var(--grayscale-black)'}
      />
    );
  }
  if (type === '자료집') {
    return (
      <DataBookIcon
        style={{ width: 'inherit', height: 'inherit' }}
        fill={read ? 'var(--grayscale-300)' : 'var(--grayscale-black)'}
      />
    );
  }
  if (type === '카달로그') {
    return (
      <CatalogIcon
        style={{ width: 'inherit', height: 'inherit' }}
        fill={read ? 'var(--grayscale-300)' : 'var(--grayscale-black)'}
      />
    );
  }

  return (
    <IconWrapper>
      <SamhwaBrandLogo
        width="22"
        height="7"
        color={read ? 'var(--grayscale-300)' : 'var(--grayscale-black)'}
      />
    </IconWrapper>
  );
};

export const NotificationListItem: FC<NotificationListItemProps> = ({
  content,
  created_at,
  payload,
  type,
  read = false,
  _id,
}) => {
  const onClick = () => {
    if (!read) {
      notificationApis
        .updateRead(_id)
        .then(() => {})
        .catch(() => {});
    }
  };
  const path = useMemo(() => {
    const brochureTypes = ['none', '색견본', '자료집', '카달로그'];
    if (brochureTypes.includes(type)) {
      return `/brochure/${payload}`;
    }
    const externalUrlTypes = ['시공', '홍보'];
    if (externalUrlTypes.includes(type)) {
      return 'FeedLink';
    }
    if (type === '뉴스') {
      return 'NewsLink';
    }
    if (type === 'FAQ') {
      return 'FAQLink';
    }
    if (type === '공지사항') {
      return `/board/${payload}`;
    }
    return '';
  }, [payload, type]);
  const modalDispatch = useModalContext();
  const handleFeedLink = async () => {
    try {
      const res = await feedApis.getLink(payload);
      if (res.link) {
        linkingSupport(res.link);
      } else {
        modalDispatch.openModal({
          type: 'alert',
          title: '해당 게시물의 링크 정보가 존재하지 않습니다.',
          text: '',
        });
      }
    } catch {
      modalDispatch.openModal({
        type: 'alert',
        title: '해당 게시물을 불러올 수 없습니다.',
        text: '',
      });
    }
  };
  const handleNewsLink = async () => {
    try {
      const res = await newsApis.getLink(payload);
      if (res.link) {
        linkingSupport(res.link);
      } else {
        modalDispatch.openModal({
          type: 'alert',
          title: '해당 게시물의 링크 정보가 존재하지 않습니다.',
          text: '',
        });
      }
    } catch {
      modalDispatch.openModal({
        type: 'alert',
        title: '해당 게시물을 불러올 수 없습니다.',
        text: '',
      });
    }
  };
  const handleFaqLink = async () => {
    try {
      const res = await faqApis.getLink(payload);
      if (res.link) {
        linkingSupport(res.link);
      } else {
        modalDispatch.openModal({
          type: 'alert',
          title: '해당 게시물의 링크 정보가 존재하지 않습니다.',
          text: '',
        });
      }
    } catch {
      modalDispatch.openModal({
        type: 'alert',
        title: '해당 게시물을 불러올 수 없습니다.',
        text: '',
      });
    }
  };

  const externalPath = ['FeedLink', 'NewsLink', 'FAQLink'];

  const handleExternal = () => {
    if (path === 'FeedLink') {
      return handleFeedLink();
    } else if (path === 'NewsLink') {
      return handleNewsLink();
    } else if (path === 'FAQLink') {
      return handleFaqLink();
    }
  };

  return (
    <button className="text-left" onClick={onClick} type="button">
      {externalPath.includes(path) ? (
        <button
          className="flex px-5 py-2.5 space-x-2.5 text-left"
          type="button"
          onClick={handleExternal}
        >
          <div className="w-6 h-6" style={{ minWidth: 24 }}>
            <NotificationIcon type={type} read={read} />
          </div>
          <div>
            <p
              className={classNames('text-md font-medium', {
                'text-gray800': !read,
                'text-gray300': read,
              })}
            >
              {content}
            </p>
            <p className="text-sm font-medium text-gray300">
              {formatTimeAgo(created_at)}
            </p>
          </div>
        </button>
      ) : (
        <Link to={path} state={{fromScreen:'notification'}} className="flex px-5 py-2.5 space-x-2.5">
          <div className="w-6 h-6" style={{ minWidth: 24 }}>
            <NotificationIcon type={type} read={read} />
          </div>
          <div>
            <p
              className={classNames('text-md font-medium', {
                'text-gray800': !read,
                'text-gray300': read,
              })}
            >
              {content}
            </p>
            <p className="text-sm font-medium text-gray300">
              {formatTimeAgo(created_at)}
            </p>
          </div>
        </Link>
      )}
    </button>
  );
};

const IconWrapper = styled.div`
  width: inherit;
  height: inherit;
  border-radius: 999px;
  background-color: #f2f2f5;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
