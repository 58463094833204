import { FC } from 'react';

import { Link } from 'react-router-dom';
import { formatTimeAgo } from 'utils/formatTimeAgo';

interface BoardListItemProps {
  _id: string;
  title: string;
  created_at: Date;
  page?: number;
}

export const BoardListItem: FC<BoardListItemProps> = ({
  _id,
  created_at,
  title,
  page = 1,
}) => {
  return (
    <Link to={`/board/${_id}`} state={{page}} className="py-3 border-b border-b-gray100 block">
      <h2 className="text-lg font-semibold text-gray01 line-clamp-2">
        {title}
      </h2>
      <p className="text-sm font-medium text-gray300">
        {formatTimeAgo(created_at)}
      </p>
    </Link>
  );
};
