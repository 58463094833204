import { HttpError } from 'apis/http-error';
import { Button } from 'components/Button';
import { FieldErrorMessage } from 'components/FieldErrorMessage';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { useModalContext } from 'modal/context';
import React, {FC, useEffect, useState} from 'react';
import styled from 'styled-components';
import { verifyCodeYup } from '../../../validation/verifyCode.yup';
import {findUsernameYup} from "../../../validation/findUsername.yup";
import {userApis} from "../../../apis/user.apis";
import CountdownTimer from "../../Register/components/timer/CountDownTimer";

interface FindUsernameProps {
    onNext: (name:string, username:string) => void;
}

export const FindUsername: FC<FindUsernameProps> = ({onNext}) => {
    const modal = useModalContext();
    const [timerStart, setTimerStart] = useState<boolean>(false);
    const [showVerifyCode, setShowVerifyCode] = useState(false);
    const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState<boolean>(true);
    const [isRequestButtonDisabled, setIsRequestButtonDisabled] = useState<boolean>(false);
    const [verifyInfo, setVerifyInfo] = useState<null | {
        id: string;
        name: string;
        phone_number: string;
    }>(null);

    useEffect(() => {
        let disableButtonTimeout: NodeJS.Timeout;
        if (isRequestButtonDisabled) {
            disableButtonTimeout = setTimeout(() => {
                setIsRequestButtonDisabled(false);
            }, 60000); // 1분 = 60000ms
        }
        return () => clearTimeout(disableButtonTimeout);
    }, [isRequestButtonDisabled]);

    const sendSmsFormik = useFormik<{ name: string, phone_number: string }>({
        initialValues: {  name : '', phone_number: ''},
        onSubmit: async (values, helper) => {
            try {
                if(isRequestButtonDisabled){
                    helper.setFieldError('phone_number','인증번호 재발송은 발송 1분 후에 가능합니다.');
                    return;
                }
                const { _id } = await userApis.requestFindUsernameCode(values.name, values.phone_number);
                modal.openModal(
                    {
                        type: 'alert',
                        title: '인증 번호를 발송했습니다.',
                        text: '',
                    }
                );
                setShowVerifyCode(true);
                setTimerStart(false); // 타이머를 재설정하기 위해 false로 설정
                setTimeout(() => setTimerStart(true), 0); // 짧은 지연 후 true로 설정
                setIsVerifyButtonDisabled(false);
                setIsRequestButtonDisabled(true);
                setVerifyInfo({
                    id: _id,
                    name: values.name,
                    phone_number: values.phone_number,
                });
            } catch (e: any) {
                const error = e as HttpError;
                modal.openModal({ title: error.message, text: '', type: 'alert' });
            } finally {
               helper.setSubmitting(false);
            }
        },
        validationSchema: findUsernameYup,
        validateOnBlur: false,
        validateOnChange: false,
    });
    const verifyCodeFormik = useFormik<{ code: string }>({
        initialValues: { code: '' },
        onSubmit: async (values, helper) => {
            try {
                const { message, valid , user } = await userApis.verifyFindUsernameCode(
                    verifyInfo!.id,
                    sendSmsFormik.values.name,
                    sendSmsFormik.values.phone_number,
                    values.code
                );
                if (valid) {
                    onNext(user.name, user.username,);
                } else {
                    helper.setFieldError('code', message);
                }
            } catch (e) {
                const error = e as HttpError;
                modal.openModal({ title: error.message, text: '', type: 'alert' });
            } finally {
                helper.setSubmitting(false);
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: verifyCodeYup,
    });
    return (
            <Container>
                <div className="mt-1.5">
                    <Description className="text-gray800">
                        가입 시 입력한 정보를 정확히 입력해 주세요.
                    </Description>
                    <FormikProvider value={sendSmsFormik}>
                        <Form onSubmit={sendSmsFormik.handleSubmit}>
                            <div>
                                <label
                                    className="block text-lg font-semibold text-gray800 mb-px7"
                                    htmlFor="name"
                                >
                                    이름
                                </label>
                                <Field
                                    id="name"
                                    name="name"
                                    className="input"
                                    placeholder="이름을 입력해 주세요."
                                    onBlur={() => sendSmsFormik.validateField('name')}
                                    onChange={sendSmsFormik.handleChange}
                                    maxLength={50}
                                />
                                <FieldErrorMessage name="name"/>
                            </div>
                            <label
                                className="block text-lg font-semibold text-gray800 mb-px7"
                                htmlFor="phone_number"
                            >
                                휴대전화 번호
                            </label>
                            <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                                <Field
                                    id="phone_number"
                                    name="phone_number"
                                    className="input mr-2"
                                    placeholder="-없이 숫자만 입력"
                                    type="tel"
                                    maxLength={11}
                                    onInput={(e: { target: { value: string; }; }) => {
                                        e.target.value = e.target.value.replace(/\D/g, '').slice(0, 11);
                                    }}
                                    onBlur={() => sendSmsFormik.validateField('phone_number')}
                                    onChange={sendSmsFormik.handleChange}
                                />
                                <RequestButton type="submit" disabled={sendSmsFormik.isSubmitting}>
                                    {verifyInfo ? '재발송' : '인증요청'}
                                </RequestButton>
                            </div>
                            <FieldErrorMessage name="phone_number"/>
                        </Form>
                    </FormikProvider>
                        <FormikProvider value={verifyCodeFormik}>
                            <Form  onSubmit={verifyCodeFormik.handleSubmit}>
                                {showVerifyCode ? (
                                    <div>
                                        <label
                                            className="block text-lg font-semibold text-gray800 mb-px7"
                                            htmlFor="code"
                                        >
                                            인증번호
                                        </label>
                                        <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                                            <Field
                                                id="code"
                                                name="code"
                                                className="input mr-2"
                                                placeholder="인증번호 6자리 입력"
                                                type="text"
                                                maxLength={6}
                                                onInput={(e: { target: { value: string; }; }) => {
                                                    e.target.value = e.target.value.replace(/\D/g, '').slice(0, 6);
                                                }}
                                            />
                                            <CountdownTimer start={timerStart}
                                                            setFieldError={verifyCodeFormik.setFieldError}/>
                                        </div>
                                        <FieldErrorMessage name="code"/>
                                    </div>
                                ) : null}
                                <div style={{marginTop: 30}}>
                                    <Button type="submit" disabled={isVerifyButtonDisabled || verifyCodeFormik.isSubmitting} >인증하기</Button>
                                </div>
                            </Form>
                        </FormikProvider>
                </div>
            </Container>
    );
};

const Container = styled.div`
    padding: 30px 20px 55px;
`;
const Description = styled.p`
    font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
`;

const RequestButton = styled.button`
    position: absolute;
    top: 6.5px;
    right: 18px;
    padding: 10px;
    background-color: var(--grayscale-white);
    color: var(--grayscale-black);
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 80px;
    height: 40px;
    font-size: 14px;
`;

