import { FC, useState } from 'react';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';

import { BrochureList } from '../../components/BrochureList';
import { brochureApis } from '../../apis/brochure.apis';
import { DropdownOptions } from 'components/DropdownOptions';
import {Link, useLocation} from 'react-router-dom';
import { SearchIcon } from '@assets/components/search-icon';

interface BrochureScreenProps {}

const CATEGORY = [
  '전체',
  '건축용',
  '공업용',
  '내화방염',
  '목재목공용',
  '방수바닥용',
  '분체',
  '산업플랜트',
  '자동차보수용',
  '특수공업용',
  '기타',
];

const TYPES = ['전체', '색견본', '카달로그', '자료집'];

const typeMap: Record<string, string> = {
  전체: 'total',
  색견본: 'colorswatch',
  카달로그: 'catalog',
  자료집: 'databook',
};
export const BrochureScreen: FC<BrochureScreenProps> = () => {
  const valueToKeyMap = Object.fromEntries(
      Object.entries(typeMap).map(([key, value]) => [value, key])
  );
  const getKeyFromValue = (value: string) => {
    return valueToKeyMap[value];
  };
  const location = useLocation();
  const { state } = location as { state: { page?: number, category?:string, type?:string } };
  const tmpType = getKeyFromValue(state?.type || '');
  const [type, setType] = useState(tmpType|| TYPES[0]);
  const [category, setCategory] = useState(state?.category||CATEGORY[0]);
  const [page, setPage] =useState( state?.page || 1);
  const handleType= (type:string) =>{
    setType(type);
    setPage(1);
  }

  const handleCategory= (category:string) =>{
    setCategory(category);
    setPage(1);
  }

  return (
    <Layout renderHeader={<Header title="제품홍보" />}>
      <div className="relative flex flex-col min-h-full">
        <div className="flex px-5 justify-between py-2.5 items-center sticky top-0 bg-white">
          <div className="flex space-x-2.5">
            <DropdownOptions options={TYPES} value={type} onSelect={handleType} />
            <DropdownOptions options={CATEGORY} value={category} onSelect={handleCategory}/>
          </div>
          <Link to="/search" className="block w-8 h-8 -m-1">
            <SearchIcon width="32" height="32" />
          </Link>
        </div>
        <BrochureList
          category={category}
          queryFn={brochureApis.getListGenerator(typeMap[type])}
          type={typeMap[type]}
          key={typeMap[type]}
          vPage={page}
        />
      </div>
    </Layout>
  );
};
