import { FC } from 'react';

interface BackBtnIconProps {}

export const BackBtnIcon: FC<BackBtnIconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_428_2304)">
        <path
          d="M16.1324 5.90245L14.7974 4.57495L7.37988 12L14.8049 19.425L16.1324 18.0975L10.0349 12L16.1324 5.90245Z"
          fill="#B5B5B7"
        />
      </g>
      <defs>
        <clipPath id="clip0_428_2304">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
