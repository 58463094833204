import React, {useState} from 'react';
import {IoCheckboxSharp} from "react-icons/io5";
import {SamhwaBrandLogo} from "@assets/components/samhwa-brand-logo";
import styled from "styled-components";
import {useModalContext} from "../../../../modal/context";
import {userApis} from "../../../../apis/user.apis";
import {HttpError} from "../../../../apis/http-error";
import {useAuthDispatch} from "../../../../auth";
import {useNavigate} from "react-router-dom";
import {Header} from "../../../../components/Header";
import {Layout} from "../../../../components/Layout";
import {Field, Form, FormikProvider, useFormik} from "formik";
interface DeleteAccountScreenProps {
}

const DeleteAccountScreen: React.FC<DeleteAccountScreenProps> = () => {
    const { logout } = useAuthDispatch();
    const navigate = useNavigate();
    const modalDispatch = useModalContext();
    const [inProgress, setInProgress] = useState(false);
    const formik = useFormik({
        initialValues: {
            isAgree: false,
        },
        onSubmit: (values) => {
            handleWithdrawal();
        },
    });

    const removeUser = () => {
        setInProgress(true);
        userApis
            .removeUser()
            .then(() => {
                modalDispatch.openModal(
                    {
                        type: 'alert',
                        title: '탈퇴 완료되었습니다.\n이용해 주셔서 감사합니다.',
                        text: '',
                    },
                    {
                        onClose: () => {logout();navigate('/', { replace: true });},
                        closeTypes: 'sideeffect'
                    }
                );
            })
            .catch((error: HttpError) => {
                if (error.code === 'USER_NOT_FOUND') {
                    logout();
                    navigate('/', { replace: true });
                } else {
                    setInProgress(false);
                    modalDispatch.openModal({
                        type: 'alert',
                        title: '오류',
                        text: error.message,
                    });
                }
            })
            .finally(() => {

            });
    };
    const handleWithdrawal = () =>{
        modalDispatch.openModal(
            { type: 'alert', title: '탈퇴 후에는 계정 정보를\n복구할 수 없습니다.\n정말로 계정을 삭제하시겠습니까?', text: '' },
            {
                buttonText: '삭제',
                onClose: () => {
                    removeUser();
                },
                buttonType: '2',
                inProgress: inProgress,
            }
        );
    }

    const handleIndividualChange = (field: string, value: boolean) => {
        formik.setFieldValue(field, value);
    };

    return (
        <Layout renderHeader={<Header hasBack />} hideFooter>
            <FormikProvider value={formik}>
                <Form className="mt-1.5 p-6" onSubmit={formik.handleSubmit} >
                    <div className="flex flex-col ">
                        <Container>
                            <div className="mb-5">
                            <h1 className="text-head font-bold mb-3">
                                서비스 탈퇴 전 유의사항
                            </h1>
                            <span className="flex-grow text-md">탈퇴 유의사항을 확인하신 후 회원탈퇴를 진행해주세요.</span>
                            </div>
                            <div
                                className="bg-white border border-gray100 overflow-auto p-2.5 rounded-px5 text-gray400 text-sm font-medium">
                                <Content className="mb-2">• 그동안 페인트프로 서비스를 이용해주셔서 감사합니다.</Content><br/>
                                <Content className="mb-2">• 서비스 탈퇴 시 회원 전용 서비스 이용이 불가능합니다.</Content><br/>
                                <Content className="mb-2">• 서비스 탈퇴 신청 후 서비스 탈퇴가 완료되면 해당 계정에 대한 모든 정보가
                                    삭제됩니다.</Content><br/>
                                <Content>• 탈퇴 후 1일이 지나면 동일한 아이디와 휴대전화번호로 재가입할 수 있습니다.</Content><br/>
                            </div>
                        </Container>
                        <div style={{marginTop: 30}}>
                            <div className="flex items-center mb-3 px-2 py-2 cursor-pointer">
                                <div className="flex items-start cursor-pointer w-full justify-between"
                                     onClick={() => handleIndividualChange('isAgree', !formik.values.isAgree)}
                                >
                                    {formik.values.isAgree ? <IoCheckboxSharp className="w-6 h-6 mr-2" style={{color:'#d82148'}}/> :
                                        <IoCheckboxSharp className="w-6 h-6 mr-2" style={{ color: 'var(--grayscale-300)'}}/>}
                                    <Field
                                        type="checkbox"
                                        name="termsOfService"
                                        className="hidden"
                                    />
                                    <span className="flex-grow text-md">탈퇴 유의사항을 모두 확인하였으며, 페인트프로 서비스 탈퇴에 동의합니다.</span>
                                </div>
                            </div>
                            <Button className="mt-4 border"
                                    type="submit"
                                    disabled={!formik.values.isAgree || inProgress}
                            >
                                탈퇴하기
                            </Button>
                        </div>
                        <SamhwaWrapper>
                            <SamhwaBrandLogo/>
                        </SamhwaWrapper>
                    </div>
                </Form>
            </FormikProvider>
        </Layout>
    );
};

export default DeleteAccountScreen;


const SamhwaWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-top: 50px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0 0;
    height: 100%;
    max-height: 100%;
`;
const Content = styled.span`
    display: inline-block;
    text-indent: -0.8em;
    padding-left: 1em;
`;

export const Button = styled.button`
  display: block;
  width: 100%;
  color: var(${'--grayscale-white'});
  background-color: #d82148;
  border-style: solid;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -3%;
  font-weight: 600;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
      background-color: var(${'--grayscale-300'});
    );
    cursor: auto;
  }
`;