import { FC, useMemo } from 'react';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import {useNavigate, useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { brochureApis } from '../../apis/brochure.apis';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { BrochureDetailPresenter } from '../../components/BrochureDetailPresenter';

interface BrochureDetailScreenProps {}

export const BrochureDetailScreen: FC<BrochureDetailScreenProps> = () => {
  const params = useParams();
  const id = params.id ?? '';
  const enabled = !!id;
  const nav = useNavigate();
  const location = useLocation();
  const { state } = location as { state: { page?: number, fromScreen?:string, vCategory?:string, vType?:string} };
  const page = state?.page || 1;
  const fromScreen = state?.fromScreen;
  const category = state?.vCategory;
  const type = state?.vType;

  const handleBack = () => {
    if(fromScreen === 'brochure'){
      nav('/brochure', {
        state: {
          page,
          category,
          type,
        },
      });
    }else{
      nav(-1);
    }
  };

  const query = useQuery({
    queryKey: ['brochure-detail', id],
    queryFn: () => brochureApis.getOne(id),
    enabled: enabled,
  });

  const SHOW_NONE_INFO =
    enabled === false ||
    (enabled === true && query.isLoading === false && !query.data);
  const IS_LOADING = enabled === true && query.isLoading === true;
  const GET_DATA =
    enabled === true && query.isLoading === false && !!query.data;
  const title = useMemo(() => {
    return query.data?.type ?? '';
  }, [query.data?.type]);
  return (
    <Layout hideFooter renderHeader={<Header hasBack title={title} onBack={handleBack} />}>
      {SHOW_NONE_INFO ? (
        <div className="p-5 flex justify-center items-center flex-col h-full pt-0 pb-8">
          <p className="text-lg font-semibold text-gray300 text-center">
            홍보물이 삭제되었거나,
          </p>
          <p className="text-lg font-semibold text-gray300 text-center">
            요청 정보가 잘못되었습니다.
          </p>
        </div>
      ) : null}
      {IS_LOADING ? (
        <div className="h-full flex justify-center items-center pb-12">
          <LoadingIndicator />
        </div>
      ) : null}
      {GET_DATA ? <BrochureDetailPresenter {...query.data} /> : null}
    </Layout>
  );
};
