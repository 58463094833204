import { HideIcon } from '@assets/components/hide-icon';
import { authApis } from 'apis/auth.apis';
import { HttpError } from 'apis/http-error';
import { Button } from 'components/Button';
import { FieldErrorMessage } from 'components/FieldErrorMessage';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { useModalContext } from 'modal/context';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UpdatePwYup } from 'validation/update-pw.yup';

interface FindPasswordEditProps {
    id:string,
    phone_number:string,
    username: string
}

const FindPasswordEdit: FC<FindPasswordEditProps> = ({id, phone_number, username}) => {
    const [hidePw, setHidePw] = useState(true);
    const [hidePwConfirm, setHidePwConfirm] = useState(true);
    const togglePw = () => setHidePw((prev) => !prev);
    const togglePwConfirm = () => setHidePwConfirm((prev) => !prev);
    const navigate = useNavigate();
    const modal = useModalContext();

    const formik = useFormik<{ password: string; passwordConfirm: string; username:string }>({
        initialValues: { password: '', passwordConfirm: '', username: username },
        onSubmit: async (values, helper) => {
            try {
                await authApis.changePw(id, phone_number, values.password);
                modal.openModal(
                    { type: 'alert', title: '비밀번호를 재설정 하였습니다.', text: ''},
                    {
                        onClose: () => navigate(-1),
                        closeTypes: 'sideeffect'
                    }
                );
            } catch (e) {
                const error = e as HttpError;
                modal.openModal({ type: 'alert', title: error.message, text: '' });
            } finally {
                helper.setSubmitting(false);
            }
        },
        validationSchema: UpdatePwYup,
        validateOnBlur: false,
        validateOnChange: false,
    });
    useEffect(() => {
        if (!id || !phone_number || !username) {
            navigate(-1);
        }
    }, );
    return (
        <Container>
            <Description className="text-gray800">
                비밀번호 재설정
            </Description>
            <FormikProvider value={formik}>
                <Form className="mt-1.5 space-y-3" onSubmit={formik.handleSubmit}>
                    <div>
                        <label
                            className="block text-lg font-semibold text-gray800 mb-px7"
                            htmlFor="password"
                        >
                            신규 비밀번호
                        </label>
                        <div className="relative">
                            <Field
                                id="password"
                                type={hidePw ? 'password' : ''}
                                name="password"
                                className="input pr-12"
                                placeholder="숫자, 영문자, 특수문자 중 2개 이상 조합 8~20자"
                                maxLength={20}
                                onBlur={() => formik.validateField('password')}
                                onChange={formik.handleChange}
                            />
                            <button
                                type="button"
                                onClick={togglePw}
                                className="absolute right-3 top-4"
                                tabIndex={-1}
                            >
                                <HideIcon mode={hidePw ? 'hide' : 'visible'}/>
                            </button>
                        </div>
                        <FieldErrorMessage name="password"/>
                    </div>
                    <div>
                        <label
                            className="block text-lg font-semibold text-gray800 mb-px7"
                            htmlFor="passwordConfirm"
                        >
                            신규 비밀번호 확인
                        </label>
                        <div className="relative">
                            <Field
                                id="passwordConfirm"
                                type={hidePwConfirm ? 'password' : ''}
                                name="passwordConfirm"
                                className="input pr-12"
                                placeholder="숫자, 영문자, 특수문자 중 2개 이상 조합 8~20자"
                                maxLength={20}
                                onBlur={() => formik.validateField('passwordConfirm')}
                                onChange={formik.handleChange}
                            />
                            <button
                                type="button"
                                onClick={togglePwConfirm}
                                className="absolute right-3 top-4"
                                tabIndex={-1}
                            >
                                <HideIcon mode={hidePwConfirm ? 'hide' : 'visible'}/>
                            </button>
                        </div>
                        <FieldErrorMessage name="passwordConfirm"/>
                    </div>
                    <div style={{marginTop: 30}}>
                        <Button type="submit">완료</Button>
                    </div>
                </Form>
            </FormikProvider>
        </Container>
    );
};

export default FindPasswordEdit;

const Container = styled.div`
    padding: 30px 20px 55px;
`;

const Description = styled.p`
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
`;
