import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children?: ReactNode;
}

export const Portal: FC<PortalProps> = ({ children }) => {
  const rootElement = document.getElementById('app-modal');
  return rootElement && children ? createPortal(children, rootElement) : null;
};
