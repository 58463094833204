import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { getPageBlock } from '../utils/getPageBlock';
import classNames from 'classnames';
import { LeftPaginationIcon } from '@assets/components/left-pagination-icon';
import { RightPaginationIcon } from '@assets/components/right-pagination-icon';
import {DoubleRightPaginationIcon} from "@assets/components/double-right-pagination-icon";
import {DoubleLeftPaginationIcon} from "@assets/components/double-left-pagination-icon";
interface PaginationComponentProps {
  onClick?: (page: number) => void;
  page?: number;
  total_count?: number;
  page_size?: number;
}

export const PaginationComponent: FC<PaginationComponentProps> = ({
  onClick,
  page = 1,
  page_size = 1,
  total_count = 0,
}) => {
  const scrollToTop = () => {
    const mainContentElement = document.getElementById('main-content');
    if (mainContentElement) {
      mainContentElement.scrollTo({ top: 0 });
    }
  };
  const generateClickPage = (next?: number) => () => {
      if (next) {
      onClick?.(next);
      scrollToTop();
    }
  };
    const data = useMemo(() => {
        const totalPages = Math.ceil(total_count / page_size);
        const pageNumbers = [];
        const maxPageNumbers = 5;
        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > maxPageNumbers) {
            if (page <= 3) {
                startPage = 1;
                endPage = maxPageNumbers;
            } else if (page + 1 >= totalPages) {
                startPage = totalPages - maxPageNumbers + 1;
                endPage = totalPages;
            } else {
                startPage = page - 2;
                endPage = page + 2;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return {
            pageNumbers,
            prev: page > 1 ? page - 1 : null,
            next: page < totalPages ? page + 1 : null,
            startPage,
            endPage,
            totalPages,
        };
    }, [page, page_size, total_count]);

  return (
      <PaginationWrapper className="space-x-1.5">
          {
              page > 1 && data.startPage > 1 ? (
                  <button
                      type="button"
                      onClick={generateClickPage(1)}
                  >
                      <DoubleLeftPaginationIcon/>
                  </button>
              ) : (
                  <EmptyContainer></EmptyContainer>
              )
          }
          { page > 1 ? (
              <button
                  type="button"
                  onClick={generateClickPage(page -1)}
              >
                  <LeftPaginationIcon/>
              </button>
          ): (
              <EmptyContainer></EmptyContainer>
          )
          }
          {data.pageNumbers.map((v) => (
              <PaginationButton
                  key={`page-${v}`}
                  disabled={v === page}
                  type="button"
                  className={classNames({active: v === page})}
                  onClick={generateClickPage(v)}
              >
                  {v}
              </PaginationButton>
          ))}
          {page < Math.ceil(total_count / page_size) ? (
              <button
                  type="button"
                  onClick={generateClickPage(page + 1)}
              >
                  <RightPaginationIcon/>
              </button>
          ): (
              <EmptyContainer></EmptyContainer>
          )
          }
          {page < data.totalPages && data.endPage < data.totalPages ?(
              <button
                  type="button"
                  onClick={generateClickPage(Math.ceil(total_count / page_size))}
              >
                  <DoubleRightPaginationIcon/>
              </button>
          ): (
              <EmptyContainer></EmptyContainer>
          )
          }
      </PaginationWrapper>
  );
};

const PaginationWrapper = styled.div`
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PaginationButton = styled.button`
    border: 1px solid var(--grayscale-black);
    display: block;
    width: 29px;
    height: 29px;
    border-radius: 5px;
    color: var(--grayscale-black);
    background-color: var(--grayscale-white);
    font-size: 14px;
    line-height: 22px;
  &.active {
    color: var(--grayscale-white);
    background-color: var(--grayscale-black);
  }
  &:last-child {
    margin-right: 0;
  }
  &.prev,
  &.next {
    padding: 2px;
    svg {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
  &.next svg {
    transform: rotateY(180deg) translateX(-2px);
  }
`;


const EmptyContainer = styled.div`
    width: 25px;
`;