import { httpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

async function getList(page: number) {
  try {
    const response = await httpClient.get(`/board?page=${page}`);
    const data = response.data as {
      total_count: number;
      rows: { _id: string; title: string; created_at: string }[];
      page: number;
      page_size: number;
    };
    return {
      ...data,
      rows: data.rows.map((v) => ({
        ...v,
        created_at: new Date(v.created_at),
      })),
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function getOne(id: string) {
  try {
    const response = await httpClient.get(`/board/${id}`);
    const data = response.data as {
      title: string;
      content: string;
      created_at: string;
      image?: { url: string };
    };
    return {
      title: data.title,
      content: data.content,
      created_at: new Date(data.created_at),
      image: data.image ?? null,
    } as {
      title: string;
      content: string;
      created_at: Date;
      image?: { url: string };
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function getRecently() {
  try {
    const response = await httpClient.get('/board/recently?v=2');
    return response.data as null | {
      title: string;
      _id: string;
      type: 'board' | 'brochure';
      announce_at: string;
    };
  } catch {
    return null;
  }
}

export const boardApis = { getList, getOne, getRecently };
