import { FC } from 'react';
import { useHttpClientSetting } from '../hooks/useHttpClientSetting';
import { useTermScreen } from '../hooks/useTermScreen';

export const Bootstrap: FC = () => {
  useTermScreen();
  useHttpClientSetting();

  return null;
};
