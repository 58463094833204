import { FC } from 'react';

interface ProfileIconProps {}

export const ProfileIcon: FC<ProfileIconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_312_4925)">
        <rect x="1" y="1" width="22" height="22" rx="11" fill="#F2F2F5" />
        <path
          d="M20 21.5442C20 22.3682 19.3872 23.0636 18.5697 23.1672L12 24L5.4303 23.1672C4.61283 23.0636 4 22.3682 4 21.5442C4 17.9299 6.92992 15 10.5442 15L13.4558 15C17.0701 15 20 17.9299 20 21.5442Z"
          fill="#B1B4C0"
        />
        <circle cx="12" cy="9" r="4" fill="#B1B4C0" />
      </g>
      <defs>
        <clipPath id="clip0_312_4925">
          <rect x="1" y="1" width="22" height="22" rx="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
