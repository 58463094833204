import { FC, FormEventHandler, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { SearchHeader } from '../../components/SearchHeader';
import { SearchResult } from './components/SearchResult';
import { SearchIcon } from '@assets/components/search-icon';
import { SearchCloseIcon } from '@assets/components/search-close-icon';

interface SearchScreenProps {}

export const SearchScreen: FC<SearchScreenProps> = () => {
  const navigation = useNavigate();
  const param = useLocation();
  const [input, setInput] = useState(param.state?.text ?? '');
  const inputMode = !param?.state?.text;
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigation('/search', {
      state: {
        text: input.trim(),
      },
    });
  };
  const handleInitialize = () => {
    setInput('');
    navigation('/search', {
      state: {
        text: '',
      },
      replace: true,
    });
  };

  return (
    <Layout
      renderHeader={
        <SearchHeader
          inputText={input}
          onChange={setInput}
          hasBack
          renderRightBtn={
            inputMode ? (
              <button
                type="submit"
                className="block"
                style={{ cursor: 'pointer' }}
                key="search"
              >
                <SearchIcon />
              </button>
            ) : (
              <button
                type="button"
                style={{ cursor: 'pointer' }}
                key="cancel"
                onClick={handleInitialize}
              >
                <SearchCloseIcon />
              </button>
            )
          }
          onSubmit={handleSubmit}
          title={param.state?.text ?? ''}
        />
      }
      hideFooter
    >
      <SearchResult searchText={param.state?.text ?? ''} />
    </Layout>
  );
};
