import { FC } from 'react';

interface SearchCloseIconProps {}

export const SearchCloseIcon: FC<SearchCloseIconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#F2F2F5" />
      <path d="M8 8L16 16" stroke="#898989" strokeWidth="1.2" />
      <path d="M8 16L16 8" stroke="#898989" strokeWidth="1.2" />
    </svg>
  );
};
