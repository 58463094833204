import { FC, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';

interface DropdownOptionsProps {
  value?: string;
  options: string[];
  onSelect?: (next: string) => void;
}

export const DropdownOptions: FC<DropdownOptionsProps> = ({
  options,
  value,
  onSelect,
}) => {
  return (
    <div>
      <Listbox value={value ?? options[0]} onChange={onSelect}>
        <div className="relative inline-block text-left">
          <Listbox.Button className="inline-flex w-full justify-center">
            <div
              className="flex items-center border bg-white border-gray100 rounded-px5 space-x-2.5"
              style={{ padding: '3px 11px' }}
            >
              <span className="block text-md font-medium text-gray800">
                {value}
              </span>
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_428_426)">
                  <path
                    d="M1.2902 2.16322L0.700195 2.75656L4.0002 6.05322L7.30019 2.75322L6.71019 2.16322L4.00019 4.87322L1.2902 2.16322Z"
                    fill="#202020"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_428_426">
                    <rect
                      width="8"
                      height="8"
                      fill="white"
                      transform="translate(0 8) rotate(-90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="absolute mt-2.5 max-h-60 w-full overflow-auto bg-white rounded-px5"
              style={{ width: 120, boxShadow: '0px 4px 4px 0px #00000026' }}
            >
              {options.map((v) => (
                <Listbox.Option
                  key={v}
                  value={v}
                  className="px-2.5 py-3 border-b border-b-gray100 last:border-none"
                >
                  <span className="block text-md font-medium">{v}</span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
