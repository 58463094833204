import { FC, useCallback, useMemo } from 'react';
import { TFeedListItem } from '../../../types/feed-list-item.type';
import styled from 'styled-components';
import { youtubeLinkParser } from '../../../utils/youtubeLinkParser';
import { feedApis } from '../../../apis/feed.apis';
import { linkingSupport } from '../../../utils/linkingSupport';

interface FeedListItemProps extends TFeedListItem {}

export const FeedListItem: FC<FeedListItemProps> = ({
  thumbnail,
  text,
  link,
  _id,
}) => {
  const thumbnailUrl = useMemo(() => {
    const youtubeId = youtubeLinkParser(link);
    if (youtubeId) {
      return `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
    }

    return thumbnail?.url;
  }, [link, thumbnail?.url]);

  const handleUpdateView = useCallback(() => {
    return feedApis.updateView(_id).catch(() => {});
  }, [_id]);

  const handleClick = async () => {
    await handleUpdateView();
    linkingSupport(link);
  };

  return (
    <Container type="button" onClick={handleClick}>
      {thumbnailUrl ? (
        <Thumbnail
          className="rounded-px5 border border-gray100"
          data={thumbnailUrl}
        />
      ) : null}
      <h2 className="text-lg font-semibold text-gray800 mt-2.5 line-clamp-2">
        {text}
      </h2>
    </Container>
  );
};
const Container = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
`;
const Thumbnail = styled.div<{ data: string }>`
  width: 100%;
  position: relative;
  background-color: var(--grayscale-300);
  background-image: url('${(props) => props.data}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 320 / 180;
`;
