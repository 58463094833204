import { FC, useState, useEffect   } from 'react';
import styled from "styled-components";
import { LiaToggleOnSolid } from "react-icons/lia";
interface ToggleButtonIconProps {
    isEnabled: boolean;
    onToggle: (isEnabled: boolean) => void;
}

export const ToggleButtonIcon: FC<ToggleButtonIconProps> = ({ isEnabled, onToggle }) => {
    const [isOn, setIsOn] = useState(isEnabled ?? false);
    useEffect(() => {
        setIsOn(isEnabled);
    }, [isEnabled]);

    const toggle = () => {
        const newIsOn = !isOn;
        setIsOn(newIsOn);
        onToggle(newIsOn);
    };
  return (
      <IconContainer onClick={toggle} isOn={isOn}>
          {isOn ? <LiaToggleOnSolid /> : <LiaToggleOnSolid style={{ transform: 'scaleX(-1)'}} />}
      </IconContainer>
  );
};

const IconContainer = styled.div<{ isOn: boolean }>`
  font-size: 45px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  transition: color 0.6s ease, transform 0.6s ease;
    color: ${({ isOn }) => (isOn ? '#3f86f1' : '#666666' )};
`;