import { FC } from 'react';
import { Layout } from '../../components/Layout';
import { Banner } from './components/Banner';

import styled from 'styled-components';
import { SamhwaLogoSvg } from '../../assets';
import { Link } from 'react-router-dom';

import { RecentlyBoard } from './components/RecentlyBoard';
import { AuthRightButton } from './components/AuthRightButton';
import { SearchIcon } from '@assets/components/search-icon';

interface HomeScreenProps {}

export const HomeScreen: FC<HomeScreenProps> = () => {
  return (
    <Layout
      renderHeader={
        <Header>
          <LogoWrapper>
            <SamhwaLogoSvg />
          </LogoWrapper>
          <NotiIconPosition>
            <AuthRightButton />
          </NotiIconPosition>
        </Header>
      }
    >
      <Container>
        <SearchBannerArea>
          <Banner />
          <RecentlyBoard />
        </SearchBannerArea>
        <NaviagaionArea>
          <WelcomeMessage>
            삼화페인트와
            <br />
            일상을 지켜보세요.
          </WelcomeMessage>
          <SearchBox to="/search">
            <p className="text-lg text-gray300 font-semibold">
              홍보물명을 입력해 주세요.
            </p>
            <SearchIcon />
          </SearchBox>
        </NaviagaionArea>
      </Container>
    </Layout>
  );
};
const LogoWrapper = styled.div`
  height: 24px;
  & > svg {
    height: 24px;
  }
`;
const WelcomeMessage = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  background-color: var(--grayscale-white);
  flex-direction: column;
`;
const SearchBannerArea = styled.div`
  position: relative;
  flex: 1;
`;
const Header = styled.div`
  background-color: var(--grayscale-white);
  height: 52px;
  position: relative;
  border-bottom: 1px solid var(--grayscale-100);
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const NotiIconPosition = styled.div``;
const NaviagaionArea = styled.div`
  background-color: var(--grayscale-white);
  padding: 20px;
  border-radius: 20px 20px 0 0;
  margin-top: -36px;
  position: relative;
  z-index: 1;
`;
const SearchBox = styled(Link)`
  height: 54px;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  background-color: var(--grayscale-100);
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
`;
