import React, {FC, useEffect} from "react";
import styled from "styled-components";
import { AiOutlineCheck } from "react-icons/ai";
import {Button} from "../../../components/Button";
import {useNavigate} from "react-router-dom";
interface FindUserCompleteProps {
    onReset:() => void;
    name:string,
    username:string,
}

const FindUserComplete: FC<FindUserCompleteProps> = ({onReset, name, username}) => {
    const navigate = useNavigate();
    const confirm = () => {
        onReset();
        navigate(-1);
    }

    useEffect(() => {
        if (!name || !username) {
            navigate(-1);
        }
    }, );

    return (
        <>
            <Container>
                <div className="mt-1.5">
                    <IconContainer>
                        <AiOutlineCheck/>
                    </IconContainer>
                    <Description className="text-gray800">
                        <div>
                            {name}님의 아이디는
                        </div>
                        <div >
                            <span style={{color: "#4B89DC"}}>{username}</span>입니다.
                        </div>
                    </Description>
                    <div style={{marginTop: 50}}>
                        <Button onClick={confirm}>확인</Button>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default FindUserComplete;
const Container = styled.div`
    padding: 30px 20px 55px;
`;
const Description = styled.p`
    font-size: 1.25rem; /* 20px */
    line-height: 2rem; /* 32px */
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
`;

const IconContainer = styled.div`
    display: flex;
    font-size: 2.25rem; /* 36px */
    line-height: 2.5rem; /* 40px */
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 50px;
    color: green;
`;


