import { useInfiniteQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { searchApis } from '../../../apis/search.apis';
import { useModalContext } from '../../../modal/context';
import { BrochureListItem } from '../../../components/BrochureListItem';
import { IntersectionObserverTrigger } from '../../../components/IntersectionObserverTrigger';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { DropdownOptions } from 'components/DropdownOptions';

interface SearchResultProps {
  searchText: string;
}

type TabName = '전체' | '자료집' | '카달로그' | '색견본';
const TABS: TabName[] = ['전체', '자료집', '카달로그', '색견본'];
const CATEGORIES: string[] = [
  '전체',
  '건축용',
  '공업용',
  '내화방염',
  '목재목공용',
  '방수바닥용',
  '분체',
  '산업플랜트',
  '자동차보수용',
  '특수공업용',
  '기타',
];
export const SearchResult: FC<SearchResultProps> = ({ searchText }) => {
  const [tab, setTab] = useState<TabName>('전체');
  const [category, setCategory] = useState('전체');
  const modalDispatch = useModalContext();
  const query = useInfiniteQuery({
    queryKey: ['search', searchText, 'tab', tab, 'category', category],
    queryFn: ({ pageParam = 1 }) =>
      searchApis.getList(pageParam, searchText, tab, category),
    initialPageParam: 1,
    getNextPageParam: (page) => {
      if (page.rows.length === 25) {
        return page.page + 1;
      }
      return undefined;
    },
    throwOnError(error) {
      modalDispatch.openModal({
        type: 'alert',
        title: '오류',
        text: error.message,
      });
      return true;
    },
    enabled: !!searchText,
  });

  const handleNextFetch = () => {
    if (
      query.hasNextPage === true &&
      query.isFetchingNextPage === false &&
      query.isLoading === false
    ) {
      query.fetchNextPage({});
    }
  };

  if (searchText === '') {
    return null;
  }
  const rows = (query.data?.pages ?? []).flatMap((v) => v.rows);

  return (
    <div className="relative h-full flex flex-col">
      <div className="px-5 py-2.5 flex space-x-2.5 sticky top-0 bg-white">
        <DropdownOptions
          value={tab}
          options={TABS}
          onSelect={(v) => setTab(v as any)}
        />
        <DropdownOptions
          value={category}
          options={CATEGORIES}
          onSelect={(v) => setCategory(v)}
        />
      </div>
      <div className="flex-1">
        {query.isLoading === true ? (
          <div className="h-full flex items-center justify-center pb-12">
            <LoadingIndicator />
          </div>
        ) : null}
        {query.isLoading === false && rows.length === 0 ? (
          <div className="h-full flex justify-center items-center flex-col pb-12">
            <p className="text-lg font-semibold text-gray300">
              ‘{searchText}’에 대한 검색 결과가 없습니다.
            </p>
            <p className="mt-2.5 text-sm font-medium text-gray300">
              검색어를 다시 확인해 주세요.
            </p>
          </div>
        ) : null}
        {rows.length > 0 ? (
          <div className="py-2.5 px-5 space-y-6">
            {rows.map((v) => {
              return <BrochureListItem {...v} key={v._id} fromScreen={'search'} />;
            })}
          </div>
        ) : null}
        <IntersectionObserverTrigger onTrigger={handleNextFetch} />
      </div>
    </div>
  );
};
