import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { LogoSamhwaWhite } from '../images';
import { useNavigate } from 'react-router-dom';
import { BackBtnIcon } from '@assets/components/back-btn-icon';

interface HeaderProps {
  title?: string;
  hasBack?: boolean;
  renderRightBtn?: ReactNode;
  onBack?: () => void;
}

export const Header: FC<HeaderProps> = ({hasBack = false, renderRightBtn = null, title = null, onBack:handleBackProp}) => {
  const nav = useNavigate();
  // const handleBack = () => nav(-1);
  const handleBack = handleBackProp || (() => nav(-1));
  return (
    <Container>
      <div className="left flex items-center">
        <BackArea type="button" onClick={handleBack} disabled={!hasBack}>
          {hasBack ? <BackBtnIcon /> : null}
          {title === null ? (
            <Logo src={LogoSamhwaWhite} alt="삼화페인트" />
          ) : (
            <h1 className="text-header font-bold" style={{ marginLeft: 5 }}>
              {title}
            </h1>
          )}
        </BackArea>
      </div>
      <div className="right">{renderRightBtn}</div>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  background-color: var(--grayscale-white);
  height: 52px;
  border-bottom: 1px solid var(--grayscale-100);
  padding: 12px 20px;
  justify-content: space-between;
  .left,
  .right {
    display: flex;
    align-items: center;
  }
`;

const Logo = styled.img`
  height: 24px;
`;

const BackArea = styled.button`
  display: flex;
  align-items: center;
`;
