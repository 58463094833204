import * as yup from 'yup';
import {phoneValidate} from "./phone.validate";
import {nameValidate} from "./name.validate";

export const findPasswordYup = yup.object().shape({
  username: yup
      .string()
      .required('아이디를 입력해 주세요.'),
  phone_number: phoneValidate,
  name: nameValidate,
});
