import { FC } from 'react';

const NotificationBadgeIcon: FC = () => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="24" y="4" width="4" height="4" rx="2" fill="#E20D3A" />
    </svg>
  );
};

export default NotificationBadgeIcon;
