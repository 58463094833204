import { FC, ReactNode, useEffect, useRef } from 'react';

interface IntersectionObserverTriggerProps {
  onTrigger?: () => void;
  children?: ReactNode;
}

export const IntersectionObserverTrigger: FC<
  IntersectionObserverTriggerProps
> = ({ children, onTrigger }) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<(() => void) | null>(null);
  useEffect(() => {
    triggerRef.current = onTrigger ?? null;
  });

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };
    const observer = new IntersectionObserver((entries, obs) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          triggerRef.current?.();
        }
      });
    }, options);
    const target = targetRef.current;
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return <div ref={targetRef}> {children}</div>;
};
