import React, { FC } from 'react';

import {Header} from "../../../components/Header";
import {Layout} from "../../../components/Layout";
import styled from "styled-components";
import {PrivacyPolicy} from "../../../components/terms/PrivacyPolicy";
import {SamhwaBrandLogo} from "@assets/components/samhwa-brand-logo";


interface PrivacyPolicyScreenProps {}

export const PrivacyPolicyScreen : FC<PrivacyPolicyScreenProps> = () => {
  return (
      <Layout renderHeader={<Header hasBack title="개인정보 처리방침"   />} hideFooter>
          <div className="bg-white p-4 rounded shadow-lg max-w-full w-full h-full overflow-auto"
               onClick={e => e.stopPropagation()}>
              <div className="p-6">
                  <Container>
                      <h1 className="text-head font-bold mb-10">
                          개인정보 처리방침
                      </h1>
                      <Content className="p-2.5 rounded-px5 text-gray400 text-md font-medium">
                          <PrivacyPolicy/>
                          <SamhwaWrapper>
                              <SamhwaBrandLogo/>
                          </SamhwaWrapper>
                      </Content>
                  </Container>
              </div>
          </div>
      </Layout>
);
};

const SamhwaWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 50px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0;
  height: 100%;
  max-height: 100%;
`;
const Content = styled.div`
  height: 30rem;
`;





