import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Screens } from './screen';
import { ModalProvider } from './modal/context';
import { AuthProvider } from './auth';
import { useEffect } from 'react';
import { rnBridge } from 'utils/rn-bridge';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
    },
  },
});

function App() {
  useEffect(() => {
    rnBridge.webviewOnLoaded();
  }, []);
  return (
    <ModalProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Screens />
        </AuthProvider>
      </QueryClientProvider>
    </ModalProvider>
  );
}

export default App;
