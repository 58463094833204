import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { HomeIconColor } from './icons/HomeIconColor';
import { BrochureIcon } from '@assets/components/brochure-icon';
import { PromotionIcon } from '@assets/components/promotion-icon';
import { NoticeMegaphoneIcon } from '@assets/components/notice-megaphone-icon';

interface WhiteFooterProps {}

export const WhiteFooter: FC<WhiteFooterProps> = () => {
  return (
    <Container>
      <NavLink className="item" to="/home">
        <span className="icon">
          <HomeIconColor color="var(--icon-color)" />
        </span>
        <span className="label">홈</span>
      </NavLink>
      <NavLink className="item" to="/brochure">
        <span className="icon">
          <BrochureIcon color="var(--icon-color)" />
        </span>
        <span className="label">제품홍보</span>
      </NavLink>
      <NavLink className="item" to="/promotion">
        <span className="icon">
          <PromotionIcon color="var(--icon-color)" />
        </span>
        <span className="label">시공&홍보</span>
      </NavLink>
      <NavLink className="item" to="/newsnfaq">
        <span className="icon">
          <NoticeMegaphoneIcon
            color="var(--icon-color)"
            width="24"
            height="24"
          />
        </span>
        <span className="label">뉴스&FAQ</span>
      </NavLink>
    </Container>
  );
};

const Container = styled.nav`
  display: flex;
  padding: 4px 20px;
  align-items: center;
  background-color: var(--grayscale-white);
  border-top: var(--grayscale-100) solid 1px;
  height: 54px;

  .item {
    height: 44px;
    flex: 1;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    margin-right: 10px;
  }
  .item .label {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: var(--grayscale-300);
  }
  .item.active .label {
    color: var(--grayscale-black);
  }
  .item:last-child {
    margin-right: 0;
  }

  .faIcon {
    display: block;
    width: 20px;
    height: 20px;
    margin: 4px 2px 0;
    --icon-color: var(--grayscale-300);
  }
  .item.active .faIcon {
    --icon-color: var(--grayscale-black);
  }

  .icon {
    display: block;
    width: 24px;
    height: 24px;
    margin-bottom: 2px;
    --icon-color: var(--grayscale-300);
  }
  .item.active .icon {
    --icon-color: var(--grayscale-black);
  }
`;
