import { FC, useCallback, useEffect, useMemo } from 'react';
import { useModalContext } from '../modal/context';
import { isRn } from '../utils/rn-utils';
import { logger } from '../utils/logger';
import { checkMobile } from '../utils/checkMobile';
import { userApis } from '../apis/user.apis';
import { useNavigate } from 'react-router-dom';
import { httpClient } from '../apis/http-client';
import {useAuthData, useAuthDispatch} from '../auth';
import { rnBridge } from 'utils/rn-bridge';
import {NoticeTopic} from "../constants/notice-topic";

export const RnEventHandler: FC = () => {
  const modalDispatch = useModalContext();
  const authDispatch = useAuthDispatch();
  const authData = useAuthData();
  const isLogin = useMemo(() => {
    return !!authData.user;
  }, [authData.user]);

  const navigate = useNavigate();
  const handleMessage = useCallback(
    (event: any) => {
      if (!event?.data) {
        return;
      }
      const { payload, type } = JSON.parse(event.data);
      switch (type) {
        case 'alert':
          modalDispatch.openModal({
            type: 'alert',
            title: payload.title,
            text: payload.text,
          });
          break;
        case 'loading': {
          modalDispatch.openModal({
            type: 'loading',
            title: payload.title,
            text: payload.text,
          });
          break;
        }
        case 'modalUpdate': {
          modalDispatch.updateModal(
            {
              type: payload.type,
              title: payload.title,
              text: payload.text,
            },
            payload.opts ?? {}
          );
          break;
        }
        case 'download-start': {
          modalDispatch.openModal(
            {
              type: 'loading',
              title: '',
              text: '파일 다운로드 진행중',
            },
            {
              closeTypes: 'disable',
            }
          );
          break;
        }
        case 'download-done': {
          modalDispatch.updateModal(
            {
              type: 'alert',
              text: '',
              title: `${payload.name} (을)를\n다운로드 하였습니다.`,
            },
            {
              closeTypes: 'justclose',
              onClose: () => rnBridge.fileOpen(payload.path),
            }
          );
          break;
        }
        case 'res-token':
          if (isLogin) {
            userApis
              .updateFcmToken(payload.token)
              .then(() => {
                logger('token registered');
              })
              .catch(() => {
                logger('token register failed');
              });
          }
          break;
        case 'res-notification-enabled':
          if (isLogin) {
            userApis
                .updateNotificationEnabled(payload.isGranted)
                .then(() => {
                  authDispatch.update({ service_notification_enabled: payload.isGranted});
                  if(payload.isGranted){
                    rnBridge.subscribeFCMTopic(NoticeTopic);
                  }else{
                    rnBridge.unsubscribeFCMTopic(NoticeTopic);
                  }
                })
                .catch(() => {
                  logger('notification enabled register failed');
                });
          }
          break;
        case 'res-allow-notification':
          if (isLogin) {
            authDispatch.update({ device_notification_granted: payload.isEnabled});
          }
          break;
        case 'navigate':
          if (isLogin) {
            setTimeout(() => navigate(payload.to), 300);
          }
          break;
        case 'view-increase':
          logger(payload);
          payload.url &&
            httpClient
              .put(payload.url)
              .then(() => {
                logger('done');
              })
              .catch(() => {
                logger('failed');
              });
          break;
        default:
          logger('UNKNOWN_EVENT: type', type, 'payload', payload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalDispatch, isLogin]
  );
  const addRnMessageListener = useCallback((func: any) => {
    const device = checkMobile();
    if (device === 'android') {
      document.addEventListener('message', func);
    } else if (device === 'ios') {
      window.addEventListener('message', func);
    }
    return () => {
      if (device === 'android') {
        document.removeEventListener('message', func);
      } else if (device === 'ios') {
        window.removeEventListener('message', func);
      }
    };
  }, []);
  useEffect(() => {
    if (isRn()) {
      const resolver = addRnMessageListener(handleMessage);
      return resolver;
    }
  }, [addRnMessageListener, handleMessage]);

  return null;
};
