import { httpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

async function requestSendSms(phone_number: string) {
  try {
    const res = await httpClient.post('/sms/request-code', { phone_number });
    return res.data as { _id: string };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function verifyCode(id: string, phone_number: string, code: string) {
  try {
    const res = await httpClient.post('/sms/verify-code', { id, phone_number, code });
    return res.data as {
      valid: boolean;
      message: string;
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}


export const smsApis = {
  requestSendSms,
  verifyCode
};
