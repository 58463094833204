import { TFeedListItem } from '../types/feed-list-item.type';
import { httpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

function updateViewGenerator(type: string) {
  return async function (id: string) {
    try {
      await httpClient.put(`/feed/${type}/${id}/view`);
    } catch (e: any) {
      throw ErrorToHttpError(e);
    }
  };
}

function getLinkGenerator(type: string) {
  return async function (id: string) {
    try {
      const response = await httpClient
        .get(`/feed/${type}/${id}/link`)
        .then((res) => res.data as { link: null | string });
      return response;
    } catch (e: any) {
      throw ErrorToHttpError(e);
    }
  };
}

async function getList(kind: string, page: number) {
  try {
    const response = await httpClient
      .get(`/feed/${kind}?page=${page}`)
      .then((res) => {
        return res.data as {
          total_count: number;
          rows: {
            _id: string;
            type: string; // 영상 블로그
            text: string;
            thumbnail: {
              url: string;
            } | null;
            link: string;
            created_at: string;
          }[];
          page: number;
          page_size: number;
        };
      });
    return {
      ...response,
      rows: response.rows.map(
        (v) =>
          ({
            ...v,
            created_at: new Date(v.created_at),
          } as TFeedListItem)
      ),
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function updateView(id: string) {
  try {
    await httpClient.put(`/feed/${id}/view`);
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function getLink(id: string) {
  try {
    const response = await httpClient
      .get(`/feed/${id}/link`)
      .then((res) => res.data as { link: null | string });
    return response;
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

export const feedApis = {
  updateViewGenerator,
  getLinkGenerator,
  getList,
  updateView,
  getLink,
};
