import { Header } from 'components/Header';
import { Layout } from 'components/Layout';
import { FC } from 'react';
import { BoardList } from './components/BoardList';
import {useLocation, useNavigate} from "react-router-dom";

interface NoticeListScreenProps {}

export const NoticeListScreen: FC<NoticeListScreenProps> = () => {
    const location = useLocation();
    const nav = useNavigate();
    const { state } = location as { state: { page?: number } };
    const page = state?.page || 1;
    const handleBack = () => {
        nav('/user/setting', );
    };
    return (
    <Layout renderHeader={<Header hasBack title="공지사항"  onBack={handleBack}/>} hideFooter>
      <BoardList vPage={page} />
    </Layout>
  );
};
