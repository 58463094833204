import { TImage } from '../types/image.type';
import { httpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

async function getAll() {
  try {
    const response = await httpClient.get('/banner').then((res) => {
      return res.data as {
        total_count: number;
        rows: {
          _id: string;
          image: TImage;
          created_at: string;
        }[];
      };
    });
    return {
      ...response,
      rows: response.rows.map((v) => ({
        ...v,
        created_at: new Date(v.created_at),
      })),
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

export const bannerApis = { getAll };
