import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {FieldErrorMessage} from "../../../components/FieldErrorMessage";
import {Button} from "../../../components/Button";
import {smsApis} from "../../../apis/sms.apis";
import {HttpError} from "../../../apis/http-error";
import {useModalContext} from "../../../modal/context";
import {verifySmsYup} from "../../../validation/verifySms.yup";
import {verifyCodeYup} from "../../../validation/verifyCode.yup";
import CountdownTimer from './timer/CountDownTimer';

interface SendSmsValues {
    phone_number: string;
}
interface VerifyCodeValues {
    code: string;
}

interface PhoneVerificationProps{
    onNext: (phone_number?: string) => void;
    phone_number?: string;
}

const PhoneVerification: React.FC<PhoneVerificationProps> = ({ onNext, phone_number }) => {
    const modal = useModalContext();
    const [timerStart, setTimerStart] = useState<boolean>(false);
    const [showVerifyCode, setShowVerifyCode] = useState(false);
    const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState<boolean>(true);
    const [isRequestButtonDisabled, setIsRequestButtonDisabled] = useState<boolean>(false);
    const [verifyInfo, setVerifyInfo] = useState<null | {
        id: string;
        phone_number: string;
    }>(null);

    useEffect(() => {
        let disableButtonTimeout: NodeJS.Timeout;
        if (isRequestButtonDisabled) {
            disableButtonTimeout = setTimeout(() => {
                setIsRequestButtonDisabled(false);
            }, 60000); // 1분 = 60000ms
        }

        return () => clearTimeout(disableButtonTimeout);
    }, [isRequestButtonDisabled]);

    const sendSmsFormik = useFormik<SendSmsValues>({
        initialValues: {
            phone_number: phone_number || ''
        },
        onSubmit: async (values, helper) => {
            try {
                if(isRequestButtonDisabled){
                    helper.setFieldError('phone_number','인증번호 재발송은 발송 1분 후에 가능합니다.');
                    return;
                }

                const { _id } = await smsApis.requestSendSms(values.phone_number);
                modal.openModal(
                    {
                        type: 'alert',
                        title: '인증 번호를 발송했습니다.',
                        text: '',
                    },
                    {
                    }
                );
                setShowVerifyCode(true);
                setTimerStart(false); // 타이머를 재설정하기 위해 false로 설정
                setTimeout(() => setTimerStart(true), 0); // 짧은 지연 후 true로 설정
                setIsVerifyButtonDisabled(false);
                setIsRequestButtonDisabled(true);
                setVerifyInfo({
                    id: _id,
                    phone_number: values.phone_number,
                });

            } catch (e: any) {
                setVerifyInfo(null);
                const error = e as HttpError;
                if (error.code === 'phone_number') {
                    helper.setFieldError(error.code, error.message);
                } 
            } finally {
                helper.setSubmitting(false);
            }
        },
        validationSchema: verifySmsYup,
        validateOnBlur: true,
        validateOnChange: true,
    });

    const verifyCodeFormik = useFormik<VerifyCodeValues>({
        initialValues: {
            code : ""
        },
        onSubmit: async (values, helper) => {
            try {
                const { message, valid } =  await smsApis.verifyCode(verifyInfo!.id,sendSmsFormik.values.phone_number, values.code);
                if (valid) {
                    onNext(sendSmsFormik.values.phone_number);
                } else {
                    helper.setFieldError('code', message);
                }

            } catch (e: any) {
                const error = e as HttpError;
                modal.openModal({ title: error.message, text: '', type: 'alert' });
            } finally {
                helper.setSubmitting(false);
            }
        },
        validationSchema: verifyCodeYup,
        validateOnBlur: true,
        validateOnChange: true,
    });

    return (
        <div>
            <PagingNumber >
                2/3
            </PagingNumber>
            <h1 className="text-head font-bold mb-10">휴대전화 번호를<br/>입력해 주세요.</h1>
            <FormikProvider value={sendSmsFormik}>
                <Form className="mt-1.5"  onSubmit={sendSmsFormik.handleSubmit} >
                    <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <Field
                            id="phone_number"
                            name="phone_number"
                            className="input mr-2"
                            placeholder="-없이 숫자만 입력"
                            type="tel"
                            maxLength={11}
                            onInput={(e: { target: { value: string; }; }) => {
                                e.target.value = e.target.value.replace(/\D/g, '').slice(0, 11);
                            }}
                        />
                        <RequestButton type="submit" disabled={sendSmsFormik.isSubmitting}>
                            {verifyInfo ? '재발송' : '인증요청'}
                        </RequestButton>
                    </div>
                    <FieldErrorMessage name="phone_number"/>
                </Form>
            </FormikProvider>
            <FormikProvider value={verifyCodeFormik}>
                <Form className="mt-1.5" onSubmit={verifyCodeFormik.handleSubmit} >
                    { showVerifyCode ? (
                        <div>
                        <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                            <Field
                                id="code"
                                name="code"
                                className="input mr-2"
                                placeholder="인증번호 6자리 입력"
                                type="text"
                                maxLength={6}
                                onInput={(e: { target: { value: string; }; }) => {
                                    e.target.value = e.target.value.replace(/\D/g, '').slice(0, 6);
                                }}
                            />
                            <CountdownTimer start={timerStart} setFieldError={verifyCodeFormik.setFieldError} />
                        </div>
                        <FieldErrorMessage name="code"/>
                        </div>
                        ) : null}
                        <div style={{marginTop: 30}}>
                            <Button type="submit" disabled={isVerifyButtonDisabled || verifyCodeFormik.isSubmitting} >인증하기</Button>
                        </div>
                </Form>
            </FormikProvider>
        </div>
    );
};

export default PhoneVerification;


const PagingNumber = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 30px 30px;
`;

const RequestButton = styled.button`
    position: absolute;
    top: 6.5px;
    right: 18px;
    padding: 10px;
    background-color: var(--grayscale-white);
    color: var(--grayscale-black);
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 80px;
    height: 40px;
    font-size: 14px;
`;
