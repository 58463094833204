import { useEffect, useRef } from 'react';
import { useAuthData } from '../auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { NoticeTopic } from 'constants/notice-topic';
import { rnBridge } from 'utils/rn-bridge';

export function useTermScreen() {
  const authData = useAuthData();
  const loginCheck = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (authData.user && !loginCheck.current) {
      const { state: routeState } = location;
      loginCheck.current = true;
      let to = '/home';
      if (routeState?.from) {
        to = routeState.from;
      }
      if (!authData.user.service_term_agree || !authData.user.privacy_policy_agree) {
        to = '/terms';
        if (!!routeState?.from) {
          navigate(-1);
        }
      } else {
        if(authData.user.service_notification_enabled){
          rnBridge.subscribeFCMTopic(NoticeTopic);
        }
      }

      setTimeout(() => {
        navigate(to, { replace: true });
      }, 100);
    } else if (!authData.user) {
      loginCheck.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData]);
}
