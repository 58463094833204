import styled from 'styled-components';

type BtnType = 'primary' | 'secondary';

function getBackgroundColor(type?: BtnType) {
  const theme = type ?? 'primary';
  if (theme === 'primary') {
    return '--grayscale-black';
  }
  return '--grayscale-200';
}

function getDisabledBackgroundColor(type?: BtnType) {
  const theme = type ?? 'primary';
  if (theme === 'primary') {
    // return '--grayscale-black';
    return '--grayscale-300';
  }
  return '--grayscale-100';
}

function getColor(type?: BtnType) {
  const theme = type ?? 'primary';
  if (theme === 'primary') {
    return '--grayscale-white';
  }
  return '--grayscale-black';
}

export const Button = styled.button<{ btnType?: BtnType }>`
  display: block;
  width: 100%;
  background-color: var(${(props) => getBackgroundColor(props.btnType)});
  color: var(${(props) => getColor(props.btnType)});
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -3%;
  font-weight: 600;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    background-color: var(
      ${(props) => getDisabledBackgroundColor(props.btnType)}
    );
    cursor: auto;
  }
`;
