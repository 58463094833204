import { httpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

async function updatePassword(current: string, next: string) {
  try {
    await httpClient.put('/users/pw', { password: current, nextPassword: next });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function updateFcmToken(token: string) {
  try {
    await httpClient.put('/users/fcm', { token });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function agreeServiceTerm() {
  try {
    await httpClient.put('/users/term');
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function requestFindUsernameCode(name:string, phone_number:string){
  try {
    const res = await httpClient.post('/users/username-recovery', { name, phone_number });
    return res.data as { _id: string };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function verifyFindUsernameCode(id:string, name:string, phone_number:string, code: string){
  try {
    const res = await httpClient.post('/users/verify-username-recovery-code', { id, name, phone_number, code });
    return res.data as {
      valid: boolean;
      message: string;
      user: {
        username:string,
        name:string,
      };
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function requestFindPasswordCode(username:string, name:string, phone_number:string){
  try {
    const res = await httpClient.post('/users/password-recovery', { username, name, phone_number });
    return res.data as { _id: string };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function verifyFindPassowrdCode(id:string, username:string, name:string, phone_number:string, code: string){
  try {
    const res = await httpClient.post('/users/verify-password-recovery-code', { id, username, name, phone_number, code });
    return res.data as {
      valid: boolean;
      message: string;
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function removeUser() {
  try {
  const res = await httpClient.delete('/users');
  return res.data as {
    id: string
  }
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function updateNotificationEnabled(service_notification_enabled:boolean){
  try {
    await httpClient.put('/users/notification', {service_notification_enabled });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

export const userApis = { updatePassword, updateFcmToken, agreeServiceTerm, requestFindUsernameCode, verifyFindUsernameCode, requestFindPasswordCode, verifyFindPassowrdCode, removeUser, updateNotificationEnabled };
