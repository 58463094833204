import * as yup from 'yup';
import { passwordValidate } from './password.validate';
import {newPasswordValidate} from "./newPassword.validate";
import {newPasswordConfirmValidate} from "./newPasswordConfirm.validate";

export const userPasswordEditYup = yup.object().shape({
  current: passwordValidate,
  password: newPasswordValidate,
  passwordConfirm: newPasswordConfirmValidate
});
