import { FC } from 'react';
import styled from "styled-components";
import {PiCaretDoubleLeftThin} from "react-icons/pi";

interface DoubleLeftPaginationIcon {}

export const DoubleLeftPaginationIcon: FC<DoubleLeftPaginationIcon> = () => {
  return (
      <IconContainer>
          <PiCaretDoubleLeftThin  />
      </IconContainer>
  );
};

const IconContainer = styled.div`
    font-size: 25px;
`;