import classNames from 'classnames';
import { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';

interface TagNavigatorProps {
  items: { label: string; value: string }[];
  value?: { label: string; value: string };
  onChange?: (next: { label: string; value: string }) => void;
}

export const TagNavigator: FC<TagNavigatorProps> = ({
  items,
  value,
  onChange,
}) => {
  const changeTabHandler =
    (next: {
      label: string;
      value: string;
    }): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      const target = e.target as HTMLButtonElement;
      const parent = target.parentElement;
      if (!onChange) {
        return;
      }
      onChange(next);
      if (parent) {
        parent.scroll({ left: target.offsetLeft - 20, behavior: 'smooth' });
      }
    };

  return (
    <TabWrapper>
      {items.map((v) => (
        <Tab
          className={classNames({ active: v.value === value?.value })}
          type="button"
          onClick={changeTabHandler(v)}
          key={v.value}
        >
          {v.label}
        </Tab>
      ))}
    </TabWrapper>
  );
};

const TabWrapper = styled.nav`
  background-color: var(--grayscale-white);
  height: 48px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0 20px;
  z-index: 1;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  position: sticky;
  top: -1px;
  left: 0;
  right: 0;
  & > * {
    margin-right: 8px;
  }
  & > *:last-child {
    margin-right: 0px;
  }
`;
const Tab = styled.button`
  display: block;
  white-space: nowrap;
  position: relative;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  background-color: var(--grayscale-100);
  color: var(--grayscale-400);
  border-radius: 999px;
  &.active {
    background-color: var(--grayscale-black);
    color: var(--grayscale-white);
  }
`;
