import { FC, useEffect, useMemo, useState } from 'react';
import { TBrochureDetailItem } from '../types/brochure-detail-item.type';
import styled from 'styled-components';
import { Document, Page } from 'react-pdf';
import { LoadingIndicator } from './LoadingIndicator';

interface BrochureDetailPdfProps extends TBrochureDetailItem {}

export const BrochureDetailPdf: FC<BrochureDetailPdfProps> = ({
  _id,
  file_url: fileUrl,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const pages = useMemo(() => {
    const total = numPages ?? 0;
    return new Array(total).fill(0).map((v, i) => i + 1);
  }, [numPages]);
  const [pageWidth, setPageWidth] = useState(0);
  fileUrl = useMemo(() => {
    return `/apis/c/brochure/${_id}/pdf`;
  }, [_id]);

  useEffect(() => {
    function setBrowserWidth() {
      setPageWidth(window.innerWidth - 40);
    }
    document.addEventListener('resize', setBrowserWidth);
    window.addEventListener('resize', setBrowserWidth);
    setBrowserWidth();
    return () => {
      document.removeEventListener('resize', setBrowserWidth);
      window.addEventListener('resize', setBrowserWidth);
    };
  }, []);

  return (
    <div>
      <Document
        file={fileUrl}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        loading={
          <LoadingWrapper>
            <LoadingIndicator />
          </LoadingWrapper>
        }
        error={
          <ErrorContainer>
            <ErrorParagraph>파일이 PDF가 아니거나</ErrorParagraph>
            <ErrorParagraph>파일을 불러올 수 없습니다.</ErrorParagraph>
          </ErrorContainer>
        }
      >
        {pages.map((v) => (
          <Page
            width={pageWidth}
            pageNumber={v}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            key={`${v}-${pageWidth}px-page`}
            loading={
              <LoadingWrapper>
                <LoadingIndicator />
              </LoadingWrapper>
            }
            error={
              <ErrorContainer>
                <ErrorParagraph>페이지를 불러올 수 없습니다.</ErrorParagraph>
              </ErrorContainer>
            }
          />
        ))}
      </Document>
    </div>
  );
};

const LoadingWrapper = styled.div`
  padding: 100px 20px;
  text-align: center;
`;

const ErrorContainer = styled.div`
  padding: 100px 0;
`;
const ErrorParagraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 4px;
`;
