import React from 'react';
import {Button} from "../Button";
import {IoClose} from "react-icons/io5";
import {SamhwaBrandLogo} from "@assets/components/samhwa-brand-logo";
import styled from "styled-components";
interface TermsModalProps {
    show: boolean;
    onClose: (type:string, isAgree:boolean) => void;
    content: React.ReactNode;
    type:string;
    title: string;
}

const TermsModal: React.FC<TermsModalProps> = ({ show, onClose, content, type, title}) => {
    if (!show) {
        return null;
    }

    return (
        <div className="fixed inset-0 flex flex-col h-screen justify-between">
            <div className="bg-white p-4 rounded shadow-lg max-w-full w-full h-full overflow-auto"
                 onClick={e => e.stopPropagation()}>
                <button className="absolute top-4 right-4 text-2xl text-gray-600" onClick={()=>onClose(type, false)}>
                    <IoClose className="w-6 h-6"/>
                </button>
                <div className="p-6">
                    <Container>
                        <h1 className="text-head font-bold mb-10">
                            {title}
                        </h1>
                        <Content className="bg-white border border-gray100 overflow-auto p-2.5 rounded-px5 text-gray400 text-sm font-medium">
                            {content}
                        </Content>
                    </Container>
                    <div style={{marginTop: 30}}>
                        <Button onClick={()=>onClose(type,true)}>
                            확인
                        </Button>
                    </div>
                </div>
                <SamhwaWrapper>
                    <SamhwaBrandLogo/>
                </SamhwaWrapper>
            </div>
        </div>
    );
};

export default TermsModal;



const SamhwaWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-top: 50px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0;
  height: 100%;
  max-height: 100%;
`;
const Content = styled.div`
  height: 30rem;
`;
