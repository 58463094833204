import React, {useState} from 'react'
import styled from "styled-components";
import { Button } from 'components/Button';
import {Field, Form, FormikProvider, useFormik} from "formik";
import * as Yup from 'yup';
import { IoCheckboxOutline, IoCheckboxSharp } from 'react-icons/io5';
import {PrivacyPolicy } from '../../../components/terms/PrivacyPolicy';
import {TermsOfService} from '../../../components/terms/TermsOfService'
import TermsModal from "../../../components/terms/TermsModal";

interface AgreementProps {
    onNext:(phone_number?: string, terms_agree_at?: string, terms_of_service?:boolean, privacy_policy?:boolean) => void;
    terms_of_service?: boolean
    privacy_policy?: boolean,
}

const Agreement: React.FC<AgreementProps> = ({ onNext, terms_of_service, privacy_policy }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState<React.ReactNode>(null);
    const [modalTitle, setModalTitle] = useState('');
    const [termsType, setTermsType] = useState('');
    const formik = useFormik({
        initialValues: {
            allAgree: (terms_of_service && privacy_policy) ? true : false,
            termsOfService: terms_of_service || false,
            privacyPolicy: privacy_policy || false,
        },
        validationSchema: Yup.object({
            allAgree: Yup.boolean().oneOf([true], '필수 항목은 반드시 동의 되어야 합니다.'),
        }),
        onSubmit: (values) => {
            const terms_agree_at = new Date().toISOString();
            onNext(undefined,terms_agree_at, values.termsOfService, values.privacyPolicy);
        },
    });

    const handleAllAgreeChange = () => {
        const { allAgree } = formik.values;
        formik.setValues({
            allAgree: !allAgree,
            termsOfService: !allAgree,
            privacyPolicy: !allAgree,
        });
    };

    const handleIndividualChange = (field: string, value: boolean) => {
        formik.setFieldValue(field, value);

        const { termsOfService, privacyPolicy } = formik.values;

        // Check if both individual checkboxes are checked
        const allAgree = (field === 'termsOfService' ? value : termsOfService) &&
            (field === 'privacyPolicy' ? value : privacyPolicy);

        formik.setFieldValue('allAgree', allAgree);
    };

    const handleShowModal = (content: React.ReactNode, type:string, title:string) => {
        setTermsType(type);
        setModalContent(content);
        setShowModal(true);
        setModalTitle(title);
    };
    // 모달 닫기 함수
    const handleCloseModal = (type:string, isAgree:boolean) => {
        if(isAgree){
           if(type === 'privacyPolicy'){
               formik.values.privacyPolicy = true;
           }else if(type=== 'termsOfService'){
               formik.values.termsOfService = true;
           }

           if(formik.values.privacyPolicy &&  formik.values.termsOfService ){
               formik.values.allAgree = true;
           }
        }
        setShowModal(false);  // 모달을 닫기만 하고 폼 값을 변경하지 않음
    };
    return (
        <div>
                <PagingNumber >
                    1/3
                </PagingNumber>
                <h1 className="text-head font-bold mb-10">이용약관 동의</h1>
                <FormikProvider value={formik}>
                    <Form className="mt-1.5">
                        <div className="flex flex-col ">
                            <div className="flex items-center mb-5 px-2 py-2 cursor-pointer"
                                 style={{borderBottom: '1px solid var(--grayscale-100)'}}
                                 onClick={handleAllAgreeChange}>
                                {formik.values.allAgree ? <IoCheckboxSharp className="w-6 h-6 mr-2"/> :
                                    <IoCheckboxOutline className="w-6 h-6 mr-2"/>}
                                <Field
                                    type="checkbox"
                                    name="allAgree"
                                    checked={formik.values.allAgree}
                                    onChange={handleAllAgreeChange}
                                    className="hidden"
                                />
                                전체 동의하기
                            </div>
                            <div className="flex items-center mb-3 px-2 py-2 cursor-pointer">
                                <div className="flex items-center cursor-pointer w-full justify-between"
                                     onClick={() => handleIndividualChange('termsOfService', !formik.values.termsOfService)}>
                                    {formik.values.termsOfService ? <IoCheckboxSharp className="w-6 h-6 mr-2"/> :
                                        <IoCheckboxOutline className="w-6 h-6 mr-2"/>}
                                    <Field
                                        type="checkbox"
                                        name="termsOfService"
                                        checked={formik.values.termsOfService}
                                        onChange={() => formik.setFieldValue('termsOfService', !formik.values.termsOfService)}
                                        className="hidden"
                                    />
                                    <span className="flex-grow">[필수] 서비스 이용약관</span>
                                </div>
                                <button type="button" className="underline text-gray400 text-sm w-14"
                                        onClick={() => handleShowModal(<TermsOfService/>, 'termsOfService', '서비스 이용약관')}>
                                    내용보기
                                </button>
                            </div>
                            <div className="flex items-center mb-3 px-2 py-2 cursor-pointer">
                                <div className="flex items-center cursor-pointer w-full justify-between"
                                     onClick={() => handleIndividualChange('privacyPolicy', !formik.values.privacyPolicy)}>
                                    {formik.values.privacyPolicy ? <IoCheckboxSharp className="w-6 h-6 mr-2"/> :
                                        <IoCheckboxOutline className="w-6 h-6 mr-2"/>}
                                    <Field
                                        type="checkbox"
                                        name="privacyPolicy"
                                        checked={formik.values.privacyPolicy}
                                        onChange={() => formik.setFieldValue('privacyPolicy', !formik.values.privacyPolicy)}
                                        className="hidden"
                                    />
                                    <span className="flex-grow">[필수] 개인정보 수집 및 이용 동의</span>
                                </div>
                                <button type="button" className="underline text-gray400 text-sm w-14"
                                        onClick={() => handleShowModal(<PrivacyPolicy/>, 'privacyPolicy', '개인정보 수집 이용 동의')}>
                                    내용보기
                                </button>
                            </div>
                            {formik.touched.allAgree && formik.errors.allAgree ? (
                                <Message>{formik.errors.allAgree}</Message>
                            ) : <Message></Message>}
                        </div>
                        <ButtonWrapper>
                            <Button type="submit">
                                다음
                            </Button>
                        </ButtonWrapper>

                        {showModal && modalContent && (
                            <TermsModal show={showModal} onClose={handleCloseModal} content={modalContent} type={termsType} title={modalTitle}/>
                        )}
                    </Form>
                </FormikProvider>
        </div>
    );
};

export default Agreement;

const ButtonWrapper = styled.div`
  padding-top: 30px;
    margin-top: 20px;
`;
const Message = styled.p`
  color: #e20d3a;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  min-height: 18px;
`;

const PagingNumber= styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 30px 30px;
`;