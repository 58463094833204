import { rnBridge } from './rn-bridge';
import { isRn } from './rn-utils';

export function linkingSupport(link: string) {
  if (!isRn()) {
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.click();
  } else {
    rnBridge.linking(link);
  }
}
