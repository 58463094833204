import { useEffect, useState } from 'react';
import { authApis } from '../../apis/auth.apis';
import { AuthDispatch, User } from '../type';
import { NoticeTopic } from 'constants/notice-topic';
import { rnBridge } from 'utils/rn-bridge';

export function useAutoLogin(login: AuthDispatch['login']) {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const getAccessToken = await authApis.getAccessToken();
      if (getAccessToken === false) {
        return null;
      }
      const userinfo = await authApis.getMe({
        access_token: getAccessToken.access_token,
      });
      return {
        ...userinfo,
        access_token: getAccessToken.access_token,
      } as User;
    })()
      .then((res) => {
        if (!mounted) {
          return;
        }
        if (res) {
          login(res);
        } else {
          rnBridge.unsubscribeFCMTopic(NoticeTopic);
        }
      })
      .catch(() => {
        rnBridge.unsubscribeFCMTopic(NoticeTopic);
      })
      .finally(() => {
        mounted && setIsReady(true);
      });
    return () => {
      mounted = false;
    };
  }, [login]);
  return isReady;
}
