import { FC, useCallback, useMemo } from 'react';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';

import { useLocation, useNavigate } from 'react-router-dom';
import { TagNavigator } from 'components/TagNavigator';
import { NewsList } from './components/NewsList';
import { FAQList } from './components/FAQList';

interface NewsAndFAQScreenProps {}

type ListItem = {
  label: string;
  value: string;
};
const CATEGORY: ListItem[] = [
  { label: '뉴스', value: 'News' },
  { label: 'FAQ', value: 'FAQ' },
];

export const NewsAndFAQScreen: FC<NewsAndFAQScreenProps> = () => {
  const param = useLocation();
  const navigate = useNavigate();
  const category: ListItem = useMemo(() => {
    return param?.state?.category ?? CATEGORY[0];
  }, [param?.state?.category]);
  const setCategory = useCallback(
    (next: ListItem) => {
      navigate(param.pathname, {
        state: { category: next, page: 1 },
        replace: true,
      });
    },
    [navigate, param.pathname]
  );

  return (
    <Layout renderHeader={<Header title="뉴스&FAQ" />}>
      <div className="flex flex-col h-full relative">
        <div className="sticky top-0 z-10">
          <TagNavigator
            value={category}
            items={CATEGORY}
            onChange={setCategory}
          />
        </div>
        <div className="min-h-full">
          {category.value === 'News' ? <NewsList /> : null}
          {category.value === 'FAQ' ? <FAQList /> : null}
        </div>
      </div>
    </Layout>
  );
};
