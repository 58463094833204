import {FC, useEffect, useState} from 'react';
import {TBrochureListItem} from '../types/brochure-list-item.type';
import {useModalContext} from '../modal/context';
import {useQuery} from '@tanstack/react-query';
import {BrochureListItem} from './BrochureListItem';

import styled from 'styled-components';
import {LoadingIndicator} from './LoadingIndicator';
import {PaginationComponent} from './PaginationComponent';

interface BrochureListProps {
    type: string,
    category: string,
    queryFn: (
        page: number,
        category: string
    ) => Promise<{
        rows: TBrochureListItem[];
        total_count: number;
        page: number;
        page_size: number;
    }>,
    vPage: number,
}

export const BrochureList: FC<BrochureListProps> = ({
                                                        category,
                                                        queryFn,
                                                        type,
                                                        vPage,
                                                    }) => {
    const [page, setPage] = useState(vPage);
    const modalDispatch = useModalContext();
    useEffect(() => {
        setPage(vPage);
    }, [vPage, category]);
    const query = useQuery({
        queryKey: ['brochure-list', type, category, page],
        queryFn: () => queryFn(page, category),
        throwOnError(error) {
            modalDispatch.openModal({
                type: 'alert',
                title: '오류',
                text: error.message,
            });
            return false;
        },
        retry: false,
    });

    const rows = query.data?.rows ?? [];
    if (query.isLoading) {
        return (
            <div className="flex-1 flex justify-center items-center pb-12">
                <LoadingIndicator/>
            </div>
        );
    }

    if (rows.length === 0) {
        return (
            <div className="flex-1 flex items-center justify-center pb-12">
                <p className="text-lg font-semibold text-gray300">
                    {category === '전체'
                        ? '항목이 없습니다.'
                        : `${category} 항목이 없습니다.`}
                </p>
            </div>
        );
    }

    return (
        <div>
            <div className="py-2.5 px-5">
                {rows.length > 0 ? (
                    <ContentListContainer>
                        {rows.map((v) => {
                            return <BrochureListItem {...v} key={v._id} page={query.data?.page} fromScreen={'brochure'} vCategory={category} vType={type}/>;
                        })}
                    </ContentListContainer>
                ) : null}
            </div>
            <div className="py-12">
                <PaginationComponent
                    onClick={setPage}
                    page={query.data?.page}
                    page_size={query.data?.page_size}
                    total_count={query.data?.total_count}
                />
            </div>
        </div>
    );
};

const ContentListContainer = styled.div`
    & > * {
        margin-bottom: 24px;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }
`;
