import { FC } from 'react';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { boardApis } from '../../apis/board.apis';
import styled from 'styled-components';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { formatTimeAgo } from '../../utils/formatTimeAgo';

export const BoardDetailScreen: FC = () => {
  const params = useParams();
  const id = params.id ?? '';
  const enabled = !!id;
  const nav = useNavigate();
  const location = useLocation();
  const { state } = location as { state: { page?: number, fromScreen?: string } };
  const page = state?.page || 1;
  const fromScreen = state?.fromScreen;
  const handleBack = () => {
    if(fromScreen){
      nav(-1 );
    }else {
      nav('/user/setting/notice', {
        state: {
          page: page,
        },
      });
    }
  };
  const query = useQuery({
    queryKey: ['board-detail', id],
    queryFn: () => boardApis.getOne(id),
    enabled: enabled,
    retry: false,
  });
  const SHOW_NONE_INFO =
    enabled === false ||
    (enabled === true && query.isLoading === false && !query.data);
  const IS_LOADING = enabled === true && query.isLoading === true;
  const GET_DATA =
    enabled === true && query.isLoading === false && !!query.data;

  return (
    <Layout hideFooter renderHeader={<Header hasBack title="공지사항" onBack={handleBack} />}>
      {SHOW_NONE_INFO ? (
        <div className="flex justify-center items-center flex-col h-full pb-12">
          <p className="text-lg font-semibold text-gray300">
            공지사항이 삭제 되었거나,
          </p>
          <p className="text-lg font-semibold text-gray300">
            요청 정보가 잘못되었습니다.
          </p>
        </div>
      ) : null}
      {IS_LOADING === true ? (
        <div className="h-full flex justify-center items-center pb-12">
          <LoadingIndicator />
        </div>
      ) : null}
      {GET_DATA ? (
        <Container>
          <h2 className="text-lg font-semibold text-gray01">
            {query.data.title}
          </h2>
          <p className="text-sm font-medium text-gray300">
            {formatTimeAgo(query.data.created_at)}
          </p>
          {query.data.image ? (
            <img
              src={query.data.image.url}
              alt="메인이미지"
              className="w-full block mt-5"
            />
          ) : null}
          <p className="mt-5 whitespace-pre-line text-md text-gray400 font-medium">
            {query.data.content}
          </p>
        </Container>
      ) : null}
    </Layout>
  );
};

const Container = styled.div`
  padding: 20px;
`;
