import { TBrochureDetailItem } from '../types/brochure-detail-item.type';
import { TBrochureListItem } from '../types/brochure-list-item.type';
import { TImage } from '../types/image.type';
import { httpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

function getListGenerator(type: string) {
  return async (page: number, category: string) => {
    try {
      const response = await httpClient
        .get(`/brochure/${type}?page=${page}&category=${category}`)
        .then((res) => {
          return res.data as {
            total_count: number;
            rows: {
              _id: string;
              name: string;
              category: string;
              type: string;
              image: TImage;
              created_at: string;
            }[];
            page: number;
            page_size: number;
          };
        });
      return {
        ...response,
        rows: response.rows.map(
          (v) =>
            ({
              ...v,
              created_at: new Date(v.created_at),
            } as TBrochureListItem)
        ),
      };
    } catch (e: any) {
      throw ErrorToHttpError(e);
    }
  };
}
const getColorswatchList = getListGenerator('colorswatch');
const getCatalogList = getListGenerator('catalog');
const getDatabookList = getListGenerator('databook');

async function getOne(id: string): Promise<TBrochureDetailItem> {
  try {
    const response = await httpClient.get(`/brochure/${id}`);
    return response.data;
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function getDownloadPath(id: string): Promise<{
  filename: string;
  url: string;
}> {
  try {
    const response = await httpClient.get(`/brochure/${id}/download`);
    return response.data;
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function getRecently() {
  try {
    const response = await httpClient.get('/brochure/recently');
    const data = response.data as {
      rows: {
        _id: string;
        name: string;
        category: string;
        type: string;
        image: TImage;
        created_at: string;
      }[];
    };
    return {
      rows: data.rows.map(
        (v) =>
          ({
            ...v,
            created_at: new Date(v.created_at),
          } as TBrochureListItem)
      ),
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

export const brochureApis = {
  getOne,
  getDownloadPath,
  getColorswatchList,
  getCatalogList,
  getDatabookList,
  getRecently,
  getListGenerator,
};
