import { FC } from 'react';
import styled from "styled-components";
import {PiCaretLeftThin} from "react-icons/pi";

interface LeftPaginationIconProps {}

export const LeftPaginationIcon: FC<LeftPaginationIconProps> = () => {
  return (
      <IconContainer>
        <PiCaretLeftThin/>
      </IconContainer>
/*    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.6001 19.2L8.4001 12L15.6001 4.80001" stroke="#B5B5B7" />
    </svg>*/
  );
};

const IconContainer = styled.div`
    font-size: 25px;
`;