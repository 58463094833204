import { useQuery } from '@tanstack/react-query';
import { FC, useMemo } from 'react';
import { boardApis } from '../../../apis/board.apis';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { feedApis } from '../../../apis/feed.apis';
import { linkingSupport } from '../../../utils/linkingSupport';
import { useModalContext } from '../../../modal/context';
import { NoticeMegaphoneIcon } from '@assets/components/notice-megaphone-icon';
import { newsApis } from 'apis/news.apis';
import { faqApis } from 'apis/faq.apis';

interface RecentlyBoardProps {}

function getEndpoint(type: string) {
  switch (type) {
    case 'board':
      return '/board';
    case 'brochure':
      return '/brochure';
    default:
      return null;
  }
}
const ButtonContainer: FC<{
  title: string;
  _id: string;
  type: string;
  announce_at: string;
  children?: any;
}> = ({ _id, type, children }) => {
  const modalDispatch = useModalContext();
  const link = useMemo(() => {
    const endpoint = getEndpoint(type);
    if (endpoint === null) {
      return null;
    }
    return endpoint + '/' + _id;
  }, [_id, type]);

  const handleClick = async () => {
    try {
      const getLink =
        type === 'news'
          ? newsApis.getLink
          : type === 'faq'
          ? faqApis.getLink
          : feedApis.getLink;
      const res = await getLink(_id);
      if (res.link) {
        linkingSupport(res.link);
      } else {
        modalDispatch.openModal({
          type: 'alert',
          title: '알림',
          text: '해당 게시물의 링크 정보가 존재하지 않습니다.',
        });
      }
    } catch {
      modalDispatch.openModal({
        type: 'alert',
        title: '알림',
        text: '해당 게시물을 불러올 수 없습니다.',
      });
    }
  };

  if (link) {
    return (
      <Container as={Link} to={link} state={{fromScreen:'home'}}>
        {children}
      </Container>
    );
  }
  return (
    <Container type="button" onClick={handleClick}>
      {children}
    </Container>
  );
};
export const RecentlyBoard: FC<RecentlyBoardProps> = () => {
  const query = useQuery({
    queryKey: ['board', 'recently', 'one'],
    queryFn: boardApis.getRecently,
    retry: false,
    throwOnError: false,
  });

  if (!query.data) {
    return null;
  }
  return (
    <Relative>
      <ButtonContainer {...query.data}>
        <NoticeMegaphoneIcon width="24" height="24" />
        <p className="text-md font-medium text-gray-800 line-clamp-2">
          {query.data.title}
        </p>
      </ButtonContainer>
    </Relative>
  );
};

const Relative = styled.div`
  position: relative;
`;

const Container = styled.button`
  position: absolute;
  padding: 10px;
  bottom: 46px;
  left: 20px;
  right: 20px;
  background-color: #ffffff;
  z-index: 1;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  box-shadow: 0px 4px 4px 0px #00000026;
`;
