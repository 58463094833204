import { rnPostMessage } from './rn-utils';

export const rnBridge = {
  downloadStart: (opts: { filename: string; url: string; fromUrl: string }) =>
    rnPostMessage({ type: 'download', payload: opts }),
  webviewOnLoaded: () => rnPostMessage({ type: 'onLoaded', payload: {} }),
  requestRefreshFCM: () => rnPostMessage({ type: 'refresh-fcm', payload: {} }),
  subscribeFCMTopic: (topic: string) =>
    rnPostMessage({ type: 'subscribeTopic', payload: topic }),
  unsubscribeFCMTopic: (topic: string) =>
    rnPostMessage({ type: 'unsubscribeTopic', payload: topic }),
  requestFCMToken: () => rnPostMessage({ type: 'req-token', payload: {} }),
  requestInitNotifee: () =>
    rnPostMessage({ type: 'req-init-notifee', payload: {} }),
  appQuit: () => rnPostMessage({ type: 'quit', payload: {} }),
  linking: (url: string) =>
    rnPostMessage({ type: 'web-linking', payload: { url } }),
  fileOpen: (url: string) =>
    rnPostMessage({ type: 'file-open', payload: { url } }),
  requestNotificationEnabled: () => rnPostMessage({ type: 'req-notification-enabled', payload: {} }),
  requestAllowNotification: (isEnabled:boolean) => rnPostMessage({ type: 'req-allow-notification', payload: isEnabled }),
};
