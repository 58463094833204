import { FC } from 'react';

interface BrochureIconProps {
  color?: string;
}

export const BrochureIcon: FC<BrochureIconProps> = ({ color = '#202020' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5853 9.47369H9.099C8.63415 9.47369 8.22434 9.77861 8.09076 10.2239L6.73686 14.7369L5.89739 17.9697C5.64956 18.9241 4.93548 19.6882 4 20H18.0589C18.5238 20 18.9336 19.6951 19.0672 19.2498L21.5935 10.8288C21.7961 10.1534 21.2904 9.47369 20.5853 9.47369Z"
        fill={color}
      />
      <path
        d="M2 17.7403C2 18.407 2.54045 18.9474 3.20713 18.9474C3.7402 18.9474 4.21016 18.5977 4.36334 18.0872L6.81306 9.92142C7.08021 9.03092 7.89984 8.4211 8.82954 8.4211H19.8947C19.8947 7.25839 18.9522 6.31583 17.7895 6.31583H12.3457C11.7874 6.31583 11.2519 6.09403 10.8571 5.69922L9.98504 4.82719C9.59022 4.43237 9.05474 4.21057 8.49639 4.21057H4.10526C2.94256 4.21057 2 5.15313 2 6.31583V17.7403Z"
        fill={color}
      />
    </svg>
  );
};
