import { newsApis } from 'apis/news.apis';
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { TNewsListItem } from 'types/new-list-item.type';
import { formatTimeAgo } from 'utils/formatTimeAgo';
import { linkingSupport } from 'utils/linkingSupport';

interface NewsListItemProps extends TNewsListItem {}

export const NewsListItem: FC<NewsListItemProps> = ({
  _id,
  created_at,
  title,
  image,
}) => {
  const handleGetLink = useCallback(() => {
    return newsApis.getLink(_id);
  }, [_id]);
  const handleClick = async () => {
    const result = await handleGetLink();
    if (result.link) {
      linkingSupport(result.link);
    }
  };
  return (
    <Container type="button" onClick={handleClick}>
      {image ? (
        <Thumbnail className="rounded-px5 border border-gray100" data={image} />
      ) : null}
      <h2 className="text-lg font-semibold text-gray800 mt-2.5 line-clamp-2">
        {title}
      </h2>
      <p className="text-sm font-medium text-gray300">
        {formatTimeAgo(created_at)}
      </p>
    </Container>
  );
};

const Container = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
`;
const Thumbnail = styled.div<{ data: string }>`
  width: 100%;
  position: relative;
  background-color: var(--grayscale-300);
  background-image: url('${(props) => props.data}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 320 / 180;
`;
