import { TBrochureListItem } from '../types/brochure-list-item.type';
import { TImage } from '../types/image.type';
import { httpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

async function getList(
  page: number,
  text: string,
  tab: string,
  category: string
) {
  try {
    const response = await httpClient
      .get(
        `/search?page=${page}&search=${text}&type=${tab}&category=${category}`
      )
      .then((res) => {
        return res.data as {
          total_count: number;
          rows: {
            _id: string;
            name: string;
            category: string;
            type: string;
            image: TImage;
            created_at: string;
          }[];
          page: number;
        };
      });
    return {
      ...response,
      rows: response.rows.map(
        (v) =>
          ({
            ...v,
            created_at: new Date(v.created_at),
          } as TBrochureListItem)
      ),
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

export const searchApis = { getList };
