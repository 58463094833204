import { FC, useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import { useInfiniteQuery } from '@tanstack/react-query';
import { notificationApis } from '../../apis/notification.apis';
import { useModalContext } from '../../modal/context';
import { NotificationListItem } from '../../components/NotificationListItem';
import { IntersectionObserverTrigger } from '../../components/IntersectionObserverTrigger';
import styled from 'styled-components';
import { LoadingIndicator } from '../../components/LoadingIndicator';

interface NotificationScreenProps {}

const EmptyText: FC = () => {
  return (
    <div className="empty flex-1 flex items-center justify-center">
      <p className="text-center text-lg font-semibold text-gray300">
        아직 새로운 알림이 없습니다.
      </p>
    </div>
  );
};

export const NotificationScreen: FC<NotificationScreenProps> = () => {
  const modalDispath = useModalContext();
  const notification = useInfiniteQuery({
    queryKey: ['notification'],
    queryFn: ({ pageParam = 1 }) => notificationApis.getList(pageParam),
    initialPageParam: 1,
    getNextPageParam: (page) => {
      if (page.rows.length === 25) {
        return page.page + 1;
      }
      return undefined;
    },
    throwOnError(error, query) {
      modalDispath.openModal({
        type: 'alert',
        title: '오류',
        text: error.message,
      });
      return true;
    },
  });

  const handleNextFetch = () => {
    if (
      notification.hasNextPage === true &&
      notification.isFetchingNextPage === false &&
      notification.isLoading === false
    ) {
      notification.fetchNextPage({});
    }
  };

  const rows = (notification.data?.pages ?? []).flatMap((v) => v.rows);

  useEffect(() => {
    const firstItemId = notification.data?.pages?.[0]?.rows?.[0]?._id;
    if (firstItemId) {
      localStorage.setItem('n_rect', firstItemId);
    }
  }, [notification.data?.pages]);

  const IS_LOADING = notification.isLoading;

  return (
    <Layout renderHeader={<Header title="알림" hasBack />} hideFooter>
      <ContentWrapper>
        {IS_LOADING ? (
          <div className="flex-1 flex justify-center items-center">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            {rows.length === 0 ? <EmptyText /> : null}
            {rows.map((v) => {
              return <NotificationListItem {...v} key={v._id} />;
            })}
            <IntersectionObserverTrigger onTrigger={handleNextFetch} />
            <MonthNoticeWrapper>
              <p className="text-sm font-medium text-gray300 text-center py-5">
                최근 30일동안의 알림만 확인하실 수 있습니다.
              </p>
            </MonthNoticeWrapper>
          </>
        )}
      </ContentWrapper>
    </Layout>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;
const MonthNoticeWrapper = styled.div`
  margin-top: 80px;
  .empty + & {
    margin-top: 0px;
  }
`;
