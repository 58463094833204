import { Header } from 'components/Header';
import { Layout } from 'components/Layout';
import { FC } from 'react';
import {Link} from "react-router-dom";

interface TermsListScreenProps {}

const routes = [
    { to: '/user/terms/terms-of-service', label: '서비스 이용약관' },
    { to: '/user/terms/privacy-policy', label: '개인정보 처리방침' },
];

export const TermsListScreen: FC<TermsListScreenProps> = () => {

    return (
        <Layout renderHeader={<Header hasBack title="약관 및 정책"  />} hideFooter>
            <div className="h-full flex flex-col pb-3 px-5 justify-start items-stretch">
                {routes.map((route) => (
                    <Link
                        key={route.to}
                        to={route.to}
                        className="flex items-center justify-between py-3 border-b border-b-gray100"
                    >
                    <span className="text-gray800 text-lg font-semibold">
                      {route.label}
                    </span>
                        <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.18936 10.065L5.08503 10.95L10.0615 5.99995L5.08 1.04995L4.18936 1.93495L8.28025 5.99995L4.18936 10.065Z"
                                fill="#B5B5B7"
                            />
                        </svg>
                    </Link>
                ))}
            </div>
        </Layout>
    );
};
