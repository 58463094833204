import { FC } from 'react';

interface SearchIconProps {
  width?: string;
  height?: string;
}

export const SearchIcon: FC<SearchIconProps> = ({
  height = '32',
  width = '32',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_337_7489)">
        <path
          d="M19.5 18H18.71L18.43 17.73C19.41 16.59 20 15.11 20 13.5C20 9.91 17.09 7 13.5 7C9.91 7 7 9.91 7 13.5C7 17.09 9.91 20 13.5 20C15.11 20 16.59 19.41 17.73 18.43L18 18.71V19.5L23 24.49L24.49 23L19.5 18ZM13.5 18C11.01 18 9 15.99 9 13.5C9 11.01 11.01 9 13.5 9C15.99 9 18 11.01 18 13.5C18 15.99 15.99 18 13.5 18Z"
          fill="#B5B5B7"
        />
      </g>
      <defs>
        <clipPath id="clip0_337_7489">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
