import { FC, useState } from 'react';
import { Button } from '../../components/Button';
import styled from 'styled-components';
import { FormikProvider, useFormik, Field } from 'formik';
import { authApis } from '../../apis/auth.apis';
import { HttpError } from '../../apis/http-error';
import { useAuthDispatch } from '../../auth';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import { refreshTokenStorage } from '../../utils/refreshTokenStorage';
import { loginYup } from '../../validation/login.yup';
import { FieldErrorMessage } from '../../components/FieldErrorMessage';
import { HideIcon } from '@assets/components/hide-icon';
import { SamhwaBrandLogo } from '@assets/components/samhwa-brand-logo';
import { Link } from 'react-router-dom';

interface LoginScreenProps {}

type LoginFormData = {
  username: string;
  password: string;
};

export const LoginScreen: FC<LoginScreenProps> = () => {
  const authDispatch = useAuthDispatch();
  const [hidePw, setHidePw] = useState(true);
  const toggleHide = () => {
    setHidePw((prev) => !prev);
  };
  const formik = useFormik<LoginFormData>({
    initialValues: {
      password: '',
      username: '',
    },
    onSubmit: async (body, helper) => {
      try {
        const user = await authApis.login(body.username, body.password);
        const { refresh_token, ...userData } = user;
        authDispatch.login(userData);
        refreshTokenStorage.set(refresh_token);
      } catch (e: any) {
        const err = e as HttpError;
        if (err.status !== 401) {
        } else if (err.code === 'username') {
          helper.setFieldError('username', err.message);
        } else if (err.code === 'password') {
          helper.setFieldError('password', err.message);
        }
      } finally {
        helper.setSubmitting(false);
      }
    },
    validationSchema: loginYup,
    validateOnBlur: false,
    validateOnChange: false,
  });
  return (
    <Layout hideFooter renderHeader={<Header hasBack />}>
      <Container>
        <h1 className="text-head font-bold mb-10">로그인</h1>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} className="mt-1.5">
            <div>
              <label
                className="block text-lg font-semibold text-gray800 mb-px7"
                htmlFor="username"
              >
                아이디
              </label>
              <Field
                id="username"
                name="username"
                className="input"
                placeholder="아이디를 입력해 주세요."
                type="text"
              />
              <FieldErrorMessage name="username" />
            </div>
            <div>
              <label
                className="block text-lg font-semibold text-gray800 mb-px7"
                htmlFor="password"
              >
                비밀번호
              </label>
              <div className="relative">
                <Field
                  id="password"
                  type={hidePw ? 'password' : ''}
                  name="password"
                  className="input pr-12"
                  placeholder="비밀번호를 입력해 주세요."
                />
                <button
                  type="button"
                  onClick={toggleHide}
                  className="absolute right-3 top-4"
                >
                  <HideIcon mode={hidePw ? 'hide' : 'visible'} />
                </button>
              </div>
              <FieldErrorMessage name="password" />
            </div>
            <ButtonWrapper>
              <Button type="submit" disabled={formik.isSubmitting}>
                로그인
              </Button>
              <div className="mt-2.5 flex justify-center items-center space-x-3">
                <Link
                    className="text-sm font-medium text-gray300"
                    to="/user/find"
                >
                  아이디/비밀번호 찾기
                </Link>
                <div
                    style={{width: 1, height: 14}}
                    className="bg-gray300"
                ></div>
                <Link
                    className="text-sm font-medium text-gray300"
                    to="/register"
                >
                  회원가입
                </Link>
              </div>
            </ButtonWrapper>
          </Form>
        </FormikProvider>
        <SamhwaWrapper>
          <SamhwaBrandLogo />
        </SamhwaWrapper>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 58px 20px 55px;
  height: 100%;
  max-height: 100%;
`;
const Form = styled.form`
  & > * + * {
    margin-top: 20px;
  }
`;
const ButtonWrapper = styled.div`
  padding-top: 30px;
`;

const SamhwaWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-top: 50px;
`;
