import { useField } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';

interface FieldErrorMessageProps {
  name: string;
}

export const FieldErrorMessage: FC<FieldErrorMessageProps> = ({ name }) => {
  const [, { error }] = useField(name);
  return <Message>{error}</Message>;
};

const Message = styled.p`
  color: #e20d3a;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  min-height: 18px;
`;
