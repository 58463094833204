import { useInfiniteQuery } from '@tanstack/react-query';
import { newsApis } from 'apis/news.apis';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useModalContext } from 'modal/context';
import { FC } from 'react';
import { NewsListItem } from './NewsListItem';
import { useInView } from 'react-intersection-observer';

interface NewsListProps {}

export const NewsList: FC<NewsListProps> = () => {
  const modalDispatch = useModalContext();
  const query = useInfiniteQuery({
    queryKey: ['News', 'getList'],
    queryFn: ({ pageParam = 1 }) => newsApis.getList(pageParam),
    throwOnError(error) {
      modalDispatch.openModal({
        type: 'alert',
        title: '오류',
        text: error.message,
      });
      return false;
    },
    retry: false,
    initialPageParam: 1,
    getNextPageParam(lastPage) {
      if (
        lastPage.page < Math.ceil(lastPage.total_count / lastPage.page_size)
      ) {
        return lastPage.page + 1;
      }
      return undefined;
    },
  });
  const [ref] = useInView({
    threshold: 0,
    onChange(inView) {
      if (inView && query.hasNextPage && !query.isFetchingNextPage) {
        query.fetchNextPage();
      }
    },
  });

  const pages = query.data?.pages ?? [];
  const rows = pages.flatMap((v) => v.rows ?? []);

  if (query.isLoading) {
    return (
      <div className="h-full flex justify-center items-center pb-12">
        <LoadingIndicator />
      </div>
    );
  }
  if (rows.length === 0) {
    return (
      <div className="h-full flex justify-center items-center pb-12">
        <p className="text-lg font-semibold text-gray300">
          등록된 뉴스가 없습니다.
        </p>
      </div>
    );
  }
  return (
    <div className="h-full">
      <div className="py-2.5 px-5 space-y-6">
        {rows.map((v) => {
          return <NewsListItem {...v} key={v._id} />;
        })}
        <div ref={ref} style={{ height: 20 }}></div>
      </div>
    </div>
  );
};
