import { FC } from 'react';

interface HideIconProps {
  mode?: 'visible' | 'hide';
}

export const HideIcon: FC<HideIconProps> = ({ mode = 'hide' }) => {
  if (mode === 'hide') {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1741 14.1135L3.70715 2.64648L2.64649 3.70714L6.90702 7.96767C6.72678 8.27231 6.62402 8.62444 6.62402 8.99996C6.62402 10.162 7.60794 11.1 8.82682 11.1C9.1831 11.1 9.5193 11.0198 9.81672 10.8774L14.1135 15.1741L15.1741 14.1135ZM4.05438 5.11511C2.57367 6.04833 1.41005 7.40318 0.75 8.99996C2.02028 12.073 5.15559 14.25 8.82692 14.25C10.1163 14.25 11.3396 13.9814 12.4392 13.4999L10.856 11.9167C10.2746 12.2852 9.57691 12.5 8.82692 12.5C6.80035 12.5 5.15559 10.932 5.15559 8.99996C5.15559 8.22506 5.42019 7.50872 5.86788 6.92862L4.05438 5.11511ZM15.0623 11.7259L15.0493 11.7386L12.4935 9.18275C12.4944 9.16592 12.4952 9.14906 12.4958 9.13217C12.4975 9.0883 12.4984 9.04423 12.4984 8.99997C12.4984 7.06797 10.8536 5.49997 8.82707 5.49997L8.81073 5.5L7.2052 3.89447C7.23196 3.88964 7.25877 3.88492 7.28562 3.88033C7.78582 3.79468 8.30094 3.74997 8.82707 3.74997C12.4984 3.74997 15.6337 5.92697 16.904 8.99997C16.4808 10.0239 15.8505 10.9483 15.0623 11.7259Z"
          fill="#B5B5B7"
        />
      </svg>
    );
  }
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82692 3.75C5.15559 3.75 2.02028 5.927 0.75 9C2.02028 12.073 5.15559 14.25 8.82692 14.25C12.4983 14.25 15.6336 12.073 16.9038 9C15.6336 5.927 12.4983 3.75 8.82692 3.75ZM8.82692 12.5C6.80035 12.5 5.15559 10.932 5.15559 9C5.15559 7.068 6.80035 5.5 8.82692 5.5C10.8535 5.5 12.4983 7.068 12.4983 9C12.4983 10.932 10.8535 12.5 8.82692 12.5ZM8.82692 6.9C7.60804 6.9 6.62413 7.838 6.62413 9C6.62413 10.162 7.60804 11.1 8.82692 11.1C10.0458 11.1 11.0297 10.162 11.0297 9C11.0297 7.838 10.0458 6.9 8.82692 6.9Z"
        fill="#B5B5B7"
      />
    </svg>
  );
};
