import { FC, useRef } from 'react';
import { TBrochureDetailItem } from '../types/brochure-detail-item.type';
import styled from 'styled-components';
import { useModalContext } from '../modal/context';
import { brochureApis } from '../apis/brochure.apis';
import { isRn } from '../utils/rn-utils';
import { HttpError } from '../apis/http-error';
import { useLocation } from 'react-router-dom';
import { rnBridge } from 'utils/rn-bridge';
import { BrochureDetailPdf } from './BrochureDetailPdf';

interface BrochureDetailPresenterProps extends TBrochureDetailItem {}

export const BrochureDetailPresenter: FC<BrochureDetailPresenterProps> = (
  props
) => {
  const location = useLocation();
  const { cover_image, name, type, category, date, description, _id } = props;
  const modalDispatch = useModalContext();
  const process = useRef(false);
  const handleDownload = () => {
    if (process.current === true) {
      return;
    }
    process.current = true;
    brochureApis
      .getDownloadPath(_id)
      .then((res) => {
        if (isRn()) {
          rnBridge.downloadStart({
            ...res,
            fromUrl: location.pathname,
          });
        } else {
          // 웹에서 하는 것이 아니기 때문에 신경쓰지 않아도됨.
          const a = document.createElement('a');
          a.href = res.url;
          a.target = '_blank';
          a.setAttribute('download', res.filename);
          document.body.appendChild(a);
          a.click();
          a.remove();
          modalDispatch.openModal({
            type: 'alert',
            title: `${name} 을(를)\n다운로드 하였습니다.`,
            text: '',
          });
        }
      })
      .catch((e: HttpError) => {
        modalDispatch.openModal({
          type: 'alert',
          title: '오류',
          text: e.message,
        });
      })
      .finally(() => {
        process.current = false;
      });
  };
  return (
    <div>
      <div style={{ paddingBottom: 80 }}>
        <ThumbnailImage data-src={cover_image.url} />
        <Container>
          <TitleArea>
            <div className="text-sm font-medium flex space-x-2.5 mb-2">
              <span className="block px-2 py-0.5 bg-red500 text-white rounded-px5">
                {type}
              </span>
              <span className="block px-2 py-0.5 bg-red100 text-red500 rounded-px5">
                {category}
              </span>
            </div>
            <h1 className="text-lg font-semibold">{name}</h1>
            <p className="text-sm font-medium text-gray300">{date}</p>
          </TitleArea>
          <Content>{description}</Content>
        </Container>
        <PDFWrapper>
          <BrochureDetailPdf {...props} />
        </PDFWrapper>
      </div>
      <div className="fixed inset-x-0 bottom-0 pt-1.5 bg-white">
        <button
          className="block w-full bg-black text-lg font-semibold text-white p-3 rounded-t-px20"
          type="button"
          onClick={handleDownload}
        >
          <span className="block" style={{ paddingTop: 3, paddingBottom: 3 }}>
            다운로드
          </span>
        </button>
      </div>
    </div>
  );
};
const Container = styled.div`
  padding: 20px;
`;

const TitleArea = styled.div`
  padding-bottom: 10px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--grayscale-100);
`;

const PDFWrapper = styled.div`
  padding: 0 20px 0;
`;

const Content = styled.p`
  white-space: pre-line;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -3%;
  color: var(--grayscale-800);
`;

const ThumbnailImage = styled.div<{ 'data-src': string }>`
  background-color: gray;
  width: 100%;
  position: relative;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${(item) =>
    item['data-src'] ? `background-image: url('${item['data-src']}');` : ''}

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;
