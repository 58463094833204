import { FC, useCallback, useMemo } from 'react';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import { FeedList } from './components/FeedList';
import { feedApis } from '../../apis/feed.apis';
import { useLocation, useNavigate } from 'react-router-dom';
import { TagNavigator } from 'components/TagNavigator';

interface FeedScreenProps {}

type ListItem = {
  label: string;
  value: string;
};
const CATEGORY: ListItem[] = [
  { label: '전체', value: 'total' },
  { label: '시공사례', value: 'construction' },
  { label: '홍보영상', value: 'promotion' },
];

export const FeedScreen: FC<FeedScreenProps> = () => {
  const param = useLocation();
  const navigate = useNavigate();
  const category: ListItem = useMemo(() => {
    return param?.state?.category ?? CATEGORY[0];
  }, [param?.state?.category]);
  const setCategory = useCallback(
    (next: ListItem) => {
      navigate(param.pathname, {
        state: { category: next },
        replace: true,
      });
    },
    [navigate, param.pathname]
  );

  return (
    <Layout renderHeader={<Header title="시공&홍보" />}>
      <div className="flex flex-col h-full relative">
        <div className="sticky top-0 z-10">
          <TagNavigator
            items={CATEGORY}
            onChange={setCategory}
            value={category}
          />
        </div>
        <div className="min-h-full">
          <FeedList type={category.value} queryFn={feedApis.getList} />
        </div>
      </div>
    </Layout>
  );
};
