import { ChangeEventHandler, FC, FormEventHandler, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackBtnIcon } from '@assets/components/back-btn-icon';

interface SearchHeaderProps {
  hasBack?: boolean;
  renderRightBtn?: ReactNode;
  inputText?: string;
  onChange?: (next: string) => void;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  title?: string;
}

export const SearchHeader: FC<SearchHeaderProps> = ({
  inputText = '',
  title = '',
  hasBack = false,
  renderRightBtn = null,
  onChange,
  onSubmit,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const next = e.target.value;
    onChange?.(next);
  };
  const nav = useNavigate();
  const handleBack = () => nav(-1);

  return (
    <Container onSubmit={onSubmit}>
      <BackArea type="button" onClick={handleBack} disabled={!hasBack}>
        {hasBack ? <BackBtnIcon /> : null}
        {title ? <Title>{title}</Title> : null}
      </BackArea>
      {!title ? (
        <div
          className="flex-1 flex relative self-stretch"
          style={{
            marginLeft: 5,
          }}
        >
          <Input
            type="text"
            value={inputText}
            onChange={handleChange}
            autoFocus
            placeholder="홍보물명을 입력해 주세요."
          />
        </div>
      ) : null}
      <div className="right flex items-center">{renderRightBtn}</div>
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  background-color: var(--grayscale-white);
  height: 52px;
  border-bottom: 1px solid var(--grayscale-100);
  align-items: center;
  padding: 0 20px;
  .right {
    margin-left: auto;
  }
`;

const Input = styled.input`
  border: none;
  position: absolute;
  inset: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  &::placeholder {
    color: var(--grayscale-300);
  }
  margin-right: 10px;
  outline: none;
  padding: 0;
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  margin-left: 5px;
`;

const BackArea = styled.button`
  display: flex;
  align-self: center;
  align-items: center;
`;
