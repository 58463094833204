export function isRn() {
  const rnWebview =
    // @ts-ignore;
    window?.webkit?.messageHandlers?.ReactNativeWebView ??
    // @ts-ignore;
    window?.ReactNativeWebView;
  return !!rnWebview;
}

export function rnPostMessage(data: { type: string; payload: any }) {
  // @ts-ignore;
  const rnWebview: any = window?.ReactNativeWebView;
  if (rnWebview) {
    const stringData = JSON.stringify(data);
    rnWebview.postMessage(stringData);
  }
}
