import { FC } from 'react';
import { Layout } from '../components/Layout';
import styled from 'styled-components';

export const Error404: FC = () => {
  const handleRestore = () => {
    window.location.href = '/';
  };
  return (
    <Layout hideFooter>
      <ErrorScreenContainer>
        <div style={{ flex: 1 }}></div>
        <ErrorParagraph>
          오류가 발생하였습니다.
          <br />
          아래 버튼을 눌러 앱을 복구하세요.
        </ErrorParagraph>
        <div style={{ flex: 1 }}></div>
        <ErrorRestoreButton type="button" onClick={handleRestore}>
          복구하기
        </ErrorRestoreButton>
      </ErrorScreenContainer>
    </Layout>
  );
};

const ErrorScreenContainer = styled.div`
  padding: 12px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const ErrorParagraph = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
`;

const ErrorRestoreButton = styled.button`
  display: block;
  width: 100%;
  background-color: var(--grayscale-black);
  color: var(--grayscale-white);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -3%;
  font-weight: 500;
  padding: 15px;
  border-radius: 5px;
`;
