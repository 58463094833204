import React, { useState, useEffect } from 'react';
import styled from "styled-components";

interface CountdownTimerProps {
    start: boolean;
    setFieldError: (field: string, message: string) => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ start, setFieldError} ) => {
    const [timeLeft, setTimeLeft] = useState<number>(180);

    useEffect(() => {
        if (start) {
            setTimeLeft(180); // 3분 = 180초
            setFieldError('code', '');
        }else{
            setTimeLeft(180);
        }
    }, [start, setFieldError]);


    useEffect(() => {
        if (timeLeft === 0 && start) {
            setFieldError('code', '인증번호 입력 유효시간이 만료되었습니다. 다시 시도해 주세요.');
            return;
        }

        if (timeLeft > 0 && start) {
            const intervalId = setInterval(() => {
                setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [timeLeft, start, setFieldError]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    return (
        <div>
            <Timer>{formatTime(timeLeft)}</Timer>
        </div>
    );
};

const Timer = styled.div`
    position: absolute;
    top: 6.5px;
    right: 18px;
    padding: 10px;
    color: #e20d3a;
    width: 80px;
    height: 40px;
    font-size: 14px;
    text-align: center;
`;


export default CountdownTimer;