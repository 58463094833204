import {FC} from 'react';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthDispatch } from 'auth';
import { useModalContext } from 'modal/context';

interface UserSettingScreenProps {}

const routes = [
  { to: '/user/setting/notice', label: '공지사항' },
  { to: '/user/setting/info', label: '계정 정보' },
  { to: '/user/setting/notification', label: '알림 설정' },
  { to: '/user/setting/terms', label: '약관 및 정책' },
];

export const UserSettingScreen: FC<UserSettingScreenProps> = () => {
  const { logout } = useAuthDispatch();
  const navigate = useNavigate();
  const modalDispatch = useModalContext();
  const handleLogout = () => {
    modalDispatch.openModal(
      { type: 'alert', title: '로그아웃 하시겠습니까?', text: '' },
      {
        buttonText: '예',
        onClose: () => {
          navigate('/', { replace: true });
          logout();
        },
      }
    );
  };

  const handleBack = () => {
      navigate('/', );
  };
  return (
    <Layout renderHeader={<Header hasBack title="내 정보" onBack={handleBack} />} hideFooter>
      <div className="h-full flex flex-col pb-3 px-5 justify-start items-stretch">
        {routes.map((route) => (
          <Link
            key={route.to}
            to={route.to}
            className="flex items-center justify-between py-3 border-b border-b-gray100"
          >
            <span className="text-gray800 text-lg font-semibold">
              {route.label}
            </span>
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.18936 10.065L5.08503 10.95L10.0615 5.99995L5.08 1.04995L4.18936 1.93495L8.28025 5.99995L4.18936 10.065Z"
                fill="#B5B5B7"
              />
            </svg>
          </Link>
        ))}
          <div className="flex space-x-3">
              <button
                  type="button"
                  onClick={handleLogout}
                  className="block mt-3 text-sm text-gray400 font-medium"
              >
                  로그아웃
              </button>
          </div>
          <div className="mt-auto p-4">
              <p className="text-sm text-gray400 font-medium text-right">1.0.1</p>
          </div>
      </div>
    </Layout>
  );
};
