import { Header } from 'components/Header';
import { Layout } from 'components/Layout';
import { FC, useState, useEffect, useCallback} from 'react';
import {ToggleButtonIcon} from "@assets/components/toggle-button-icon";
import {useAuthData, useAuthDispatch} from "../../../auth";
import {rnBridge} from "../../../utils/rn-bridge";
interface NotificationSettingScreenProps {}

const routes = [
    { label: '푸시 알림 받기' },
];

export const NotificationSettingScreen: FC<NotificationSettingScreenProps> = () => {
    const authData = useAuthData();
    const authDispatch = useAuthDispatch();
    const [serviceNotificationEnabled, setServiceNotificationEnabled] = useState(authData?.user?.service_notification_enabled ?? false);

    const updateNotificationSettings = useCallback(() => {
        if (authData.user?.service_notification_enabled !== authData.user?.device_notification_granted) {
            let newValue = authData.user?.service_notification_enabled ?? false;
            setServiceNotificationEnabled(newValue);
            authDispatch.update({ device_notification_granted: newValue });
        }
    }, [authData.user?.service_notification_enabled, authData.user?.device_notification_granted, setServiceNotificationEnabled, authDispatch]);

    useEffect(() => {
        updateNotificationSettings();
    }, [updateNotificationSettings]);

    const  handleToggle = (isEnabled: boolean) => {
        setServiceNotificationEnabled(isEnabled);
        rnBridge.requestAllowNotification(isEnabled);
    };
    return (
        <Layout renderHeader={<Header hasBack title="알림 설정"/>} hideFooter>
            <div className="h-full flex flex-col px-5 justify-start items-stretch">
                {routes.map((route) => (
                    <div
                        className="flex items-center justify-between py-1 border-b border-b-gray100"
                    >
                    <span className="text-gray800 text-lg font-semibold">
                      {route.label}
                    </span>
                      <ToggleButtonIcon isEnabled={serviceNotificationEnabled} onToggle={handleToggle}  />
                    </div>
                ))}
            </div>
        </Layout>
    );
};
