import { FC } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { HomeScreen } from './Home';
import { NotificationScreen } from './Notification';
import { SearchScreen } from './Search';
import { BrochureDetailScreen } from './Brochure/detail';
import { Error404 } from './404';
import { BoardDetailScreen } from './Board';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { LoginScreen } from './Login';
import { UserSettingScreen } from './UserSetting';
import { BrochureScreen } from './Brochure';
import { FeedScreen } from './Feed';
import { NewsAndFAQScreen } from './NewsAndFAQ';
import { RnEventHandler } from '../components/RnEventHandler';
import { Bootstrap } from '../components/Bootstrap';
import { TermScreen } from './Term';
import { NoticeListScreen } from './UserSetting/Notice';
import { UserInfoScreen } from './UserSetting/UserInfo';
import { PasswordEditScreen } from './UserSetting/UserInfo/EditPassword';
import RegisterScreen from './Register';
import FindUserScreen from'./FindUser'
import {TermsListScreen} from "./UserSetting/Terms";
import {TermsOfServiceScreen} from "./Term/termsOfService";
import {PrivacyPolicyScreen} from "./Term/privacyPolicy";
import {NotificationSettingScreen} from "./UserSetting/Notification";
import DeleteAccountScreen from "./UserSetting/UserInfo/DeleteAccount";
interface ScreensProps {}

export const Screens: FC<ScreensProps> = () => {
  return (
    <BrowserRouter>
      <RnEventHandler />
      <Bootstrap />
      <Routes>
        <Route element={<Navigate to="/home" replace />} path="/"></Route>
        <Route Component={HomeScreen} path="/home"></Route>
        <Route Component={LoginScreen} path="/login"></Route>
        <Route Component={RegisterScreen} path="/register"></Route>
        <Route Component={FindUserScreen} path="/user/find"></Route>
        <Route Component={FeedScreen} path="/promotion"></Route>
        <Route Component={NewsAndFAQScreen} path="/newsnfaq"></Route>

        <Route element={<ProtectedRoute />}>
          <Route Component={NotificationScreen} path="/notification"></Route>
          <Route Component={SearchScreen} path="/search"></Route>
          <Route Component={BrochureScreen} path="/brochure"></Route>
          <Route Component={BrochureDetailScreen} path="/brochure/:id"></Route>
          <Route Component={BoardDetailScreen} path="/board/:id"></Route>
          <Route Component={UserSettingScreen} path="/user/setting"></Route>
          <Route Component={UserInfoScreen} path="/user/setting/info"></Route>
          <Route
            Component={PasswordEditScreen}
            path="/user/setting/info/password"
          ></Route>
          <Route
              Component={DeleteAccountScreen}
              path="/user/setting/delete-account"
          ></Route>
          <Route
            Component={NoticeListScreen}
            path="/user/setting/notice"
          ></Route>
          <Route
              Component={NotificationSettingScreen}
              path="/user/setting/notification"
          ></Route>
          <Route
              Component={TermsListScreen}
              path="/user/setting/terms"
          ></Route>
        </Route>

        <Route element={<ProtectedRoute to="/home" />}>
          <Route Component={TermScreen} path="/terms" />
        </Route>

        <Route Component={TermsOfServiceScreen} path="/user/terms/terms-of-service" ></Route>
        <Route Component={PrivacyPolicyScreen} path="/user/terms/privacy-policy" ></Route>
        <Route Component={Error404} path="*"></Route>
      </Routes>
    </BrowserRouter>
  );
};
