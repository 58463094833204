import { FC } from 'react';

interface PromotionIconProps {
  color?: string;
}

export const PromotionIcon: FC<PromotionIconProps> = ({
  color = '#E20D3A',
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1001 5.89982C18.1001 5.33831 18.5553 4.88312 19.1168 4.88312H20.1335C20.695 4.88312 21.1502 5.33831 21.1502 5.89982V18.1002C21.1502 18.6618 20.695 19.1169 20.1335 19.1169H19.1168C18.5553 19.1169 18.1001 18.6618 18.1001 18.1002V5.89982Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.73814 3.73149C3.67405 3.80749 2.84961 4.69292 2.84961 5.75972V18.2403C2.84961 19.3071 3.67405 20.1925 4.73814 20.2685L14.9052 20.9947C16.0822 21.0788 17.0834 20.1466 17.0834 18.9665V5.03351C17.0834 3.85344 16.0822 2.9212 14.9052 3.00527L4.73814 3.73149ZM9.96652 13.0167H4.88301V14.0334H9.96652V13.0167ZM4.88301 15.0501H13.0166V16.0668H4.88301V15.0501ZM13.0166 17.0835H4.88301V18.1002H13.0166V17.0835Z"
        fill={color}
      />
    </svg>
  );
};
