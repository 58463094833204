import { TNotificationListItem } from '../types/notification-list-item.type';
import { httpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

async function getList(page = 1) {
  try {
    const response = await httpClient
      .get(`/notification?page=${page}`)
      .then((res) => {
        return res.data as {
          total_count: number;
          page: number;
          rows: {
            _id: string;
            content: string;
            action: string;
            payload: string;
            created_at: string;
          }[];
        };
      });
    return {
      ...response,
      rows: response.rows.map(
        (v) =>
          ({
            ...v,
            created_at: new Date(v.created_at),
          } as TNotificationListItem)
      ),
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function getRecentlyId() {
  try {
    const response = await httpClient.get('/notification/recently');
    return response.data as { badge: boolean };
  } catch {
    return { badge: false };
  }
}
async function updateRead(id: string) {
  try {
    await httpClient.patch(`/notification/read/${id}`);
  } catch {
    return;
  }
}

export const notificationApis = {
  getList,
  getRecentlyId,
  updateRead,
};
