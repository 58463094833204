import { useQuery } from '@tanstack/react-query';
import { boardApis } from 'apis/board.apis';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useModalContext } from 'modal/context';
import { FC, useState } from 'react';

import { BoardListItem } from './BoardListItem';
import { PaginationComponent } from 'components/PaginationComponent';

interface BoardListProps{
  vPage: number,
}

export const BoardList: FC<BoardListProps> = ({vPage}) => {
  const [page, setPage] = useState(vPage);
  const modalDispatch = useModalContext();
  const query = useQuery({
    queryKey: ['board-list', page],
    queryFn: () => boardApis.getList(page),
    throwOnError(error) {
      modalDispatch.openModal({
        type: 'alert',
        title: '오류',
        text: error.message,
      });
      return false;
    },
    retry: false,
  });

  const rows = query.data?.rows ?? [];

  if (query.isLoading) {
    return (
      <div className="h-full flex justify-center items-center pb-12">
        <LoadingIndicator />
      </div>
    );
  }

  if (rows.length === 0) {
    return (
      <div className="h-full flex justify-center items-center pb-12">
        <p className="text-lg font-semibold text-gray300">
          공지사항이 없습니다.
        </p>
      </div>
    );
  }

  return (
    <div className="px-5">
      {rows.map((v) => (
        <BoardListItem {...v} key={v._id} page={query.data?.page}/>
      ))}
      <div className="py-12">
        <PaginationComponent
          page={page}
          page_size={query.data?.page_size ?? 10}
          total_count={query.data?.total_count ?? 0}
          onClick={setPage}
        />
      </div>
    </div>
  );
};
