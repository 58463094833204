import { CSSProperties, FC } from 'react';

interface ColorSwatchIconProps {
  fill?: string;
  style?: CSSProperties;
  className?: string;
}

export const ColorSwatchIcon: FC<ColorSwatchIconProps> = ({
  className,
  fill = '#202020',
  style,
}) => {
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9019 16.7222H18.3047L9.34601 21.8944L9.28516 21.9999H20.9019C21.5084 21.9999 22.0001 21.5083 22.0001 20.9017V17.8203C22.0001 17.2139 21.5084 16.7222 20.9019 16.7222Z"
        fill={fill}
      />
      <path
        d="M12.9662 15.6238L12.6492 16.1729L12.3322 16.722L9.6582 20.618L16.1082 16.722L17.0593 16.1729L18.0104 15.6238L19.973 14.4907C20.4983 14.1874 20.6782 13.5158 20.375 12.9905L18.8343 10.322C18.6309 9.96963 18.2618 9.77271 17.8822 9.77271C17.6959 9.77271 17.5071 9.82016 17.3342 9.92003L15.7219 10.8508L12.9662 15.6238Z"
        fill={fill}
      />
      <path
        d="M11.381 16.1731L11.6981 15.624L13.8197 11.9492L14.369 10.9976L14.9179 10.0471L16.1353 7.93839C16.4386 7.4131 16.2587 6.74148 15.7334 6.43825L13.0649 4.89757C12.8919 4.7977 12.7031 4.75024 12.5168 4.75024C12.1373 4.75024 11.7681 4.94724 11.5648 5.29953L9.37598 9.09059V19.646L11.064 16.7222L11.381 16.1731Z"
        fill={fill}
      />
      <path
        d="M7.17955 3H4.09818C3.49165 3 3 3.49172 3 4.09818V20.9016C3 21.2972 3.22039 21.6281 3.53414 21.8215C3.70144 21.9245 3.88724 21.9998 4.09818 21.9998H7.17955C7.64908 21.9998 8.04273 21.7024 8.19978 21.2879C8.24562 21.167 8.27773 21.0386 8.27773 20.9016V4.09818C8.27773 3.49172 7.78608 3 7.17955 3Z"
        fill={fill}
      />
    </svg>
  );
};
