import { Header } from '../../../../components/Header';
import { Layout } from '../../../../components/Layout';
import React, { FC, useState } from 'react';
import { Button } from '../../../../components/Button';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { FieldErrorMessage } from '../../../../components/FieldErrorMessage';
import { userPasswordEditYup } from '../../../../validation/user-password-edit.yup';
import { userApis } from '../../../../apis/user.apis';
import { HttpError } from '../../../../apis/http-error';
import { HideIcon } from '@assets/components/hide-icon';
import {useAuthData} from "../../../../auth";
import {useModalContext} from "../../../../modal/context";

interface PasswordEditScreenProps {}

type PasswordEditFormik = {
  current: string;
  password: string;
  passwordConfirm: string;
  username: string;
};

export const PasswordEditScreen: FC<PasswordEditScreenProps> = () => {
  const modal = useModalContext();
  const authData = useAuthData();
  const [hideCurrentPw, setHideCurrentPw] = useState(true);
  const [hideNextPw, setHideNextPw] = useState(true);
  const [hideConfirmPw, setHideConfirmPw] = useState(true);
  const toggleHideCurrentPw = () => setHideCurrentPw((prev) => !prev);
  const toggleHideNextPw = () => setHideNextPw((prev) => !prev);
  const toggleHideConfirmPw = () => setHideConfirmPw((prev) => !prev);

  const formik = useFormik<PasswordEditFormik>({
    initialValues: {
      current: '',
      password: '',
      passwordConfirm: '',
      username: authData?.user?.username ?? '',
    },
    onSubmit: async (values, helper) => {
      try {
        await userApis.updatePassword(values.current, values.password);
        modal.openModal(
            { type: 'alert', title: '비밀번호를 변경하였습니다.', text: ''}
        );
        helper.resetForm();
      } catch (e: any) {
        const err = e as HttpError;
        if (err.code === 'current') {
          helper.setFieldError('current', '비밀번호가 일치하지 않습니다.');
        }else{
          modal.openModal({ type: 'alert', title: err.message, text: '' });
        }
      } finally {
        helper.setSubmitting(false);
      }
    },
    validationSchema: userPasswordEditYup,
    validateOnChange: false,
    validateOnBlur: false,
  });
  return (
    <Layout renderHeader={<Header hasBack title="비밀번호 변경" />} hideFooter>
      <FormikProvider value={formik}>
        <Form className="py-3 px-5 space-y-3" onSubmit={formik.handleSubmit}>
          <div>
            <label
              className="block text-lg font-semibold text-gray800 mb-px7"
              htmlFor="current"
            >
              현재 비밀번호
            </label>
            <div className="relative">
              <Field
                id="current"
                type={hideCurrentPw ? 'password' : ''}
                name="current"
                className="input pr-12"
                placeholder="현재 비밀번호를 입력해 주세요."
                disabled={formik.isSubmitting}
                maxLength={20}
                onBlur={() => formik.validateField('current')}
                onChange={formik.handleChange}
              />
              <button
                type="button"
                onClick={toggleHideCurrentPw}
                className="absolute right-3 top-4"
                disabled={formik.isSubmitting}
                tabIndex={-1}
              >
                <HideIcon mode={hideCurrentPw ? 'hide' : 'visible'} />
              </button>
            </div>
            <FieldErrorMessage name="current" />
          </div>
          <div>
            <label
              className="block text-lg font-semibold text-gray800 mb-px7"
              htmlFor="password"
            >
              새 비밀번호
            </label>
            <div className="relative">
              <Field
                id="password"
                type={hideNextPw ? 'password' : ''}
                name="password"
                className="input pr-12"
                placeholder="숫자, 영문자, 특수문자 중 2개 이상 조합 8~20자"
                maxLength={20}
                onBlur={() => formik.validateField('password')}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
              <button
                type="button"
                onClick={toggleHideNextPw}
                className="absolute right-3 top-4"
                disabled={formik.isSubmitting}
                tabIndex={-1}
              >
                <HideIcon mode={hideNextPw ? 'hide' : 'visible'} />
              </button>
            </div>
            <FieldErrorMessage name="password" />
          </div>
          <div>
            <label
              className="block text-lg font-semibold text-gray800 mb-px7"
              htmlFor="passwordConfirm"
            >
              새 비밀번호 확인
            </label>
            <div className="relative">
              <Field
                id="passwordConfirm"
                type={hideConfirmPw ? 'password' : ''}
                name="passwordConfirm"
                className="input pr-12"
                placeholder="숫자, 영문자, 특수문자 중 2개 이상 조합 8~20자"
                disabled={formik.isSubmitting}
                maxLength={20}
                onBlur={() => formik.validateField('passwordConfirm')}
                onChange={formik.handleChange}
              />
              <button
                type="button"
                onClick={toggleHideConfirmPw}
                className="absolute right-3 top-4"
                disabled={formik.isSubmitting}
                tabIndex={-1}
              >
                <HideIcon mode={hideConfirmPw ? 'hide' : 'visible'} />
              </button>
            </div>
            <FieldErrorMessage name="passwordConfirm" />
          </div>
          <div className="!mt-7" style={{ paddingTop: 3, paddingBottom: 3 }}>
            <Button type="submit" disabled={formik.isSubmitting}>
              변경하기
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </Layout>
  );
};
