import { FC } from 'react';
import styled from "styled-components";
import {PiCaretDoubleRightThin} from "react-icons/pi";

interface DoubleRightPaginationIcon {}

export const DoubleRightPaginationIcon: FC<DoubleRightPaginationIcon> = () => {
  return (
      <IconContainer>
          <PiCaretDoubleRightThin  />
      </IconContainer>
  );
};

const IconContainer = styled.div`
    font-size: 25px;
`;