import {HideIcon} from '@assets/components/hide-icon';
import {authApis} from 'apis/auth.apis';
import {HttpError} from 'apis/http-error';
import {Button} from 'components/Button';
import {FieldErrorMessage} from 'components/FieldErrorMessage';
import {Field, Form, FormikProvider, useFormik} from 'formik';
import {useModalContext} from 'modal/context';
import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {registerYup} from 'validation/register.yup';

interface SignupFormProps {
    phone_number: string;
    terms_agree_at: string;
}

interface Values {
    username: string;
    name: string;
    phone_number: string;
    email: string;
    password: string;
    passwordConfirm: string;
    service_term_agree_at:  string;
    privacy_policy_agree_at:  string;

}

const SignupForm: FC<SignupFormProps> = ({phone_number, terms_agree_at}) => {
    const [hidePw, setHidePw] = useState(true);
    const [hidePwConfirm, setHidePwConfirm] = useState(true);
    const modal = useModalContext();
    const navigate = useNavigate();
    const formik = useFormik<Values>({
        initialValues: {
            username: '',
            name: '',
            phone_number: phone_number,
            email: '',
            password: '',
            passwordConfirm: '',
            service_term_agree_at: terms_agree_at ?? undefined,
            privacy_policy_agree_at: terms_agree_at ?? undefined,
        },
        onSubmit: async (values, helper) => {
            try {
                await authApis.register({
                    username : values.username,
                    phone_number: phone_number,
                    email: values.email,
                    name: values.name,
                    password: values.password,
                    service_term_agree_at: terms_agree_at,
                    privacy_policy_agree_at: terms_agree_at
                });
                modal.openModal(
                    {
                        type: 'alert',
                        title: '회원가입이 완료되었습니다.',
                        text: '',
                    },
                    {
                        onClose: () => navigate('/login'),
                        closeTypes: 'sideeffect'
                    }
                );
            } catch (e: any) {
                const error = e as HttpError;
                if (error.code === 'username') {
                    helper.setFieldError(error.code, error.message);
                } else if (error.code === 'email') {
                    helper.setFieldError(error.code, error.message);
                }
            } finally {
                helper.setSubmitting(false);
            }
        },
        validationSchema: registerYup,
        validateOnBlur: false,
        validateOnChange: false,
    });
    const togglePw = () => setHidePw((prev) => !prev);
    const togglePwConfirm = () => setHidePwConfirm((prev) => !prev);
    return (
        <div>
            <PagingNumber>
                3/3
            </PagingNumber>
            <h1 className="text-head font-bold mb-10">회원가입</h1>
            <FormikProvider value={formik}>
                <Form className="mt-1.5"  onSubmit={formik.handleSubmit} >
                    <div>
                        <label
                            className="block text-lg font-semibold text-gray800 mb-px7"
                            htmlFor="username"
                        >
                            아이디
                        </label>
                        <Field
                            id="username"
                            name="username"
                            className="input"
                            placeholder="영문 소문자, 숫자 6~12자"
                            maxLength={12}
                            onBlur={() => {
                                formik.handleBlur('username');
                                formik.setFieldValue('context', { username: formik.values.username });
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                formik.handleChange(e);
                                formik.setFieldValue('context', { username: e.target.value });
                            }}
                            value={formik.values.username}
                        />
                        <FieldErrorMessage name="username"/>
                    </div>
                    <div>
                        <label
                            className="block text-lg font-semibold text-gray800 mb-px7"
                            htmlFor="password"
                        >
                            비밀번호
                        </label>
                        <div className="relative">
                            <Field
                                id="password"
                                type={hidePw ? 'password' : ''}
                                name="password"
                                className="input pr-12"
                                placeholder="숫자, 영문자, 특수문자 중 2개 이상 조합 8~20자"
                                maxLength={20}
                                onBlur={() => formik.validateField('password')}
                                onChange={formik.handleChange}
                            />
                            <button
                                type="button"
                                onClick={togglePw}
                                className="absolute right-3 top-4"
                                tabIndex={-1}
                            >
                                <HideIcon mode={hidePw ? 'hide' : 'visible'}/>
                            </button>
                        </div>
                        <FieldErrorMessage name="password"/>
                    </div>
                    <div>
                        <label
                            className="block text-lg font-semibold text-gray800 mb-px7"
                            htmlFor="passwordConfirm"
                        >
                            비밀번호 확인
                        </label>
                        <div className="relative">
                            <Field
                                id="passwordConfirm"
                                type={hidePwConfirm ? 'password' : ''}
                                name="passwordConfirm"
                                className="input pr-12"
                                placeholder="숫자, 영문자, 특수문자 중 2개 이상 조합 8~20자"
                                maxLength={20}
                                onBlur={() => formik.validateField('passwordConfirm')}
                                onChange={formik.handleChange}
                            />
                            <button
                                type="button"
                                onClick={togglePwConfirm}
                                className="absolute right-3 top-4"
                                tabIndex={-1}
                            >
                                <HideIcon mode={hidePwConfirm ? 'hide' : 'visible'}/>
                            </button>
                        </div>
                        <FieldErrorMessage name="passwordConfirm"/>
                    </div>
                    <div>
                        <label
                            className="block text-lg font-semibold text-gray800 mb-px7"
                            htmlFor="name"
                        >
                            이름
                        </label>
                        <Field
                            id="name"
                            name="name"
                            className="input"
                            placeholder="이름을 입력해 주세요."
                            onBlur={() => formik.validateField('name')}
                            onChange={formik.handleChange}
                            maxLength={50}
                        />
                        <FieldErrorMessage name="name"/>
                    </div>
                    <div>
                        <label
                            className="block text-lg font-semibold text-gray800 mb-px7"
                            htmlFor="email"
                        >
                            이메일
                        </label>
                        <Field
                            id="email"
                            name="email"
                            className="input"
                            placeholder="이메일을 입력해 주세요."
                            type="email"
                            onBlur={() => formik.validateField('email')}
                            onChange={formik.handleChange}
                        />
                        <FieldErrorMessage name="email"/>
                    </div>
                    <div style={{marginTop: 30}}>
                        <Button type="submit" disabled={formik.isSubmitting}>
                            가입하기
                        </Button>
                    </div>
                </Form>
            </FormikProvider>
        </div>
    );
};

export default SignupForm;

const PagingNumber = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 30px 30px;
`;