import { User } from '../auth';
import { refreshTokenStorage } from '../utils/refreshTokenStorage';
import { httpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

async function login(username: string, password: string) {
  try {
    const response = await httpClient
      .post('/auth/login', {
        username,
        password,
      })
      .then((res) => res.data as User & { refresh_token: string });

    return response;
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function getAccessToken(): Promise<Pick<User, 'access_token'> | false> {
  const refreshToken = refreshTokenStorage.get();
  if (!refreshToken) {
    return false;
  }
  try {
    const response = await httpClient.get('/auth/access-token', {
      withCredentials: true,
      headers: {
        'x-refresh-token': refreshToken,
      },
    });

    return response.data as Pick<User, 'access_token'>;
  } catch (e: any) {
    refreshTokenStorage.remove();
    return false;
  }
}

async function getMe(opts: { access_token?: string } = {}) {
  const { access_token } = opts;
  try {
    const headers =
      typeof access_token === 'string'
        ? { Authorization: `Bearer ${access_token}` }
        : {};
    const response = await httpClient.get('/auth/me', {
      headers: headers,
    });
    return response.data as Omit<User, 'access_token'>;
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function removeRefreshToken() {
  await httpClient.delete('/auth/token').catch(() => {});
}

async function register(body: {
    password: string;
    privacy_policy_agree_at: string;
    name: string;
    phone_number: string;
    service_term_agree_at: string;
    email: string;
    username: string
}) {
  try {
    await httpClient.post('/auth/register', body);
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function requestFindPwCode(email: string) {
  try {
    const res = await httpClient.post('/auth/request-code', { email });
    return res.data as { _id: string };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function verifyCode(id: string, email: string, code: string) {
  try {
    const res = await httpClient.post('/auth/verify-code', { id, email, code });
    return res.data as {
      valid: boolean;
      message: string;
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function changePw(verifyId: string, phone_number: string, password: string) {
  try {
    await httpClient.post('/auth/change-pw', { phone_number, verifyId, password });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

export const authApis = {
  login,
  getAccessToken,
  getMe,
  removeRefreshToken,
  register,
  requestFindPwCode,
  verifyCode,
  changePw
};
