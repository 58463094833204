import { FC } from 'react';

const NotificationIcon: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4134 24.5012C17.2076 25.0832 16.6525 25.5 16 25.5C15.3476 25.5 14.7925 25.0832 14.5866 24.5012H17.4134Z"
        stroke="#202020"
        fill="#202020"
      />
      <path
        d="M22.2149 13.7151H22.2148L22.2149 13.7219L22.2807 18.5641C22.2807 18.5641 22.2807 18.5641 22.2807 18.5642C22.2887 19.1551 22.6939 19.5501 22.9385 19.7855C23.1946 20.032 23.3286 20.2106 23.4018 20.3558C23.4696 20.4903 23.4967 20.6187 23.4967 20.7894V20.8033L23.4975 20.8172C23.5462 21.6903 22.8334 22.3983 22.0137 22.3983H9.98619C9.16643 22.3983 8.45369 21.6903 8.50239 20.8172L8.50316 20.8033V20.7894C8.50316 20.6187 8.53031 20.4903 8.59807 20.3558C8.67128 20.2106 8.80528 20.032 9.06134 19.7855C9.30609 19.55 9.71121 19.1551 9.71919 18.5642C9.71919 18.5641 9.71919 18.5641 9.71919 18.5641L9.78499 13.7219L9.78503 13.7219V13.7151C9.78503 10.8805 11.6854 8.49226 14.2826 7.74619L14.5189 7.67831L14.6094 7.44972C14.8302 6.8923 15.3695 6.50073 15.9999 6.50073C16.6304 6.50073 17.1697 6.8923 17.3905 7.44972L17.481 7.67831L17.7173 7.74619C20.3145 8.49225 22.2149 10.8805 22.2149 13.7151Z"
        stroke="#202020"
        fill="#202020"
      />
    </svg>
  );
};

export default NotificationIcon;
