import { CSSProperties, FC } from 'react';

interface DataBookIconProps {
  fill?: string;
  style?: CSSProperties;
  className?: string;
}

export const DataBookIcon: FC<DataBookIconProps> = ({
  className,
  fill = '#202020',
  style,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M18.9504 6.88281H18.5975V19.2062C18.5975 20.4314 17.7642 21.4716 16.6384 21.6752L10.0547 23H18.9504C19.5947 23 20.117 22.4776 20.117 21.8333V8.04949C20.117 7.40513 19.5947 6.88281 18.9504 6.88281Z"
        fill={fill}
      />
      <path
        d="M17.431 19.2065V5.81175C17.431 5.77771 17.4156 5.74973 17.4134 5.7164C17.3661 5.01187 16.8428 4.4682 16.2125 4.46826C16.1464 4.46826 16.0792 4.47424 16.0116 4.48642L15.9115 4.50657V16.6587C15.9115 17.71 15.2402 18.6585 14.2614 19.0166L8.42725 22.3038L16.4083 20.5317C16.9982 20.4249 17.431 19.8641 17.431 19.2065Z"
        fill={fill}
      />
      <path
        d="M14.7448 16.6585V3.34397C14.7448 2.58049 14.1202 1.99992 13.4042 2C13.2775 2.00007 13.148 2.01816 13.0186 2.05668L7.93331 4.92195L6.52361 5.7162L6.29026 5.84765L5.48818 6.29961L4.96003 6.59719C4.3905 6.76666 4 7.29026 4 7.88449V21.199C4 21.9625 4.62457 22.543 5.3407 22.543C5.46731 22.543 5.59684 22.5248 5.72622 22.4863L13.7848 17.9458C14.3543 17.7764 14.7448 17.2527 14.7448 16.6585Z"
        fill={fill}
      />
    </svg>
  );
};
