import { FC } from 'react';
import { useAuthData } from '../../../auth';
import { Link } from 'react-router-dom';
import { NotificationRightButton } from './NotificationRightButton';
import { ProfileIcon } from '@assets/components/profile-icon';
import { LoginIcon } from '@assets/components/login-icon';

interface AuthRightButtonProps {}

export const AuthRightButton: FC<AuthRightButtonProps> = () => {
  const authData = useAuthData();
  const isLogin = !!authData.user;

  if (!isLogin) {
    return (
      <Link to="/login" state={{ from: '/home' }}>
        <LoginIcon />
      </Link>
    );
  }
  return (
    <div className="flex items-center space-x-2">
      <NotificationRightButton />
      <Link to="/user/setting">
        <ProfileIcon />
      </Link>
    </div>
  );
};
