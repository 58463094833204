import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Portal } from '../components/Portal';
import styled from 'styled-components';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import closeXIcon from '@assets/svg/close-x-icon.svg';
import { LoadingIndicator } from 'components/LoadingIndicator';

type CloseType = 'disable' | 'sideeffect' | 'justclose';
type ButtonType = '' | '1' | '2';

interface PanelProps {
  modalState: {
    buttonType?: string;
  };
}


interface ModalInterface {
  openModal: (
    modal: {
      type: 'alert' | 'loading';
      title: string;
      text: string;
    },
    opts?: {
      onClose?: () => void;
      buttonText?: string;
      buttonText2?: string;
      closeTypes?: CloseType;
      buttonType?: ButtonType;
      inProgress?: boolean;
    }
  ) => void;
  updateModal: (
    modal: {
      type: 'alert' | 'loading';
      title: string;
      text: string;
    },
    opts?: {
      onClose?: () => void;
      buttonText?: string;
      closeTypes?: CloseType;
    }
  ) => void;
  closeModal: () => void;
}

export const ModalContext = createContext<ModalInterface | null>(null);

interface ModalProviderProps {
  children?: ReactNode;
}

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modalState, setModalState] = useState<{
    type: 'alert' | 'loading';
    key: string | null;
    title: string;
    text: string;
    onClose?: () => void;
    buttonText?: string;
    buttonText2?: string;
    closeType: CloseType;
    buttonType?: ButtonType;
    inProgress?: boolean;
  }>({
    type: 'alert',
    key: null,
    title: '',
    text: '',
    closeType: 'justclose',
    buttonType: '',
    inProgress: false,
  });

  const openModal: ModalInterface['openModal'] = useCallback(
    (modal, opts = {}) => {
      setModalState({
        type: modal.type ?? 'alert',
        key: `${Date.now()}_${modal.title}`,
        title: modal.title,
        text: modal.text,
        onClose: opts.onClose,
        buttonText: opts.buttonText,
        closeType: opts.closeTypes ?? 'justclose',
        buttonType: opts.buttonType,
        inProgress: opts.inProgress,
        buttonText2: opts.buttonText2,
      });
    },
    []
  );

  const closeModal: ModalInterface['closeModal'] = useCallback(() => {
    setModalState({
      key: null,
      title: '',
      text: '',
      type: 'alert',
      closeType: 'justclose',
    });
  }, []);
  const updateModal: ModalInterface['updateModal'] = useCallback(
    (modal, opts = {}) => {
      setModalState((prev) => ({
        key: prev.key,
        type: modal.type ?? 'alert',
        title: modal.title,
        text: modal.text,
        onClose: opts.onClose,
        buttonText: opts.buttonText,
        closeType: opts.closeTypes ?? 'justclose',
      }));
    },
    []
  );
  const value = useMemo(() => {
    return { closeModal, openModal, updateModal };
  }, [closeModal, openModal, updateModal]);
  const handleClose = () => {
    if (modalState.closeType === 'disable') {
      return;
    } else {
      modalState?.onClose?.();
      closeModal();
    }
  };
  const handleCloseFromDim = () => {
    if (modalState.closeType === 'disable') {
      return;
    } else if (modalState.closeType === 'justclose') {
      closeModal();
    } else {
      //closeModal();
      //modalState?.onClose?.();
    }
  };
  const handleCloseFromXButton = () => {
    if (modalState.closeType === 'disable') {
      return;
    } else if (modalState.closeType === 'justclose') {
      closeModal();
    } else {
      closeModal();
      modalState?.onClose?.();
    }
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      <Portal>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={modalState.key ?? 'none'}
            classNames="modal"
            timeout={300}
          >
            {modalState.key !== null ? (
              <Overlay>
                <Dim />
                <Container onClick={handleCloseFromDim}>
                  <Wrapper>
                    <Panel modalState={modalState}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      {modalState.type === 'alert' ? (
                        <CloseBtn
                          type="button"
                          onClick={handleCloseFromXButton}
                        >
                          <img
                            src={closeXIcon}
                            alt="닫기"
                            style={{ width: 'inherit', height: 'inherit' }}
                          />
                        </CloseBtn>
                      ) : null}
                      {modalState.type === 'alert' ? (
                        <h2 className="text-header font-semibold text-center whitespace-pre-line break-all">
                          {modalState.title}
                        </h2>
                      ) : null}
                      {modalState.type === 'loading' ? (
                        <>
                          <LoadingIndicator />
                          <Description className="mt-3 text-center font-medium">
                            {modalState.text}
                          </Description>
                        </>
                      ) : (
                        <>
                          <Description className="mb-3">
                            {modalState.text}
                          </Description>
                          {modalState.buttonType === '2' ? (
                              <ButtonWrapper className="flex">
                                <CancleButton   onClick={handleCloseFromXButton}>
                                  {modalState.buttonText2 ?? '취소'}
                                </CancleButton>
                                <Button2 onClick={handleClose} disabled={modalState.inProgress}>
                                  {modalState.buttonText ?? '확인'}
                                </Button2>
                              </ButtonWrapper>
                              ) : (
                                  <ButtonWrapper>
                                    <Button onClick={handleClose}>
                                      {modalState.buttonText ?? '확인'}
                                    </Button>
                                  </ButtonWrapper>
                              )}
                        </>
                      )}
                    </Panel>
                  </Wrapper>
                </Container>
              </Overlay>
            ) : (
              <div></div>
            )}
          </CSSTransition>
        </SwitchTransition>
      </Portal>
    </ModalContext.Provider>
  );
};

export function useModalContext() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('Modal Context가 정의되어야 합니다.');
  }
  return context;
}

const Overlay = styled.div`
  position: relative;
  z-index: 10;
`;
const Dim = styled.div`
  position: fixed;
  display: block;
  inset: 0;
  background-color: rgba(40, 40, 50, 0.75);
  opacity: 0;
  //transition: opacity 0.2s ease;
  backdrop-filter: blur(5px);
  .modal-enter-done & {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: fixed;
  inset: 0;
  overflow-y: auto;
`;

const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
`;
const Panel = styled.div<PanelProps>`
  position: relative;
  width: 100%;
  max-width: 380px;
  background-color: white;
  padding: ${({ modalState }) => (modalState.buttonType === '2' ? '40px 0 0 0' : '40px 20px')};
  border-radius: 10px;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.2s, transform 0.2s;
  .modal-enter-done & {
    opacity: 1;
    transform: scale(1);
  }
`;
const CloseBtn = styled.button`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 16px;
  top: 16px;
`;
const Description = styled.p`
  font-size: 14px;
`;
const Button = styled.button`
  display: block;
  background-color: var(--grayscale-black);
  color: var(--grayscale-white);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
`;
const ButtonWrapper = styled.div`
  margin-top: 40px;
`;


const CancleButton = styled.button`
  display: block;
  color: gray;;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 15px;
  width: 100%;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
`;

const Button2 = styled.button`
  display: block;
  color: #e20d3a;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 15px;
  width: 100%;
  border-top: 1px solid lightgray;
`;