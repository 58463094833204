import { FC } from 'react';

interface HomeIconColorProps {
  color?: string;
}

export const HomeIconColor: FC<HomeIconColorProps> = ({
  color = '#202020',
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.13478 20.3993V17.3416C9.13478 16.561 9.77217 15.9283 10.5584 15.9283H13.4326C13.8102 15.9283 14.1723 16.0772 14.4393 16.3422C14.7063 16.6073 14.8563 16.9668 14.8563 17.3416V20.3993C14.8539 20.7238 14.9821 21.0359 15.2124 21.2662C15.4427 21.4965 15.7561 21.626 16.0829 21.626H18.0438C18.9596 21.6283 19.8388 21.2688 20.4872 20.6268C21.1356 19.9847 21.5 19.113 21.5 18.2038V9.49284C21.5 8.75844 21.1721 8.06182 20.6046 7.59065L13.934 2.30185C12.7737 1.37454 11.1111 1.40448 9.98539 2.37296L3.46701 7.59065C2.87274 8.04793 2.51755 8.74661 2.5 9.49284V18.1949C2.5 20.0898 4.04738 21.626 5.95617 21.626H7.87229C8.55123 21.626 9.103 21.0822 9.10792 20.4082L9.13478 20.3993Z"
        fill={color}
      />
    </svg>
  );
};
